import React, { useState, useContext, useEffect } from 'react'
import HeaderTwo from '../../components/HeaderTwo'
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import MetaTags from "react-meta-tags";
import { ContextProvider } from '../../context/BaseContext'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Styles } from './styles/course.js';
import Error401 from '../../components/Error401';
import Error500 from '../../components/Error500';
import EmptyData from '../../components/EmptyData';
import { host } from '../../util/host';
import moment from 'moment'
import "moment/locale/id"
import { convertToSlug } from '../../util/Functions';

export default function Index() {
    const context = useContext(ContextProvider)
    const [refresh, setRefresh] = useState(true)
    const [data, setData] = useState([])

    async function getTikTalk() {
        setRefresh(true)
        let response = await context.getRequest(`tiktalk`)
        if (response) {
            console.log("RESPONSE : ", response.data.data)
            setData(response.data.data)
        }
        setRefresh(false)
    }
    useEffect(() => {
        getTikTalk()
    }, [])
    return (
        <div className="main-wrapper course-details-page">
            <HeaderTwo />

            <BreadcrumbBox
                title="TIKTALK"
                image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}
            />
            {refresh ?
                <Col md="12" className="text-center mt-2 mb-2">
                    <Spinner animation="border" variant="danger" />
                </Col> :
                <Styles>
                    {data.length > 0 ? (
                        <section className="course-grid-area" style={{ paddingBottom: 150 }}>
                            <Container>
                                <div className="course-items">
                                    <Row>
                                        <Items />
                                    </Row>
                                </div>
                            </Container>
                        </section>
                    ) : <EmptyData />}
                </Styles>
            }
        </div>
    )
    function formatTime(time) {
        let times = time.split(":");
        return times[0] + "." + times[1];
    }
    function Items() {
        return (
            <>
                {data.map((val, i) => {
                    var href=`https://share.wakool.academy/${val.id}/tiktalk/${convertToSlug(val.title)}`
                    return (
                        <Col lg="4" md="6" sm="12" >
                            <div className="course-item" style={{ minHeight: '26rem' }}>
                                <a
                                    href={href}
                                    // target='_blank'
                                >
                                    <div
                                        className="course-image"
                                        style={{ backgroundImage: `url('${val.img}')` }}
                                    >
                                    </div>
                                </a>
                                <div className="course-content">
                                    <h6 className="heading">
                                        <a
                                            href={href}
                                            // target='_blank'
                                        >
                                            {val.title}
                                        </a>
                                    </h6>
                                    {/* <div className='class-info'>
                                        <p>Narasumber :</p>
                                        {val.mentor.map((v,ii)=>{
                                            return(
                                                <h6>{v.mentor_name}</h6>
                                            )
                                        })}
                                    </div> */}
                                    {val.video_recording?(<span class="badge badge-success">video recording available</span>):null}
                                    
                                    <div className="class-info d-flex flex-row mt-3">
                                        <p className="mr-3">
                                            <i className="las la-calendar mr-1"></i>
                                            <span>
                                                {moment(val.date_start).format("dddd, DD MMMM YYYY")}
                                            </span>
                                        </p>
                                        <p>
                                            <i className="lar la-clock mr-1"></i>
                                            <span>
                                                {formatTime(val.time_start)} - {formatTime(val.time_end)}
                                            </span>
                                        </p>
                                    </div>
                                    
                                    {/* <button type="button" style={{marginVertical:20,marginBottom:20}} className="btn btn-danger float-right">Lihat Sertifikat</button> */}
                                </div>
                                
                            </div>
                        </Col>
                    )
                })}
            </>
        )
    }
}