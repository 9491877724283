import React, { useContext, useEffect } from "react";
import Datas from "../data/help-area/help-area.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeContact.js";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../context/BaseContext";

function HelpArea() {
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    // console.log(data);
    let form = new FormData();
    form.append("nama", data.nama);
    form.append("email", data.email);
    form.append("subject", data.subject);
    form.append("text", data.text);
    //console.log("test 1",saveAvatar)
    let response = await context.postRequest("customer-service", form, "form");
    if (response) {
      reset();
      context.setNotification(
        "success",
        "Email berhasil dikirim, admin akan membalas email anda"
      );
    }
  };

  useEffect(() => {});

  return (
    <Styles>
      {/* Help Area */}
      <section
        className="home-contact-area"
        // style={{
        //   backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})`,
        //   backgroundSize: "contain",
        // }}
      >
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{Datas.secTitle}</h4>
              </div>
            </Col>
            <Col md="12">
              <form
                id="form1"
                className="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col md="6">
                    <p
                      className={
                        errors.nama
                          ? "form-control error mb-4"
                          : "form-control mb-4"
                      }
                    >
                      <input
                        {...register("nama", {
                          required: "Nama pengirim tidak boleh kosong",
                        })}
                        type="text"
                        placeholder={
                          errors.nama
                            ? errors.nama.message
                            : "Masukkan nama anda"
                        }
                        id="name1"
                      />
                      <span className="input-msg1"></span>
                    </p>
                    <p
                      className={
                        errors.email
                          ? "form-control error mb-4"
                          : "form-control mb-4"
                      }
                    >
                      <input
                        {...register("email", {
                          required: "Email pengirim tidak boleh kosong",
                          pattern:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        })}
                        type="email"
                        placeholder={
                          errors.email
                            ? errors.email.message
                            : "Masukkan email anda"
                        }
                        id="email1"
                      />
                      {/* {errors.email && (
                        <span style={{ color: "black" }} className="input-msg1">
                          {errors.email.message}
                        </span>
                      )} */}
                    </p>
                    <p
                      className={
                        errors.subject
                          ? "form-control error mb-4"
                          : "form-control mb-4"
                      }
                    >
                      <input
                        {...register("subject", {
                          required: "Subject email tidak boleh kosong",
                        })}
                        type="text"
                        placeholder={
                          errors.subject
                            ? errors.subject.message
                            : "Masukkan subject email anda"
                        }
                        id="subject1"
                      />
                      <span className="input-msg1"></span>
                    </p>
                  </Col>
                  <Col md="6">
                    <div
                      className={
                        errors.text
                          ? "form-group quest error"
                          : "form-group quest"
                      }
                    >
                      <textarea
                        {...register("text", {
                          required: "Text tidak boleh kosong",
                        })}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder={
                          errors.text
                            ? errors.text.message
                            : "Tulis pertanyaan mu disini"
                        }
                        rows={7}
                      />
                    </div>
                  </Col>
                  <Col md="12" className="text-center">
                    <button type="submit">
                      <i className="fas fa-envelope"></i> Send Request
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default HelpArea;
