import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Swal from "sweetalert2";

export default function RenderContentItem({
  setIsTimeline,
  content,
  eventKey,
  setTypeContent,
  setUrlContent,
  idContentActive,
  setidContentActive,
  videoToBumper,
  cannotChangeContent,
  setListFilesByContent,
  context,
  setMessageBrokenFile,
 
}) {
  async function getTempUrl(url) {
    console.log('Ini URL',url)
    let response = await context.getRequestWithoutHost(url);
    if (response) {
      // console.log(response.data.url);
      setUrlContent(response.data.url);
    }
  }


  return (
    <Accordion.Collapse className="border-top" eventKey={eventKey}>
      <Card.Body
        onClick={() => {
          if (cannotChangeContent) {
            Swal.fire({
              title: "Opss...",
              text: "Anda sedang mengerjakan kuis, mohon selesaikan terlebih dahulu",
              icon: "error",
              showConfirmButton: false,
            });
          } else {
            setTypeContent(content.content_type_id);
            if(content.content_type_id == 4){
              if(content.kelas_activity != null){
                context.setValue('isPraktikumDone',true)
              } else {
                context.setValue('isPraktikumDone',false)
              }
              setidContentActive(content.id);
              setMessageBrokenFile(false);
            } else if (content.content_type_id == 3) {
              if (content.contents_url != "") {
                setMessageBrokenFile(false);
                getTempUrl(content.contents_url);
              } else {
                setMessageBrokenFile(true);
              }
            } else if(content.content_type_id == 2){
                setidContentActive(content.id);
                setMessageBrokenFile(false);
            } else {
              if (content.contents_url != "") {
                setMessageBrokenFile(false);
                setUrlContent(content.contents_url);
              } else {
                setMessageBrokenFile(true);
              }
            }
            if(content.kelas_activity != null){
              setIsTimeline(true)
              // setPraktikDone(true)
            }
            document.getElementById('contentPlay').scrollIntoView({behavior: "smooth"});
            setidContentActive(content.id);
            videoToBumper(content.content_type_id);
            setListFilesByContent(content.content_files);
          }
        }}
        className={`p-3 list-materi ${
          idContentActive == content.id && "active"
        }`}
      >
        <span className="play-icon">
          {content.content_type_id === 1 && (
            <i className="lar la-file-video"></i>
          )}
          {content.content_type_id === 2 && (
            <i className="las la-file-invoice"></i>
          )}
          {content.content_type_id === 3 && <i className="lar la-file-pdf"></i>}
          {content.content_type_id === 4 && <i className="las la-file-upload"></i>}
        </span>
        <span className="lecture-title">{content.title}</span>
        <span className="lecture-duration">
          {content.kelas_activity != null && (
            <i className="fas mr-1 fa-check-circle"></i>
          )}
          {content?.durasi ? `${content?.durasi} menit` : ""}
        </span>
      </Card.Body>
    </Accordion.Collapse>
  );
}
