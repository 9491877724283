import React, { useEffect, useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col,Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/account.js';
import { useForm } from "react-hook-form";
import {ContextProvider} from '../../context/BaseContext'
import MetaTags from "react-meta-tags";
function Register() {
    const context=useContext(ContextProvider)
    const [refresh,setRefresh]=useState(false)
    const { register, handleSubmit, watch, reset,  formState: { errors } } = useForm();
    let password=watch('password')
    const [showPass, setShowPass] = useState(false)
    const [showConf, setShowConf] = useState(false)
    console.log(errors)
    useEffect(() => {
        
        // console.log(pass)
    },[]);

    const onSubmit = async(data) => {
        console.log(data)
        setRefresh(true)
        try{
            var response=await context.postRequest('register',JSON.stringify(data));
            if(response){
                context.setNotification('success','Selamat anda berhasil daftar di wakool academy, silahkan cek email anda untuk verifikasi')
                reset()
            }
        }catch(error){
            console.log(error.response.data.message);
            if(error.response.data.message.email[0] == 'The email has already been taken.'){
                context.setNotification('danger','Email sudah terdaftar, gunakan email lain anda untuk mendaftar')
            }else{
                context.setNotification('danger','Ooops registrasi gagal, jangan panik kami akan segera memperbaikinya')
            }
            
        }
        setRefresh(false)
    };
    return (
        <Styles>
            <MetaTags>
                <title>Daftar | Wakool Academy</title>
                <link
                rel="canonical"
                href={"https://wakool.academy/registration"}
                />
                <meta name="title" content={'Daftar'} />
                <meta itemprop="name" content={'Daftar'}/>
            </MetaTags>
            {/* Main Wrapper */}
            <div className="main-wrapper registration-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Daftar" image={`${process.env.PUBLIC_URL}/assets/illustration15.jpg`}/>

                {/* Registration Area */}
                <section className="registration-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="registration-box">
                                    <div className="registration-title text-center">
                                        <h3>Daftar</h3>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)} id="form_registration" className="form">
                                        <p className="form-control">
                                            <label htmlFor="registration_lname">Nama Lengkap *</label>
                                            <input type="text" {...register('name',{required:'Kamu harus mengisi nama lengkap'})} placeholder="Nama Lengkap" id="registration_lname" />
                                            <div style={{color:'red'}}>{errors.name?errors.name.message:null}</div>
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_email">Email *</label>
                                            <input type="text" {...register('email',{required:'Kamu harus mengisi email',pattern: {
                                                    value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                                    message: "Harap isi dengan format email"
                                                }})} placeholder="Email" id="registration_email" />
                                                <div style={{color:'red'}}>{errors.email?errors.email.message:null}</div>
                                                <span className="registration_input-msg"></span>
                                            </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_password">Kata Sandi *</label>
                                            <div className="row">
                                                <div className="col-10 pr-0">
                                                    <input {...register('password',{required:'Kamu harus mengisi password'})} type={showPass ? "text" : "password"} placeholder="*******" id="registration_password" />
                                                    <div style={{color:'red'}}>{errors.password?errors.password.message:null}</div>
                                                </div>
                                                <div className="col-2">
                                                    <button type="button" onClick={() => setShowPass(showPass ? false : true)} className="btn btn-showPass">
                                                        {showPass ? <i class="fas fa-eye-slash" /> : <i className="fa fa-eye" />}
                                                    </button>
                                                </div>
                                            </div>
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="registration_cpassword">Konfirmasi Ulang Kata Sandi *</label>
                                            <div className="row">
                                                <div className="col-10 pr-0">
                                                    <input {...register('repassword',{
                                                        required:"Kamu harus mengulangi password",
                                                        validate:(val)=>val==password||'Password tidak sama'
                                                    })} type={showConf ? "text" : "password"} placeholder="*******" id="registration_cpassword" />
                                                    <div style={{color:'red'}}>{errors.repassword?errors.repassword.message:null}</div>
                                                </div>
                                                <div className="col-2">
                                                    <button type="button" onClick={() => setShowConf(showConf ? false : true)} className="btn btn-showPass">
                                                        {showConf ? <i class="fas fa-eye-slash" /> : <i className="fa fa-eye" />}
                                                    </button>
                                                </div>
                                            </div>
                                            <span className="registration_input-msg"></span>
                                        </p>
                                        <button type="submit" disabled={refresh} className="register">DAFTAR SEKARANG &nbsp;{refresh?<Spinner animation="border" variant="light" />:null}</button>
                                    </form>
                                    <div className="have_account-btn text-center">
                                        <p>Sudah memiliki akun? <Link to="/login">Masuk sekarang</Link></p>
                                        <p className="mt-1">Daftar sebagai mentor?{" "}
                                            <a href="https://mentor.wakool.academy/auth/signup">
                                                Daftar Mentor
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <Footer />

            </div>
        </Styles>
    )
}

export default Register