import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from "./styles/pagination.js";

function Pagination ({pagination,changePage}) {
    useEffect(() => {
        // console.log("pagination :",pagination)
    },[])
    return (
        <Styles>
            {/* Pagination */}
            <ul className="pagination-box list-unstyled list-inline">
                <li className="list-inline-item"><a href={"#"} onClick={(e)=>{
                    e.preventDefault()
                    var e=pagination.currentPage-1
                    if(e<1){
                        e=1
                    }
                    changePage(e)
                }}><i className="las la-angle-double-left"></i></a></li>
                <Before/>
                <After/>
                {/* <li className="list-inline-item"><Link >1</Link></li>
                <li className="list-inline-item"><Link >2</Link></li>
                <li className="active list-inline-item"><Link >3</Link></li>
                <li className="list-inline-item"><Link >...</Link></li>
                <li className="list-inline-item"><Link >13</Link></li> */}
                <li className="list-inline-item"><a href={"#"} onClick={(e)=>{
                    e.preventDefault()
                    var e=pagination.currentPage+1
                    if(e>=pagination.aft){
                        e=pagination.aft
                    }
                    changePage(e)
                }}><i className="las la-angle-double-right"></i></a></li>
            </ul>
        </Styles>
    )
    function Before(){
        var a=pagination.bef==0?1:pagination.bef
        if(pagination.bef>2){
            a=pagination.currentPage-2
        }
        var abc=[]
        for(var aa=a;aa<=(pagination.currentPage);aa++){
            abc.push(aa)
        }
        // console.log("INI PAGINATION",abc,(pagination.currentPage-1))
        return abc.map((val,i)=>{
            var cl=val==pagination.currentPage?'active list-inline-item':'list-inline-item'
            return <li key={`bef_`+i} className={cl}><a href={"#"} onClick={(e) => { 
                e.preventDefault()
                changePage(val)
            }}>{val}</a></li>
        })
    }
    function After(){
        var abc=[]
        for(var aa=pagination.currentPage+1;aa<=pagination.aft;aa++){
            abc.push(aa)
        }
        return abc.map((val,i)=>{
            var cl=val==pagination.currentPage?'active list-inline-item':'list-inline-item'
            return <li key={`aft_`+i} className={cl}><a href={"#"} onClick={(e) => { 
                e.preventDefault()
                changePage(val)
            }}>{val}</a></li>
        })
    }
}

export default Pagination
