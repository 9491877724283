import React, { Fragment, useContext, useState, useEffect } from "react";
import { ContextProvider } from "../../../context/BaseContext";
import moment from "moment";
import { Col, Spinner } from "react-bootstrap";

export default function Diskusi({ mentors, postFeedback, dataChat ,text, setText, handleKeyDown}) {
  //DECLARAION
  const context = useContext(ContextProvider);
  
  // STATE
  const [errorAvatar, setErrorAvatar] = useState(false);
  const [errorAvatarUser, setErrorAvatarUser] = useState(false)
  const [loading, setLoading] = useState(false);

  // FUNCTION
  // async function getListChat(){
  //     let response = await context.getRequest(`get_detail_feedback_users?assignment_id=270&mentor_id=${mentors.id}`)
  //     if(response){
  //       console.log('respon chat',  response)
  //     }
  // }

  useEffect(() => {
    if (dataChat.data) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [dataChat]);

  console.log("mentors dari respon", mentors);
  return (
    <main className="content">
      <div className="container p-0">
        <div className="card shadow-sm">
          <div className="row g-0">
            <div className="col-12 col-lg-12 col-xl-12">
              <div className="py-2 px-4 border-bottom d-none d-block">
                <div className="d-flex align-items-center py-1">
                  <div className="position-relative">
                    <img
                      src={
                        errorAvatar
                          ? process.env.PUBLIC_URL + `/assets/images/author.jpg`
                          : mentors.avatar
                      }
                      onError={() => setErrorAvatar(true)}
                      className="rounded-circle mr-1"
                      alt={mentors.name}
                      width="40"
                      height="40"
                    />
                  </div>
                  <div className="flex-grow-1 pl-3">
                    <strong className="text-capitalize">{mentors.name}</strong>
                    <div className="text-muted small ">
                      <em>INSTRUKTUR</em>
                    </div>
                  </div>
                </div>
              </div>
              {!loading ? (
                <div className="position-relative">
                  <div className="chat-messages p-4" style={{height: 450}}>
                    {dataChat.data?
                    dataChat.data.feedback.map((itm,idx)=>{
                      if(itm.sender == "users"){
                        return (
                      <div className="chat-message-right pb-4" key={idx}>
                      <div>
                        <img
                           src={
                            errorAvatarUser
                              ? process.env.PUBLIC_URL + `/assets/images/author.jpg`
                              : dataChat.user.avatar
                          }
                          onError={() => setErrorAvatarUser(true)}
                          className="rounded-circle mr-1"
                          alt="Chris Wood"
                          width="40"
                          height="40"
                        />
                        
                      </div>
                      <div className="flex-shrink-1 rounded py-2 px-3 mr-3"  style={{backgroundColor: '#DFE9FF'}}>
                        <div className="font-weight-bold mb-1">You</div>
                        {itm.text}
                        <div className="text-muted small text-nowrap mt-2">
                          {moment(itm.created_at).format('hh:mm DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                        )
                      } else {
                        return (
                    <div className="chat-message-left pb-4" >
                      <div>
                        <img
                           src={
                            errorAvatar
                              ? process.env.PUBLIC_URL + `/assets/images/author.jpg`
                              : mentors.avatar
                          }
                          onError={() => setErrorAvatar(true)}
                          className="rounded-circle mr-1"
                          alt="Sharon Lessman"
                          width="40"
                          height="40"
                        />
                      </div>
                      <div className="flex-shrink-1 rounded py-2 px-3 ml-3 bg-light">
                        <div className="font-weight-bold mb-1 text-capitalize">{mentors.name}</div>
                        {itm.text}
                        <div className="text-muted small text-nowrap mt-2">
                          {moment(itm.created_at).format('hh:mm DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                        )
                      }
                    }) : null}
                  </div>
                </div>
              ) : (
                <Col md="12" className="text-center">
                  <Spinner animation="border" variant="danger" className="mt-2" />
                </Col>
              )}
              <div className="flex-grow-0 py-3 px-4 border-top">
                <div className="input-group">
                  <input
                    onChange={(e)=> setText(e.target.value)}
                    value={text}
                    type="text"
                    className="form-control"
                    placeholder="Ketik pesanmu disini"
                    style={{ height: 42 }}
                    onKeyDown={handleKeyDown}
                  />
                  <button className="btn-custom" type="button" onClick={()=> {postFeedback(text) }} style={{width: 50}}><i className="lab la-telegram-plane"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
