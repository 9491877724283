import React, { useContext, useEffect, useState } from "react";
import { Styles } from "./styles/course.js";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import Error500 from "../../components/Error500";
import EmptyData from "../../components/EmptyData";
import MetaTags from "react-meta-tags";
import { LoadingBall } from "../../components/LoadingBall";
import { ContextProvider } from "../../context/BaseContext";
import CourseItemGrid from "./components/CourseItemsGrid";
import {convertToSlug,paramsWebinars} from '../../util/Functions'

export default function Webinars() {
  const context = useContext(ContextProvider);

  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  async function getData() {
    setRefresh(true);
    try {
      let response = await context.getRequest("webinar");
      if (response) {
        console.log(response)
        setError(false);
        setRefresh(false);
        setData(response.data.data);
      }
    } catch (error) {
      setRefresh(false);
      setError(true);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="main-wrapper course-page" style={{ position: "relative" }}>
      <MetaTags>
          <title>Webinars | Wakool Academy</title>
          <meta name="title" content={"Webinars"} />
          <meta itemprop="name" content={"Webinars"}/>
      </MetaTags>
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Webinar"
        image={`${process.env.PUBLIC_URL}/assets/illustration9.png`}
      />
      {refresh ? (
        <div style={{ height: "50vh", marginTop: "100px" }}>
          <LoadingBall />
        </div>
      ) : (
        <Styles>
          {error ? (
            <Error500 refresh={getData.bind(this)} />
          ) : data.length != 0 ? (
            <section
              className="course-grid-area"
              style={{ paddingBottom: 150 }}
            >
              <div className="container">
                <div className="course-items">
                  <div className="row">
                    <CourseItemGrid data={data} />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <EmptyData />
          )}
        </Styles>
      )}

      <Footer />
    </div>
  );
}
