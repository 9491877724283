import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

export default function RenderLiveClassItem({ data }) {
  console.log("item live class: ", data);
  const dummyCover = process.env.PUBLIC_URL + `/assets/images/product-02.jpg`;
  const { title, description, img } = data.kelas_mentor;
  const { id, date_start, date_end, status, class_id } = data.event;
  const startDate = moment(date_start).locale("id").format("DD MMM YYYY");
  const endDate = moment(date_end).locale("id").format("DD MMM YYYY");

  return (
    <Col className="pl-2 pr-2" lg="3" md="4" sm="6">
      <div className="product-box">
        <div className="product-img text-center">
          <img
            src={img}
            alt={title ? title : "Live Class"}
            className="img-fluid"
            onError={(e) => (e.currentTarget.src = dummyCover)}
          />
        </div>
        <div className="product-content text-center">
          <div className="pro-title">
            <h5 className="mb-3">
              <a style={{ cursor: "text" }} onClick={(e) => e.preventDefault()}>
                {title}
              </a>
              <p
                className={
                  status == 11 ? "desc max-character-1" : "desc max-character-2"
                }
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
              {status == 11 && (
                <p className="mt-1 mb-1">
                  <span className="badge badge-success">Berlangsung</span>
                </p>
              )}
            </h5>
            {/* <br/> */}
            <Link
              className="btn btn-outline-danger mt-2"
              to={
                process.env.PUBLIC_URL +
                "/learn-live-class/" +
                id +
                "/" +
                class_id
              }
            >
              {status == 11 ? "Gabung Live" : "Detail Kelas"}
            </Link>
          </div>
          <div className="pro-info">
            <span className="content" style={{ borderRight: "none" }}>
              <i className="las la-calendar mr-1" />
              {startDate} - {endDate}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
}
