import React, { useContext, useState } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/about.js";
// import DetailProfile from "./components/DetailProfile";
import { ContextProvider } from "../../context/BaseContext";
import Error401 from "../../components/Error401";

function Profile() {
    const context = useContext(ContextProvider);
    const [nomor,setNomor]=useState('')
    async function check(){
        //check-certificate-number
        let res=await context.getRequest(`check-certificate-number?nomor=${nomor}`)
        if(res){
            if(res.data.status){
                var data=res.data.data
                if(data.type=='tiktalk'){
                    window.open("/wakool-certificate/"+data.certificate_token,"_blank")
                    // window.location.href="/wakool-certificate/"+data.certificate_token
                }else if(data.type=='kelas'){
                    window.open("/certificate/"+data.certificate_token,"_blank")
                }
            }else{
                context.setNotification('danger','Nomor sertifikat tidak ditemukan')
            }
            console.log(res)
        }
    }
    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper about-page" style={{ position: 'relative' }}>
                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Check Certificate" image={`${process.env.PUBLIC_URL}/assets/illustration11.png`} />

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" style={{ marginTop: 20 }}>
                            <input type="text" style={{
                                width:'50rem',
                                height:50,
                                borderRadius:10,
                                borderColor:'#EF2026',
                                padding:20,
                                fontSize:20
                            }} onChange={(e)=>setNomor(e.target.value)} placeholder="Nomor Sertifikat"></input><br/>
                            <button 
                                onClick={()=>check()}
                                type="button" 
                                className="btn btn-danger"
                                style={{height:50,marginTop:20,width:'30rem',fontSize:18}}
                                >Cek Nomor Sertifikat</button>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}>
                    <Footer />
                </div>
            </div>
        </Styles>
    );
}

export default Profile;
