import React from "react";

export default function RenderLevelToString({ status }) {
  if (status === "1") {
    return <span>Pemula</span>;
  } else if (status === "2") {
    return <span>Menengah</span>;
  } else {
    return <span>Mahir</span>;
  }
}
