import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { convertToBase64 } from "../../../util/Functions";

export default function FormChat({ id, userData, context, setChatData }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const inputFile = useRef(null);

  const [fileUpload, setfileUpload] = useState(null);
  const [previewImg, setpreviewImg] = useState("");

  const onSubmitChat = async (data) => {
    let form = new FormData();
    form.append("class_id", id);
    form.append("type", "siswa");
    form.append("user_id", userData.id);
    form.append("content", data.chats);
    form.append("content_type", "text");

    let response = await context.postRequest("chat-siswa-push", form, "form");
    // console.log("POST", response);
    if (response) {
      reset();
      setChatData(response.data.reverse());
    }
  };

  async function onSubmitChatImg() {
    let form = new FormData();
    form.append("class_id", id);
    form.append("type", "siswa");
    form.append("user_id", userData.id);
    form.append("content", fileUpload);
    form.append("content_type", "image");

    let response = await context.postRequest("chat-siswa-push", form, "form");
    // console.log("POST", response);
    if (response) {
      hendleResetFileUpload();
      setChatData(response.data.reverse());
    }
  }

  async function handleUploadImage(file) {
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        convertToBase64(file[0])
          .then((result) => {
            let img64 = result.split(",")
            // console.log(result);
            setpreviewImg(result);
            setfileUpload(
              JSON.stringify({
                name: file[0].name,
                data: img64[1],
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert("file yang di upload bukan gambar");
      }
    }
  }

  function hendleResetFileUpload() {
    setpreviewImg("");
    setfileUpload(null);
  }

  // function beforeChatSentToAPI(data) {
  //   let rowChat = chatData;
  //   rowChat.push({
  //     user_id: userData.id,
  //     content_type: "text",
  //     content: data.chats,
  //     img: "",
  //     created_at: new Date(),
  //     type: "siswa",

  //     users: {
  //       name: userData.name,
  //       avatar: userData.avatar,
  //     },
  //   });
  //   if (rowChat.length != chatData) {
  //     setChatData(rowChat);
  //     console.log("new chat", rowChat);
  //   }
  // }

  return (
    <form
      onSubmit={handleSubmit(onSubmitChat)}
      className="form-chat form-group m-t-15 d-flex mb-0 mt-2"
    >
      <div className="input-group-prepend">
        <button
          onClick={() => inputFile.current.click()}
          type="button"
          className="btn btn-icon polos mt-2 mr-1"
          title="upload gambar"
        >
          <i style={{ fontSize: "25px" }} className="far fa-image"></i>
          <input
            onChange={(event) => handleUploadImage(event.target.files)}
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
          />
        </button>
      </div>
      {previewImg ? (
        <div className="p-2 form-img">
          <div
            onClick={() => hendleResetFileUpload()}
            className="remove-image text-center"
          >
            <i className="fas fa-times"></i>
          </div>
          <img src={previewImg} alt="preview-file" />
        </div>
      ) : (
        <textarea
          {...register("chats", {
            required: "Maaf, chat yang di kirim tidak boleh kosong",
          })}
          type="text"
          className="form-control"
        />
      )}
      <div className="form-icon m-2">
        <button
          type={fileUpload ? "button" : "submit"}
          onClick={() => fileUpload && onSubmitChatImg()}
          className="btn btn-icon"
        >
          <i className="lab la-telegram-plane"></i>
        </button>
      </div>
    </form>
  );
}
