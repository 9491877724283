import React, { Component, Fragment, useContext, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import Pagination from './../../../components/Pagination';
import Error500 from '../../../components/Error500';
import EmptyData from '../../../components/EmptyData';
import ListItem from './ListItem'

function CourseItemList({ pagination, changePage, data, refresh, reload, error }) {
    if (refresh) {
        return (
            <Col md="12" className="text-center mt-2 mb-2">
                <Spinner animation="border" variant="danger" />
            </Col>
        )
    } else {
        if (error) {
            return <Col md="12" className="text-center"><Error500 refresh={reload()} /></Col>
        }
        if (data.length != 0) {
            return (
                <Fragment>
                    {/* Course Item */}
                    {
                        data.map((data, i) => {
                            return <ListItem data={data} key={i} />
                        })
                    }

                    <Col md="12" className="text-center">
                        <Pagination pagination={pagination} changePage={changePage.bind(this)} />
                    </Col>

                </Fragment>
            )
        } else {
            return <Col md="12" className="text-center"><EmptyData /></Col>
        }
    }
}

export default CourseItemList
