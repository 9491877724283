import React, { Component, useContext, useEffect, useState } from "react";
import Datas from "./data/testimonial/testimonial-slider.json";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import { Styles } from "./components/styles/testimonialSlider.js";
import { ContextProvider } from "./context/BaseContext";
import { convertToSlug, paramsMentor } from "./util/Functions";
import {
  currencyFormat,
  getRating,
  minuteToHour,
  NewPrices,
  generateParamsQueryClass,
  paramsWebinars
} from "./util/Functions";
import {shareHost} from './util/host'

function Highlight() {
  const context = useContext(ContextProvider);
  const [data, setData] = useState([]);
  async function getMentorHomes() {
    let response = await context.getRequest("highlight");
    if (response) {
      console.log("HIGHLIGHT : ", response.data.data);
      setData(response.data.data);
    }
  }
  useEffect(() => {
    getMentorHomes();
  }, []);
  const settings = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <Styles>
      {/* Testimonial Slider */}
      <section id="highlight" className="testimonial-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4 style={{ color: "#182B49" }}>Kelas Pilihan</h4>
              </div>
            </Col>
            <Col md="12" className="testimonial-slider">
              {data.length > 0 ? (
                <Swiper {...settings}>
                  {data.map((val, i) => {
                    return (
                      <div className="course-item">
                        <a
                          href={linkHref(val)}
                        >
                          <div
                            className="course-image"
                            style={{
                              backgroundImage: `url(${val.cover})`,
                            }}
                          >
                            <div className="author-img d-flex">
                              <div className="img">
                                <img
                                  src={
                                    val.mentor_avatar
                                  }
                                  alt="avatar-mentor"
                                />
                              </div>
                              <div className="title">
                                <p>{val.mentor_name}</p>
                                <span>{val.mentor_title}</span>
                              </div>
                            </div>
                            <div className="course-price">
                              
                            </div>
                          </div>
                        </a>
                        <div className="course-content">
                          {/* {detail.is_live_class == true &&
                          detail.live_kelas_count > 0 ? (
                            <span class="badge badge-success">
                              Webinar tersedia
                            </span>
                          ) : null} */}
                          <h6 className="heading max-character-2 mb-2">
                            <a
                              href={linkHref(val)}
                            >
                              {val.title}
                            </a>
                          </h6>
                          <h6 className="category max-character-1">
                            {/* {renderCategory(detail.category_classes)} */}
                            {val.category}
                          </h6>
                          {/* <NewPrices detail={detail} priceHome={true} /> */}
                          <h5 className="price">Rp. {currencyFormat(val.price)}</h5>
                          <p className="desc"></p>
                          <div className="course-face d-flex justify-content-between">
                            <div className="duration">
                              <p>
                                {/* <i className="las la-clock"></i> */}
                                <span className="badge badge-warning">{val.type=='webinar'?'Webinar':'Kelas mandiri'}</span>
                                {/* {minuteToHour(detail.total_durasi)} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Swiper>
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
  function linkHref(val){
    var link=''
    if(val.type=='mandiri' && val.source=='wakool'){
      val.desc=val.description
      // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
      link=shareHost+val.class_id+"/"+val.slug
    }else if(val.type=='webinar' && val.source!='wakool'){
      val.id=val.class_id
      // link=paramsWebinars(val)
      link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
    } else if(val.is_prakerja){
      val.desc=val.description
      link=shareHost+val.class_id+"/"+val.slug
    }
    return link
    // console.log(link)
  }
}

export default Highlight;
