import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { colors } from "../../../components/common/element/elements";
import RenderContentItem from "./RenderContentItem";

function RenderSectionItem({
  setIsTimeline,
  section,
  idx,
  setIsMateriShow,
  isMateriShow,
  setTypeContent,
  setUrlContent,
  setidContentActive,
  idContentActive,
  videoToBumper,
  cannotChangeContent,
  setListFilesByContent,
  context,
  setMessageBrokenFile,

}) {
  // function _done(v){
  //   console.log('function done', v)
  //   setActivity(v)
  // }
  return (
    <Card>
      <Card.Header className="p-2">
        <Accordion.Toggle
          onClick={() =>{ 
            setIsMateriShow(isMateriShow == idx ? 0 : idx)
          }}
          className="p-1"
          as={Button}
          variant="text"
          eventKey={idx}
        >
          <i
            style={{ fontSize: "18px", color: colors.text2 }}
            className={
              isMateriShow == idx
                ? "fas fa-chevron-down"
                : "fas fa-chevron-right"
            }
          ></i>
          <span
            style={{
              fontSize: "15px",
              fontWeight: "500",
              color: colors.black2,
            }}
            className="ml-2"
          >
            {section.title}
          </span>
        </Accordion.Toggle>
      </Card.Header>

      {section.contents_user?.map((content, i) => (
        <RenderContentItem
          setIsTimeline={setIsTimeline}
          key={i}
          content={content}
          eventKey={idx}
          setTypeContent={setTypeContent}
          setUrlContent={setUrlContent}
          setidContentActive={setidContentActive}
          idContentActive={idContentActive}
          videoToBumper={videoToBumper}
          cannotChangeContent={cannotChangeContent}
          setListFilesByContent={setListFilesByContent}
          context={context}
          setMessageBrokenFile={setMessageBrokenFile}
        />
      ))}
    </Card>
  );
}

export default RenderSectionItem;
