import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { host } from "../../../util/host";

export default function Deskripsi({ data }) {
  const url = `${
    window.location.protocol + "//" + window.location.host
  }/detail-class/${data.id}`;
  const message = `Hai, saya ingin membagikan kursus ${data.title}`;

  const [copied, setcopied] = useState(false);

  function returnLink(id, slug, type) {
    const apilink = "https://share.wakool.academy/";
    let links = apilink + `${id}/${slug}?media=${type}`;
    return links;
  }

  return (
    <>
      <div className="course-desc">
        <h5>Deskripsi</h5>
        <p dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>
      <div className="course-feature">
        <h5>Prerequisite</h5>
        <p dangerouslySetInnerHTML={{ __html: data.prerequisite }} />
      </div>
      <div className="course-share">
        <h5>Bagikan kelas ini</h5>
        <ul className="social list-unstyled list-inline">
          <li className="list-inline-item">
            <FacebookShareButton
              url={returnLink(data.id, data.slug, "facebook")}
              quote={message}
              hashtags={["#hastag", "tag"]}
            >
              <a>
                <i className="fab fa-facebook-f"></i>
              </a>
            </FacebookShareButton>
          </li>
          <li className="list-inline-item">
            <TwitterShareButton
              url={returnLink(data.id, data.slug, "twitter")}
              title={message}
            >
              <a>
                <i className="fab fa-twitter"></i>
              </a>
            </TwitterShareButton>
          </li>
          <li className="list-inline-item">
            <LinkedinShareButton
              url={returnLink(data.id, data.slug, "linkedin")}
              summary={message}
            >
              <a>
                <i className="fab fa-linkedin-in"></i>
              </a>
            </LinkedinShareButton>
          </li>
          <li className="list-inline-item">
            <WhatsappShareButton
              url={returnLink(data.id, data.slug, "whatsapp")}
              title={message}
            >
              <a>
                <i className="fab fa-whatsapp"></i>
              </a>
            </WhatsappShareButton>
          </li>
          <li className="list-inline-item">
            <TelegramShareButton
              url={returnLink(data.id, data.slug, "telegram")}
              title={message}
            >
              <a>
                <i className="fab fa-telegram-plane"></i>
              </a>
            </TelegramShareButton>
          </li>

          <li className="list-inline-item">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="tooltip-copy">
                  {copied ? "Copied" : "Copy to clipboard"}
                </Tooltip>
              }
            >
              <a
                href="javascript:void(0)"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(
                    returnLink(data.id, data.slug, "url")
                  );
                  setcopied(true);
                }}
                onMouseLeave={() =>
                  setTimeout(function () {
                    setcopied(false);
                  }, 1000)
                }
              >
                <i className="fas fa-copy"></i>
              </a>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
    </>
  );
}
