import React, {
  Component,
  Fragment,
  useContext,
  useState,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import { Col, Spinner } from "react-bootstrap";
import {
  currencyFormat,
  getRating,
  minuteToHour,
  Prices,
  NewPrices,
  generateParamsQueryClass,
} from "../../../util/Functions";
import { ContextProvider } from "../../../context/BaseContext";
import {shareHost} from '../../../util/host'

function GridItem({ data }) {
  const context = useContext(ContextProvider);
  const [wishlist, setWishlist] = useState(
    data.favorite == null ? false : true
  );
  const [user, setUser] = useState(null);
  const [diskon, setDiskon] = "";
  var cat = data.categories.map((val) => val.title);
  useEffect(() => {
    let u = localStorage.getItem("profile");
    if (u) {
      let j = JSON.parse(u);
      setUser(j);
    }
  }, []);
  // console.log("IMAGE ",data.img)
  return (
    <Col lg="6" md="12">
      <div className="course-item">
        <a
          href={linkHref(data)}
          // to={
          //   process.env.PUBLIC_URL +
          //   "/detail-class/" +
          //   data.id +
          //   generateParamsQueryClass(data)
          // }
        >
          <div
            className="course-image"
            style={{ backgroundImage: `url('${data.img}')` }}
          >
            <div className="author-img d-flex">
              <div className="img">
                <img src={data.mentors.avatar} alt="" />
              </div>
              <div className="title">
                <p>{data.mentors.name}</p>
                <span>{data.mentors.total_kelas} Kelas</span>
              </div>
            </div>
            <div className="course-price">
              <p
                onClick={(e) => {
                  e.preventDefault()
                  if (context.login) {
                    setWishlist(wishlist ? false : true);
                    likeUnLike(data.id);
                  } else {
                    context.setNotification("danger", "Anda belum login");
                  }
                }}
              >
                {wishlist ? (
                  <i className="fas fa-heart" style={{ color: "#DE1D22" }}></i>
                ) : (
                  <i className="far fa-heart" style={{ color: "white" }}></i>
                )}
              </p>
            </div>
          </div>
        </a>
        <div className="course-content">
          <h6 className="heading max-character-2">
            <a
              href={linkHref(data)}
              // to={
              //   process.env.PUBLIC_URL +
              //   "/detail-class/" +
              //   data.id +
              //   generateParamsQueryClass(data)
              // }
            >
              {data.title}
            </a>
          </h6>
          <h6 className="category max-character-1">{cat.join(",")}</h6>
          {/* <h6 className="price">Rp. {currencyFormat(data.prices)}</h6> */}
          <NewPrices detail={data} priceHome={true} />
          <p
            className="desc max-character"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></p>
          <div className="course-face d-flex justify-content-between">
            <div className="duration">
              <p>
                <i className="las la-clock"></i>
                {minuteToHour(data.total_durasi)}
              </p>
            </div>
            <div className="rating">
              <ul className="list-unstyled list-inline">
                {getRating(parseFloat(data.total_vote ? data.total_vote : 0))}
                <li className="list-inline-item">
                  ({parseFloat(data.total_vote ? data.total_vote : 0)})
                </li>
              </ul>
            </div>
            <div className="student">
              {/* <p>
                <i className="las la-user"></i>
                {data.total_siswa}
              </p> */}
              <p>
                <i class="las la-fire-alt"></i>
                {data.view_count}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
  async function likeUnLike(id) {
    if (id != undefined) {
      let par = wishlist ? "0" : "1";
      await context.getRequest("favorite/" + id + "/" + par);
      // console.log('favorite/' + id + '/' + par, data.favorite)
    }
  }
  function linkHref(val){
    var link=''
    console.log("VAL : ",val)
    link=shareHost+val.id+"/"+val.slug
    // if(val.type=='mandiri' && val.source=='wakool'){
    //   val.desc=val.description
    //   // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
    //   link=shareHost+val.class_id+"/"+val.slug
    // }else if(val.type=='webinar' && val.source!='wakool'){
    //   val.id=val.class_id
    //   // link=paramsWebinars(val)
    //   link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
    // }
    return link
    // console.log(link)
  }
}

export default GridItem;
