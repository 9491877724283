import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/account.js";
import firebase from "../../config/firebase.config";
import { ContextProvider } from "../../context/BaseContext";
import { setStorage, encryptString } from "../../util/Functions";
import MetaTags from "react-meta-tags";

function Login({ history, location }) {
  const context = useContext(ContextProvider);
  const [refresh, setRefresh] = useState(false);
  var providerGoogle = new firebase.auth.GoogleAuthProvider();
  providerGoogle.addScope("https://www.googleapis.com/auth/userinfo.email");
  providerGoogle.addScope("https://www.googleapis.com/auth/userinfo.profile");

  var providerFacebook = new firebase.auth.FacebookAuthProvider();
  providerFacebook.addScope("public_profile");
  providerFacebook.addScope("email");

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [registerManual, setRegisterManual] = useState(null);
  const [formSocmed, setFormSocmed] = useState("");

  useEffect(() => {
    // console.log("LOCATION", location);
  }, []);

  return (
    <Styles>
      <MetaTags>
        <title>Login | Wakool Academy</title>
        <link
          rel="canonical"
          href={"https://wakool.academy/login"}
        />
        <meta name="title" content={'Login'} />
        <meta itemprop="name" content={'Login'}/>
      </MetaTags>
      {/* Main Wrapper */}
      <div className="main-wrapper login-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox
          title="Masuk"
          image={`${process.env.PUBLIC_URL}/assets/illustration14.jpg`}
        />

        {/* Login Area */}
        <section className="login-area">
          <Container>
            <Row>
              <Col md="12">
                {registerManual != 0 ? (
                  <div className="login-box">
                    <div className="login-title text-center">
                      <h3>Masuk</h3>
                    </div>
                    <form id="form_login" className="form">
                      <p className="form-control">
                        <label htmlFor="login_user">Email</label>
                        <input
                          onChange={(e) => setUsername(e.target.value)}
                          type="text"
                          placeholder="Masukkan alamat email anda"
                          id="login_user"
                        />
                        <span className="login_input-msg">
                          Email tidak boleh kosong
                        </span>
                      </p>
                      <p className="form-control">
                        <label htmlFor="login_password">Password</label>
                        <div className="row">
                          <div className="pr-0 col-10">
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              type={showPassword ? "text" : "password"}
                              placeholder="*******"
                              id="login_password"
                            />
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              onClick={() =>
                                setShowPassword(showPassword ? false : true)
                              }
                              className="btn btn-icon btn-light"
                            >
                              {showPassword ? (
                                <i class="fas fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </button>
                          </div>
                        </div>
                        <span className="login_input-msg">
                          Password tidak boleh kosong
                        </span>
                      </p>
                      <button
                        disabled={refresh}
                        className="btn-login"
                        onClick={(e) => {
                          e.preventDefault();
                          loginManual();
                        }}
                      >
                        Masuk &nbsp;
                        {refresh ? (
                          <Spinner animation="border" variant="light" />
                        ) : null}
                      </button>
                      <div className="save-forget-password d-flex justify-content-center">
                        {/* <div className="save-passowrd">
                                                    <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Save Password</label>
                                                </div> */}
                        <div className="forget-password">
                          <Link to={process.env.PUBLIC_URL + "/forgotpassword"}>
                            Lupa Password?
                          </Link>
                        </div>
                      </div>
                      <div className="not_account-btn text-center">
                        <p>
                          Tidak memiliki akun?{" "}
                          <Link to={process.env.PUBLIC_URL + "/registration"}>
                            Daftar Sekarang
                          </Link>
                        </p>
                        <p className="mt-1">
                          Masuk sebagai mentor?{" "}
                          <a href="https://mentor.wakool.academy/auth">
                            Masuk Mentor
                          </a>
                        </p>
                      </div>
                      <div className="social-login text-center">
                        <p>Masuk lewat sosial media</p>
                        <ul className="list-unstyled list-inline">
                          <li className="list-inline-item">
                            <a
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                signInSocMed("google");
                              }}
                            >
                              <i className="fab fa-google"></i> Google
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                signInSocMed("facebook");
                              }}
                            >
                              <i className="fab fa-facebook-f"></i> Facebook
                            </a>
                          </li>
                          {/* <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i> Twitter</a></li> */}
                        </ul>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="login-box">
                    <div className="login-title text-center" style={{marginBottom:30}}>
                      <h5>*Form buat password baru</h5>
                    </div>
                    <form id="form_login" className="form">
                      <p className="form-control">
                        <label htmlFor="login_user">Email</label>
                        <input
                          readOnly
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          type="text"
                          placeholder="Masukankan email anda"
                          id="login_user"
                        />
                        <span className="login_input-msg">
                          Email tidak boleh kosong
                        </span>
                      </p>
                      <p className="form-control">
                        <label htmlFor="login_password">Password</label>
                        <Row>
                          <Col lg="10" md="10" sm="10" className="pr-0">
                            <input
                              onChange={(e) => setPassword(e.target.value)}
                              type={showPassword ? "text" : "password"}
                              placeholder="*******"
                              id="login_password"
                            />
                          </Col>
                          <Col lg="2" md="2" sm="2">
                            <button
                              type="button"
                              onClick={() =>
                                setShowPassword(showPassword ? false : true)
                              }
                              className="btn btn-icon btn-light"
                            >
                              {showPassword ? (
                                <i class="fas fa-eye-slash" />
                              ) : (
                                <i className="fa fa-eye" />
                              )}
                            </button>
                          </Col>
                        </Row>
                        <span className="login_input-msg">
                          Password tidak boleh kosong
                        </span>
                      </p>
                      <p className="form-control">
                        <label htmlFor="login_password">Ulangi Password</label>
                        <Row>
                          <Col lg="10" md="10" sm="10" className="pr-0">
                            <input
                              onChange={(e) => setPassword1(e.target.value)}
                              type={showPassword ? "text" : "password"}
                              placeholder="*******"
                              id="login_password"
                            />
                          </Col>
                          {/* <Col lg="2" md="2" sm="2">
                                                        <button type="button" onClick={() => setShowPassword1(showPassword1 ? false : true)} className="btn btn-icon btn-light">
                                                            {showPassword ? <i class="fas fa-eye-slash" /> : <i className="fa fa-eye" />}
                                                        </button>
                                                    </Col> */}
                        </Row>
                        <span className="login_input-msg">
                          Password tidak boleh kosong
                        </span>
                      </p>
                      <button
                        disabled={refresh}
                        className="btn-login"
                        onClick={(e) => {
                          e.preventDefault();
                          registerManualSocmed();
                        }}
                      >
                        Buat password &nbsp;
                        {refresh ? (
                          <Spinner animation="border" variant="light" />
                        ) : null}
                      </button>
                      <div className="text-right">
                        *akun anda telah terdaftar di wakool academy, jika anda ingin menambahkan fitur login tanpa socmed silahkan anda buat password baru anda diform ini, atau <a href="/">Skip proses ini</a>
                      </div>
                      <div className="save-forget-password d-flex justify-content-center">
                        {/* <div className="save-passowrd">
                                                    <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Save Password</label>
                                                </div> */}
                        {/* <div className="forget-password">
                                                    <Link to={process.env.PUBLIC_URL + "/forgotpassword"}>Lupa Password?</Link>
                                                </div> */}
                      </div>
                      {/* <div className="not_account-btn text-center">
                                                <p>Tidak memiliki akun? <Link to={process.env.PUBLIC_URL + "/registration"}>Daftar Sekarang</Link></p>
                                            </div>
                                            <div className="social-login text-center">
                                                <p>Masuk lewat sosial media</p>
                                                <ul className="list-unstyled list-inline">
                                                    <li className="list-inline-item"><a href="javascript:void(0)" onClick={()=>{signInSocMed('google')}}><i className="fab fa-google"></i> Google</a></li>
                                                    <li className="list-inline-item"><a href="javascript:void(0)" onClick={()=>{signInSocMed('facebook')}}><i className="fab fa-facebook-f"></i> Facebook</a></li>
                                                    
                                                </ul>
                                            </div> */}
                    </form>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );

  async function registerManualSocmed() {
    if (password == password1) {
      let form = JSON.stringify({
        email: username,
        password: password,
        remember_me: false,
      });
      // console.log(form)
      let response = await context.postRequest("register-manual", form);
      if (response) {
        // console.log("RESPONSE SOCMED MANUAL : ",response)
        await registerSocMed(formSocmed);
      }
    } else {
      context.setNotification("danger", "Password tidak sesuai");
    }
  }

  async function loginManual() {
    // await registerSocMed()
    let form = JSON.stringify({
      email: username,
      password: password,
      remember_me: false,
      oauth_provider: "manual",
      // name: "chandra",
    });
    // console.log(form);

    await registerSocMed(form);

    // await registerSocMed(form);
  }

  async function signInSocMed(socmed) {
    var provider = {};

    if (socmed == "google") {
      provider = providerGoogle;
    } else if (socmed == "facebook") {
      provider = providerFacebook;
    }

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // console.log("RESULT : ", result, result.additionalUserInfo.profile);
        var profile = result.additionalUserInfo.profile;
        var name = profile.name;
        var email = profile.email;
        setUsername(profile.email);
        var password = profile.id;
        var prov = socmed;
        let form = JSON.stringify({
          email: email,
          password: password,
          remember_me: false,
          name: name,
          oauth_provider: prov,
        });
        setFormSocmed(form);
        await registerSocMed(form);
        console.log("RESULT : ",result,form)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        context.setNotification("danger", error.message);
        // ...
      });
  }

  async function registerSocMed(form) {
    try {
      setRefresh(true);
      console.log("FORM REGISTER SOCMED : ",form);
      let responses = await context.postRequest("login", form);
      console.log("LOGIN ", responses);
      var manual = responses.manual;
      if (manual == 0) {
        // console.log('manual', manual)
        // setUsername(f.email)
        setRegisterManual(0);
        if (responses) {
          // console.log('responses', responses)
          console.log("SOCMED : ",responses)
          // context.setNotification('success','Haii')
          var f = JSON.parse(form);
          await setStorage("with", f.oauth_provider);
          // let us=await encryptString(f.email)
          // let pas=await encryptString(f.password)
          // let token=await encryptString(responses.access_token)

          let auth = await encryptString(f.email + "|" + f.password);
          await setStorage("username", auth);

          // await setStorage('username',us)
          // await setStorage('password',pas)
          await setStorage("token", responses.access_token);
          await setStorage("login", JSON.stringify(true));
          await setStorage("profile", JSON.stringify(responses.profile));
          //context.setToken(responses.access_token)
          // context.setStatusLogin(true)
          context.setValue("user", responses.profile);
          context.setValue("login", true);
          await setStorage("user_profile", JSON.stringify(responses.profile));
          context.setNotification("success", "Berhasil Login");
          //getUser()
          // history.goBack(null)
          // history.push("/");
          //context.getCountNotif();

        //   console.log("LOGIN RESPONSE ", responses);
        }
      } else {
        if (responses) {
          console.log("SOCMED : ",responses)
          // context.setNotification('success','Haii')
          var f = JSON.parse(form);
          await setStorage("with", f.oauth_provider);
          // let us=await encryptString(f.email)
          // let pas=await encryptString(f.password)
          // let token=await encryptString(responses.access_token)

          let auth = await encryptString(f.email + "|" + f.password);
          await setStorage("username", auth);

          // await setStorage('username',us)
          // await setStorage('password',pas)
          await setStorage("token", responses.access_token);
          await setStorage("login", JSON.stringify(true));
          await setStorage("profile", JSON.stringify(responses.profile));
          //context.setToken(responses.access_token)
          // context.setStatusLogin(true)
          context.setValue("user", responses.profile);
          context.setValue("login", true);
          await setStorage("user_profile", JSON.stringify(responses.profile));
          context.setNotification("success", "Berhasil Login");
          //getUser()
          // history.goBack(null)
          history.push("/");
          //context.getCountNotif();

        //   console.log("LOGIN RESPONSE ", responses);
        } else {
        }
      }
      //context.setLoading(false);
    } catch (error) {
      console.log("ERROR : ",error)
      // Object.keys(response.column).forEach(grup => {
      context.setNotification("danger", "Username dan password tidak sesuai");
      // context.setNotification('danger',JSON.stringify(error.response.data.message))
      // console.log("RESPONSE : ",JSON.stringify(error.message))
    }
    setRefresh(false);
  }
}

export default Login;
