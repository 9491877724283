import React, { Component, useContext, useEffect, useState} from 'react';
import Datas from './data/testimonial/testimonial-slider.json';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom'
import { Styles } from "./components/styles/testimonialSlider.js";
import { ContextProvider } from './context/BaseContext';
import {convertToSlug,paramsMentor} from './util/Functions'

function TestimonialSlider () {
    const context = useContext(ContextProvider);
    const [data,setData]= useState([])
    async function getMentorHomes(){
        let response = await context.getRequest('homes-mentor')
        if(response){
            console.log("HOMES MENTOR : ",response.data.data)
            setData(response.data.data)
        }
    }
    useEffect(() => {
        getMentorHomes()
    },[])
    const settings = {
        slidesPerView: 2,
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        spaceBetween: 30,
        watchSlidesVisibility: true,
        pagination: {
            el: '.slider-dot.text-center',
            clickable: true
        },
        breakpoints: {
            0: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            }
        }
    };
    return (
            <Styles>
                {/* Testimonial Slider */}
                <section className="testimonial-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>Instruktur Yang Tergabung</h4>
                                </div>
                            </Col>
                            <Col md="12" className="testimonial-slider">
                                {data.length>0?(
                                    <Swiper {...settings}>
                                        {
                                            data.map((val, i) =>{
                                                return(
                                                    
                                                        <div className="slider-item" key={i}>
                                                            <div className="desc" style={{minHeight:350}}>
                                                                {/* <h5>{data.testimonialTitle}</h5> */}
                                                                <p dangerouslySetInnerHTML={{__html:val.description}}/>
                                                            </div>
                                                            <div className="writer">
                                                                <Link to={process.env.PUBLIC_URL + `/instructor-details/${val.id}/personal/${convertToSlug(val.name)}${paramsMentor(val)}`}>
                                                                    <img src={val.avatar} className="slider-image" alt={val.name} />
                                                                    <h6>{val.name}</h6>
                                                                    <p>{val.title}</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    
                                                )
                                            })
                                        }
                                    </Swiper>
                                ):null}
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
}

export default TestimonialSlider
