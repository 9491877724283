import React, { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../../context/BaseContext";

export default function FormPass() {
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  let password = watch("newPassword");

  const [showOldP, setShowOldP] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConf, setShowConf] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onSubmitPassword = async (data) => {
    setRefresh(false);
    // console.log(data);
    let form = new FormData();
    form.append("old_password", data.oldPassword);
    form.append("new_password", data.newPassword);
    let response = await context.postRequest("change-password", form, "form");
    if (response) {
      context.setNotification("success", "Password berhasil di ubah");
      setRefresh(false);
    } else {
      context.setNotification(
        "danger",
        "Terjadi kesalahan, coba beberapa saat lagi"
      );
      setRefresh(false);
    }
  };

  return (
    <form id="user-info" onSubmit={handleSubmit(onSubmitPassword)}>
      <div className="form-group row">
        <label htmlFor="old-pass" className="col-sm-2 col-form-label">
          Password Lama
        </label>
        <div className="col-sm-10 col-lg-8">
          <div className="input-group mb-2">
            <input
              {...register("oldPassword", {
                required: "Password lama tidak boleh kosong",
              })}
              type={showOldP ? "text" : "password"}
              className="form-control"
              placeholder="Masukan password lama"
            />
            <div className="input-group-append">
              <button
                onClick={() => setShowOldP(showOldP ? false : true)}
                type="button"
                className="input-group-text"
                id="show1"
              >
                {showOldP ? (
                  <i className="fas fa-eye-slash" />
                ) : (
                  <i className="fa fa-eye" />
                )}
              </button>
            </div>
          </div>
          <div style={{ color: "red" }}>
            {errors.oldPassword && errors.oldPassword.message}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="new-pass" className="col-sm-2 col-form-label">
          Password Baru
        </label>
        <div className="col-sm-10 col-lg-8">
          <div className="input-group mb-2">
            <input
              {...register("newPassword", {
                required: "Password Baru tidak boleh kosong",
              })}
              type={showPass ? "text" : "password"}
              className="form-control"
              placeholder="Masukan password Baru"
            />
            <div className="input-group-append">
              <button
                onClick={() => setShowPass(showPass ? false : true)}
                type="button"
                className="input-group-text"
                id="show2"
              >
                {showPass ? (
                  <i className="fas fa-eye-slash" />
                ) : (
                  <i className="fa fa-eye" />
                )}
              </button>
            </div>
          </div>
          <div style={{ color: "red" }}>
            {errors.newPassword ? errors.newPassword.message : null}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="confrm-pass" className="col-sm-2 col-form-label">
          Konfirmasi Password
        </label>
        <div className="col-sm-10 col-lg-8">
          <div className="input-group mb-2">
            <input
              {...register("repassword", {
                required: "Kamu harus mengulangi password",
                validate: (val) => val == password || "Password tidak sama",
              })}
              type={showConf ? "text" : "password"}
              className="form-control"
              placeholder="Masukan ulang password"
            />
            <div className="input-group-append">
              <button
                onClick={() => setShowConf(showConf ? false : true)}
                type="button"
                className="input-group-text"
                id="show1"
              >
                {showConf ? (
                  <i className="fas fa-eye-slash" />
                ) : (
                  <i className="fa fa-eye" />
                )}
              </button>
            </div>
          </div>
          <div style={{ color: "red" }}>
            {errors.repassword ? errors.repassword.message : null}
          </div>
        </div>
      </div>
      <div className="form-group row mt-2">
        <label className="col-sm-2 col-form-label"></label>
        <div className="col-sm-10 col-lg-8">
          <button disabled={refresh} type="submit" className="btn btn-wakool">
            {refresh && <Spinner animation="border" variant="light" />}
            Simpan
          </button>
        </div>
      </div>
    </form>
  );
}
