import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav, Spinner } from "react-bootstrap";
import { Styles } from "./styles/courseFilter.js";
import CourseFilterItem from "./CourseFilter-item";
import { ContextProvider } from "../context/BaseContext.js";

function CourseFilter({ data, refresh }) {
  const context = useContext(ContextProvider)
  const [diskon,setDiskon]=useState({days:0,percentage:0})
  async function getPromoDiskon(){
    let d=await localStorage.getItem('diskon_new_user')
    if(d){
      var json=JSON.parse(d)
      setDiskon(json)
    }
    let response=await context.getRequest(`promo-new-user`)
    if(response){
      localStorage.setItem('diskon_new_user',JSON.stringify(response.data.data))
      setDiskon(response.data.data)
      // console.log("RESPONSE DISKON NEW USER : ",response.data.data)
    }
  }
  useEffect(() => {
    getPromoDiskon()
  },[]);
  return (
    <Styles>
      {/* Course Area */}
      <section className="course-filter">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>Kami senantiasa menyediakan berbagai pelatihan terbaik untuk Anda</h4>
              </div>
            </Col>
            <Col md="12">
              {refresh ?
                <Col md="12" className="text-center mt-2 mb-2">
                  <Spinner animation="border" variant="danger" />
                </Col> :
                <Tab.Container defaultActiveKey={"Terbaru"}>
                  <div className="filter-btns text-center">
                    <Nav className="flex-column">
                      {data?.map((item, idx) => {
                        if (item.data.length != 0) {
                          return (
                            <Nav.Item key={idx}>
                              <Nav.Link eventKey={item.key}>{item.key}</Nav.Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </div>
                  <Tab.Content>
                    {data?.map((item, idx) => (
                      <Tab.Pane key={idx} eventKey={item.key}>
                        <Row className="filter-items">
                          {item.data.map((detail, index) => (
                            <CourseFilterItem diskon={diskon} key={index} detail={detail} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>}
            </Col>
            <Col md="12" className="text-center">
              <div className="viewall-btn">
                <Link to={refresh?'#': process.env.PUBLIC_URL + "/classes/0"}>
                  Lihat semua kelas
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default CourseFilter;
