import React, { useContext, useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo'
import Footer from '../../components/Footer';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseItemGrid from './components/CourseItemsGrid';
import { Styles } from './styles/course.js';
import MetaTags from "react-meta-tags";
import { ContextProvider } from '../../context/BaseContext';
import Loading from '../../components/Loading';
import Error401 from '../../components/Error401';
import Error500 from '../../components/Error500';
import EmptyData from '../../components/EmptyData';


function Wishlist() {
    const context = useContext(ContextProvider)
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState([])

    async function whislist() {
        try {
            let response = await context.getRequest('favorite')
            if (response) {
                setError(false)
                setData(response.data)
            }
        } catch (error) {
            setError(true)
        }
    }

    async function getData() {
        setRefresh(true)
        try {
            let response = await context.getRequest('favorite')
            if (response) {
                setError(false)
                setRefresh(false)
                setData(response.data)
            }
        } catch (error) {
            setRefresh(false)
            setError(true)
        }
        setRefresh(false)
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <div className="main-wrapper course-page" style={{ position: 'relative' }}>
            <MetaTags>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            {/* Header 2 */}
            <HeaderTwo />

            {/* Breadcroumb */}
            <BreadcrumbBox title="Favorit" image={`${process.env.PUBLIC_URL}/assets/illustration9.png`} />
            {refresh ?
                <Col md="12" className="text-center mt-2 mb-2">
                    <Spinner animation="border" variant="danger" />
                </Col> :
                <Styles>
                    {context.login ?
                        error ? <Error500 refresh={getData.bind(this)} /> :
                            data.data?.length != 0 ?
                                <section className="course-grid-area" style={{ paddingBottom: 150 }}>
                                    <Container>
                                        <div className="course-items">
                                            <Row>
                                                <CourseItemGrid data={data} getData={whislist.bind(this)} />
                                            </Row>
                                        </div>
                                    </Container>
                                </section>
                                :
                                <EmptyData />
                        :
                        <Error401 />}
                </Styles>}

            {/* Footer 2 */}
            {/* <div style={{ position: refresh ? 'fixed' : 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}> */}
                <Footer />
            {/* </div> */}
            {/* {refresh?null:<Footer />} */}
        </div>
    )
}

export default Wishlist