import React from "react";
import Datas from "../data/footer/footer.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/footerOne.js";

function FooterInfo() {
  return (
    <Styles>
      <div className="payments pb-5">
        <div
          // style={{ borderTop: "1px solid", marginTop: "25px" }}
          className="f-post"
        >
          <div className="text-center pt-5">
            <h5 className="mb-3">Metode Pembayaran</h5>
            <img
              style={{ width: "60%", height: "auto" }}
              src={process.env.PUBLIC_URL + "/assets/list-payments.png"}
              alt="list-payments"
            />
          </div>
        </div>
      </div>
      <footer className="footer1">
        <Container>
          <Row>
            <Col md="4">
              <div className="footer-logo-info">
                <img
                  style={{ width: "170px", height: "55px" }}
                  src={process.env.PUBLIC_URL + "/assets/logo_putih2.png"}
                  alt=""
                />
                <p>
                  Wakool Academy merupakan platform digital penyedia layanan
                  pelatihan online yang bisa Anda akses kapan saja di mana saja
                  melalui website ataupun aplikasi seluler. Kami menyediakan
                  berbagai pelatihan yang bekerjasama dengan berbagai lembaga
                  atau individu professional dibidangnya.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="f-links links-footer">
                <h5>Useful Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href={`https://mentor.wakool.academy/`}>
                      <i className="las la-angle-right"></i>Mentor
                    </a>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      <i className="las la-angle-right"></i>Kebijakan Privasi
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Online Support
                    </Link>
                  </li> */}
                </ul>
                <h5 className="mt-4 mb-4">Payment Support By:</h5>
                <img
                  style={{
                    width: "190px",
                    height: "auto",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    marginTop: "4px",
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/midtrans-logo.png"
                  }
                  alt="midtrans-logo"
                />
              </div>
            </Col>
            <Col md="4">
              <div className="f-post footer-logo-info">
                <h5>Information</h5>
                <ul className="list-unstyled">
                  <li>
                    <i className="las la-map-marker"></i>Metro Trade Center Blok
                    J9, Jl. Soekarno Hatta 590, Bandung, Jawa Barat 40286
                    Indonesia
                  </li>
                  <li>
                    <i className="las la-envelope"></i>info@wakool.academy
                  </li>
                  <li>
                    <i className="las la-phone"></i>(022) 7537609
                  </li>
                </ul>
              </div>
              <br />
              <div className="link-download">
                <a href="https://play.google.com/store/apps/details?id=com.msi_academy">
                  <img
                    style={{ width: "150px", height: "50px" }}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/download_playstore.png"
                    }
                    alt="playstore-download"
                  />
                </a>
                {/* <Link to={""}>
                  <img
                    style={{
                      width: "150px",
                      height: "48px",
                      marginLeft: "10px",
                    }}
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/download_appstore.png"
                    }
                    alt="playstore-download"
                  />
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </Styles>
  );
}

export default FooterInfo;
