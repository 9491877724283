import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ContextProvider } from "../../../context/BaseContext";
import { currencyFormat,paramsWebinars } from "../../../util/Functions";

function CourseItemGrid({ data }) {
  const history = useHistory();

  function formatTime(time) {
    let times = time.split(":");
    return times[0] + "." + times[1];
  }

  return (
    <Fragment>
      {/* Course Item */}
      {data.map((item, index) => (
        <GridItem live={item} key={index} />
      ))}
    </Fragment>
  );

  function GridItem({ live }) {
    console.log("LIVE : ",live)
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 pt-3 pb-3">
        <div style={{ height: "100%" }} className="course-item">
          <a
            // onClick={(e) => {
            //   if (live.source == "Glints") {
            //     e.preventDefault();
            //     history.push({
            //       pathname: paramsWebinars(live),
            //       state: live,
            //     });
            //   }
            // }}
            // href={
            //   live.source == "Glints"
            //     ? paramsWebinars(live)
            //     : live.link_url
            // }
            href={paramsWebinars(live)}
          >
            <div
              className="course-image"
              style={{
                backgroundImage: `url('${live.img}')`,
              }}
            ></div>
          </a>
          <div className="course-content">
            <p style={{ fontWeight: 500, color: "rgb(189, 189, 189)" }}>
              Webinar
            </p>
            <h6 className="heading">
              <a
                // onClick={(e) => {
                //   if (live.source == "Glints") {
                //     e.preventDefault();
                //     history.push({
                //       pathname: paramsWebinars(live),
                //       state: live,
                //     });
                //   }
                // }}
                // href={
                //   live.source == "Glints"
                //     ? paramsWebinars(live)
                //     : live.link_url
                // }
                href={paramsWebinars(live)}
              >
                {/* {live.is_active==0?(<>[Tidak Tersedia]</>):null}  */}
                {live.title}
              </a>
            </h6>
            <div className="author-img d-flex">
              <div className="img">
                <img src={live.mentor_avatar} alt="mentor-avatar" />
              </div>
              <div className="title">
                <p>{live.mentor_name}</p>
                <span>{live.mentor_title}</span>
              </div>
            </div>
            <div className="class-info d-flex flex-row mt-3">
              <p className="mr-3">
                <i className="las la-calendar mr-1"></i>
                <span>
                  {moment(live.date_start).format("dddd, DD MMM YYYY")}
                </span>
              </p>
              <p>
                <i className="lar la-clock mr-1"></i>
                <span>
                  {formatTime(live.time_start)} - {formatTime(live.time_end)}
                </span>
              </p>
            </div>
            <div className="class-info mt-2">
              <p className="mr-3">
                <i className="las la-money-bill-wave mr-1"></i>
                <span>Rp {currencyFormat(live.price.toString())}</span>
              </p>
            </div>
            {live.is_active==1?null:(<div className="class-info mt-2">
              <span className="badge badge-danger"><h6>Sudah berakhir</h6></span>
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseItemGrid;
