import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ContextProvider } from "../context/BaseContext";
import moment from "moment";
import {shareHost} from '../util/host'
import {
  currencyFormat,
  getRating,
  minuteToHour,
  NewPrices,
  generateParamsQueryClass,
  convertToSlug,
} from "../util/Functions";

export default function CourseFilterItem({ detail, diskon }) {
  const context = useContext(ContextProvider);
  const [wishlist, setWishlist] = useState(
    detail.favorite.length == 0 ? false : true
  );
  const [user, setUser] = useState(null);

  function renderCategory(categories) {
    let category = categories.map((item) => item.category.title);
    return category.join(", ");
  }
  useEffect(() => {
    let u = localStorage.getItem("profile");
    if (u) {
      let j = JSON.parse(u);
      setUser(j);
    }
  }, []);
  return (
    <Col lg="4" md="6">
      <div className="course-item">
        <a
          href={linkHref(detail)}
          // to={
          //   process.env.PUBLIC_URL +
          //   `/detail-class/${detail.id}${generateParamsQueryClass(detail)}`
          // }
        >
          <div
            className="course-image"
            style={{
              backgroundImage: `url('${detail.img}')`,
            }}
          >
            <div className="author-img d-flex">
              <div className="img">
                <img src={detail.mentors.avatar} alt="avatar-mentor" />
              </div>
              <div className="title">
                <p>{detail.mentors.name}</p>
                <span>{detail.total_content} Materi</span>
              </div>
            </div>
            <div className="course-price">
              <p
                onClick={(e) => {
                  e.preventDefault();
                  if (context.login) {
                    setWishlist(wishlist ? false : true);
                    likeUnLike(detail.id);
                  } else {
                    context.setNotification("danger", "Anda belum login");
                  }
                }}
              >
                {wishlist ? (
                  <i className="fas fa-heart" style={{ color: "#DE1D22" }}></i>
                ) : (
                  <i className="far fa-heart" style={{ color: "white" }}></i>
                )}
              </p>
            </div>
          </div>
        </a>
        <div className="course-content">
          {detail.is_live_class == true && detail.live_kelas_count > 0 ? (
            <span class="badge badge-success">Webinar tersedia</span>
          ) : null}
          <h6 className="heading max-character-2 mb-2">
            <a
              href={linkHref(detail)}
              // to={
              //   process.env.PUBLIC_URL +
              //   `/detail-class/${detail.id}${generateParamsQueryClass(detail)}`
              // }
            >
              {detail.title}
            </a>
          </h6>
          <h6 className="category max-character-1">
            {/* {detail.category_classes[0].category.title} */}
            {renderCategory(detail.category_classes)}
          </h6>
          <NewPrices detail={detail} priceHome={true} />
          <p className="desc"></p>
          <div className="course-face d-flex justify-content-between">
            <div className="duration">
              <p>
                <i className="las la-clock"></i>
                {minuteToHour(detail.total_durasi)}
              </p>
            </div>
            <div className="rating">
              <ul className="list-unstyled list-inline">
                {getRating(
                  parseFloat(detail.total_vote ? detail.total_vote : 0)
                )}
                <li className="list-inline-item">
                  ({parseFloat(detail.total_vote ? detail.total_vote : 0)})
                </li>
              </ul>
            </div>
            <div className="student">
              {/* <p>
                <i className="las la-user"></i>
                {detail.total_siswa}
              </p> */}
              <p>
                <i class="las la-fire-alt"></i>
                {detail.view_count}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
  function GetPrice(){
    if(detail.is_self_paced){
      return <NewPrices detail={detail} priceHome={true} />
    }else if(detail.is_live_class){
      
    }
    return null
  }
  function linkHref(val){
    var link=''
    console.log("VAL : ",val)
    link=shareHost+val.id+"/"+val.slug
    // if(val.type=='mandiri' && val.source=='wakool'){
    //   val.desc=val.description
    //   // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
    //   link=shareHost+val.class_id+"/"+val.slug
    // }else if(val.type=='webinar' && val.source!='wakool'){
    //   val.id=val.class_id
    //   // link=paramsWebinars(val)
    //   link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
    // }
    return link
    // console.log(link)
  }
  // function Prices(){

  //   if(detail.discount){
  //     pricesReal(detail.discount.prices_disc)
  //     return (
  //       <div class="row" style={{paddingLeft:17}}>
  //         <h5 className="price">Rp. {currencyFormat(pricesReal(detail.discount.prices_disc))}</h5>
  //         <h6 className="price" style={{textDecorationLine:'line-through',fontSize:12,textVerticalAlign:'bottom'}}>Rp. {currencyFormat(detail.prices)}</h6>
  //       </div>
  //     )
  //   }else{
  //     var dis=pricesReal(detail.prices)
  //     // console.log("DIS ",dis,detail.prices)
  //     if(dis!=0 && dis!=detail.prices){
  //       return (
  //         <div class="row" style={{paddingLeft:17}}>
  //           <h5 className="price">Rp. {currencyFormat(dis)}</h5>
  //           <h6 className="price" style={{textDecorationLine:'line-through',fontSize:12,textVerticalAlign:'bottom'}}>Rp. {currencyFormat(detail.prices)}</h6>
  //         </div>
  //       )
  //     }else{
  //       return (
  //         <>
  //           <h5 className="price">Rp. {currencyFormat(detail.prices)}</h5>
  //         </>
  //       )
  //     }
  //   }
  // }
  // function pricesReal(price){
  //   if(user){
  //     var ca=user.created_at.split(" ")
  //     var nowDate = moment().format("YYYY-MM-DD");
  //     var date1 = moment(ca[0]);
  //     var date2 = moment(nowDate);
  //     var diff = date2.diff(date1,'days');
  //     // var startdate = moment().subtract(diskon.days, "days").format("YYYY-MM-DD");
  //     if(diff<=diskon.days){
  //       console.log("DIFF : ",price,price-((price*diskon.percentage)/100))
  //       return price-((price*diskon.percentage)/100)
  //     }else{
  //       console.log("DIFF : ",price)
  //       return price
  //     }console.log("DIFF : ",price,price-((price*diskon.percentage)/100))

  //   }else{
  //     return price-((price*diskon.percentage)/100)
  //   }
  //   // if(diskon.days!=0 && diskon.percentage!=0){
  //   //   console.log(moment)
  //   // }
  // }
  async function likeUnLike(id) {
    if (id != undefined) {
      let par = wishlist ? "0" : "1";
      await context.getRequest("favorite/" + id + "/" + par);
      console.log("favorite/" + id + "/" + par);
    }
  }
}
