import React, { Component } from 'react';
import { Col } from 'react-bootstrap'
import { Styles } from './styles/error'

export default function Error() {
    return (
        <Styles>
            <Col md="12" className="text-center mt-2 mb-2">
                <img src={`${process.env.PUBLIC_URL}/assets/empty_image.jpeg`} height='400px' width='400px' />
                <p>Data tidak ditemukan</p>
                <a href={process.env.PUBLIC_URL + "/"}>
                    <button className='enroll-btn'>Kembali ke Home</button>
                </a>
            </Col>
        </Styles>
    )
}