import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/account.js';
import { useForm } from "react-hook-form";
import { ContextProvider } from '../../context/BaseContext'
import { setStorage } from '../../util/Functions'


function ChangePassword({ history }) {

    const { token } = useParams();
    const context = useContext(ContextProvider)
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const [refresh, setRefresh] = useState(false)
    const [showPassword1, setShowPassword1] = useState(false)
    let password = watch('password')
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper login-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Ubah Password" image={`${process.env.PUBLIC_URL}/assets/illustration14.jpg`} />

                {/* Login Area */}
                <section className="login-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <div className="login-title text-center">
                                        <h3>Ubah Password</h3>
                                    </div>
                                    <form onSubmit={handleSubmit(send)} id="form_login" className="form">
                                        <p className="form-control">
                                            <label htmlFor="login_password">Password Baru</label>
                                            <Row>
                                                <Col lg="10" md="10" sm="10" className="pr-0">
                                                    <input {...register('password', { required: 'Kamu harus mengisi password' })} type={showPassword ? "text" : "password"} placeholder="*******" id="password" />
                                                    <div style={{ color: 'red' }}>{errors.password ? errors.password.message : null}</div>
                                                    <span className="registration_input-msg"></span>
                                                </Col>
                                                <Col lg="2" md="2" sm="2">
                                                    <button type="button" onClick={() => setShowPassword(showPassword ? false : true)} className="btn btn-icon btn-light">
                                                        {showPassword ? <i class="fas fa-eye-slash" /> : <i className="fa fa-eye" />}
                                                    </button>
                                                </Col>
                                            </Row>
                                            <span className="login_input-msg">Password tidak boleh kosong</span>
                                        </p>
                                        <p className="form-control">
                                            <label htmlFor="login_password">Konfirmasi Password</label>
                                            <Row>
                                                <Col lg="10" md="10" sm="10" className="pr-0">
                                                    <input {...register('repassword', {
                                                        required: "Kamu harus mengulangi password",
                                                        validate: (val) => val == password || 'Password tidak sama'
                                                    })} type={showPassword1 ? "text" : "password"} placeholder="*******" id="repassword" />
                                                    <div style={{ color: 'red' }}>{errors.repassword ? errors.repassword.message : null}</div>
                                                    <span className="registration_input-msg"></span>
                                                </Col>
                                                <Col lg="2" md="2" sm="2">
                                                    <button type="button" onClick={() => setShowPassword1(showPassword1 ? false : true)} className="btn btn-icon btn-light">
                                                        {showPassword1 ? <i class="fas fa-eye-slash" /> : <i className="fa fa-eye" />}
                                                    </button>
                                                </Col>
                                            </Row>
                                            <span className="login_input-msg">Password tidak boleh kosong</span>
                                        </p>
                                        <button disabled={refresh} className="btn-login">Kirim &nbsp;{refresh ? <Spinner animation="border" variant="light" /> : null}</button>
                                        <div className="save-forget-password d-flex justify-content-center">
                                            {/* <div className="save-passowrd">
                                                <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Save Password</label>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <Footer />

            </div>

        </Styles>
    )

    async function send(data) {
        try {
            let dt = Object.assign(data, { token: token })
            setRefresh(true)
            let response = await context.postRequest('reset', JSON.stringify(dt))
            if (response) {
                setRefresh(false)
                context.setNotification('success', response.message)
                history.push('/login')
            }
        } catch (error) {
            setRefresh(false)
            context.setNotification('danger', error.response.data.message)
        }
    }
}

export default ChangePassword