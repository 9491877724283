import React, { Component,useState} from 'react';
import { Styles } from '../styles/courseCategory.js';

function CourseCategory ({categories,listCategories}) {
    const [list,setList]=useState([])
    function setter(l){
        listCategories=l
    }
    return (
        <Styles>
            {/* Course Tag */}
            <div className="course-category">
                <h5>Kategori</h5>
                <ul className="category-item list-unstyled">
                    {categories.map((val,i)=>{
                        //if(parseInt(val.jml_course)>0){
                            return(
                                <li key={'categories-'+i} className="check-btn">
                                    <label htmlFor="cat1"><input type="checkbox" onChange={(v)=>{
                                        // console.log(v.target.checked,listCategories)
                                        var ll=list
                                        if(!v.target.checked){
                                            
                                            var ind=ll.indexOf(val.id.toString())
                                            // console.log("TRUE",ll,ind,val.id.toString())
                                            if(ind>-1){
                                                ll.splice(ind,1)
                                                setList(ll)
                                                // console.log('ini menghapus ',ll)
                                            }
                                        }else{
                                            ll.push(v.target.value)
                                            setList(ll)
                                        }
                                        listCategories(ll)
                                    }} id="cat1" className="check-box" value={val.id}/>{val.title}<span>({val.jml_course})</span></label>
                                </li>
                            )
                        // }
                        // return null
                    })}
                    {/* <li className="check-btn">
                        <label htmlFor="cat1"><input type="checkbox" id="cat1" className="check-box" />Web Development<span>(25)</span></label>
                    </li>
                    <li className="check-btn">
                        <label htmlFor="cat2"><input type="checkbox" id="cat2" className="check-box" />Graphic Design<span>(11)</span></label>
                    </li>
                    <li className="check-btn">
                        <label htmlFor="cat3"><input type="checkbox" id="cat3" className="check-box" />Php Programming<span>(17)</span></label>
                    </li>
                    <li className="check-btn">
                        <label htmlFor="cat4"><input type="checkbox" id="cat4" className="check-box" />React Js Learning<span>(23)</span></label>
                    </li>
                    <li className="check-btn">
                        <label htmlFor="cat5"><input type="checkbox" id="cat5" className="check-box" />Web Design<span>(19)</span></label>
                    </li> */}
                </ul>
            </div>
        </Styles>
    )
    
}

export default CourseCategory
