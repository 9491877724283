import React, { Component, Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Spinner } from "react-bootstrap";
import {
  currencyFormat,
  getRating,
  NewPrices,
  generateParamsQueryClass,
} from "../../../util/Functions";
import {shareHost} from '../../../util/host'
import { ContextProvider } from "../../../context/BaseContext";

function ListItem({ data }) {
  const context = useContext(ContextProvider);
  const [wishlist, setWishlist] = useState(
    data.favorite == null ? false : true
  );
  var cat = data.categories.map((val) => val.title);
  // console.log("IMAGE ",data.img)
  return (
    <Col md="12">
      <div className="course-item d-flex">
        <div className="course-image-box">
          <a
            href={linkHref(data)}
            // to={
            //   process.env.PUBLIC_URL +
            //   "/detail-class/" +
            //   data.id +
            //   generateParamsQueryClass(data)
            // }
          >
            <div
              className="course-image"
              style={{ backgroundImage: `url('${data.img}')` }}
            >
              <div className="author-img d-flex">
                <div className="img">
                  {/* <Link to={process.env.PUBLIC_URL + data.courseLink}> */}
                  <img src={data.mentors.avatar} alt="" />
                  {/* </Link> */}
                </div>
                <div className="title">
                  <p>{data.mentors.name}</p>
                  <span>{data.mentors.total_kelas} Kelas</span>
                </div>
              </div>
              <div className="course-price">
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    if (context.login) {
                      setWishlist(wishlist ? false : true);
                      likeUnLike(data.id);
                    } else {
                      context.setNotification("danger", "Anda belum login");
                    }
                  }}
                >
                  {wishlist ? (
                    <i
                      className="fas fa-heart"
                      style={{ color: "#DE1D22" }}
                    ></i>
                  ) : (
                    <i className="far fa-heart" style={{ color: "white" }}></i>
                  )}
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="course-content">
          {data.is_live_class == true && data.live_kelas_count > 0 ? (
            <span class="badge badge-success">Webinar tersedia</span>
          ) : null}
          <h6 className="heading max-character-2">
            <a
              href={linkHref(data)}
              // to={
              //   process.env.PUBLIC_URL +
              //   "/detail-class/" +
              //   data.id +
              //   generateParamsQueryClass(data)
              // }
            >
              {data.title}
            </a>
          </h6>
          <h6 className="category max-character-1">{cat.join(",")}</h6>
          {/* <h6 className="price">Rp. {currencyFormat(data.prices)}</h6> */}
          <NewPrices detail={data} priceHome={true} />
          <div className="rating">
            <ul className="list-unstyled list-inline">
              {getRating(parseFloat(data.total_vote ? data.total_vote : 0))}
              <li className="list-inline-item">
                ({parseFloat(data.total_vote ? data.total_vote : 0)})
              </li>
            </ul>
          </div>
          <p
            className="desc max-character"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></p>
          <Link
            className="details-btn"
            to={process.env.PUBLIC_URL + data.courseLink}
          >
            View Details
          </Link>
        </div>
      </div>
    </Col>
  );
  async function likeUnLike(id) {
    if (id != undefined) {
      let par = wishlist ? "0" : "1";
      await context.getRequest("favorite/" + id + "/" + par);
      console.log("favorite/" + id + "/" + par, data.favorite);
    }
  }
  function linkHref(val){
    var link=''
    console.log("VAL : ",val)
    link=shareHost+val.id+"/"+val.slug
    // if(val.type=='mandiri' && val.source=='wakool'){
    //   val.desc=val.description
    //   // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
    //   link=shareHost+val.class_id+"/"+val.slug
    // }else if(val.type=='webinar' && val.source!='wakool'){
    //   val.id=val.class_id
    //   // link=paramsWebinars(val)
    //   link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
    // }
    return link
    // console.log(link)
  }
}

export default ListItem;
