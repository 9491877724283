import React, { Component,useState } from 'react';
import { Styles } from '../styles/coursePrice.js';

function CoursePrice ({free,paid,setStatusPaid,setStatusFree}) {
    const [spaid,setPaid]=useState(false)
    const [sfree,setFree]=useState(true)
    return (
        <Styles>
            {/* Course Price */}
            <div className="course-price">
                <h5>Course Price</h5>
                <ul className="price-item list-unstyled">
                    <li className="check-btn">
                        {/* <label htmlFor="price1"><input type="checkbox" id="price1" className="check-box" />All<span>(121)</span></label> */}
                    </li>
                    <li className="check-btn">
                        <label htmlFor="price2"><input type="checkbox" onChange={(v)=>{
                            if(v.target.checked){
                                setStatusFree(true)
                            }else{
                                setStatusFree(false)
                            }
                            // console.log("Status : ",v.target.checked)
                        }} id="price2" className="check-box" value={true}/>Free<span>({free})</span></label>
                    </li>
                    <li className="check-btn">
                        <label htmlFor="price3"><input type="checkbox" onChange={(v)=>{
                            if(v.target.checked){
                                setStatusPaid(true)
                            }else{
                                setStatusPaid(false)
                            }
                        }} id="price3" className="check-box" value={true}/>Paid<span>({paid})</span></label>
                    </li>
                    <li className="check-btn">
                        {/* <label htmlFor="price4"><input type="checkbox" id="price4" className="check-box" />Scholarship<span>(35)</span></label> */}
                    </li>
                </ul>
            </div>
        </Styles>
    )
    
}

export default CoursePrice
