import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import Cropper from "react-easy-crop";
import { Styles } from "../styles/detailProfile.js";
import { ContextProvider } from "../../../context/BaseContext";
import {
  setStorage,
  getCroppedImg,
} from "../../../util/Functions";
import FormInfo from "./FormInfo.js";
import FormPass from "./FormPass.js";

export default function DetailProfile() {
  const context = useContext(ContextProvider);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [fileToCrop, setFileToCrop] = useState("");
  const [nameFileCrop, setNameFileToCrop] = useState("");

  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState("");
  const [previewAvatar, setPreviewAvatar] = useState("");
  const [show, setShow] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setPreviewAvatar("");
    setFileToCrop("");
  };

  const handleShow = () => {
    setShow(true);
    setPreviewAvatar(avatar);
  };

  const handleSaveAvatar = async (data) => {
    let form = new FormData();
    form.append("name", name);
    form.append("avatar", JSON.stringify(data));
    let response = await context.postRequest("updateUser", form, "form");
    if (response) {
      await setStorage("profile", JSON.stringify(response.data));
      await getAvatarProfile();
      context.setNotification("success", "Foto profile berhasil diubah");
      handleClose();
      setloading(false);
    } else {
      context.setNotification(
        "danger",
        "Terjadi kesalahan coba beberapa saat lagi"
      );
      handleClose();
      setloading(false);
    }
  };

  async function handleChangeAvatar(file) {
    if (file.length != 0) {
      if (file[0].type == "image/jpeg" || file[0].type == "image/png") {
        setFileToCrop(URL.createObjectURL(file[0]));
        setNameFileToCrop(file[0].name.replace(/\s/g, "_"));
      } else {
        alert("file yang di upload bukan gambar");
      }
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setloading(true);
    try {
      const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);
      // let file = dataURLtoFile(croppedImage, nameFileCrop);

      if (croppedImage) {
        let file = croppedImage.split(",");
        let data = {
          name: nameFileCrop,
          data: file[1],
        };
        handleSaveAvatar(data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  async function getAvatarProfile() {
    let dataProfile = JSON.parse(await localStorage.getItem("profile"));
    setAvatar(dataProfile.avatar);
    setName(dataProfile.name);
  }

  useEffect(() => {
    getAvatarProfile();
  }, []);
  return (
    <Styles>
      {/* Tab Box Area */}
      <section
        className="tab-section"
        style={{ backgroundColor: "white", paddingBottom: 150 }}
      >
        <Container>
          <Tab.Container defaultActiveKey="profile">
            <Row>
              <Col lg="3" md="4">
                <div className="pl-4 pr-4 ">
                  <div className="col-12 text-center containerImage p-0 mb-4">
                    <img
                      style={{
                        height: "170px",
                        width: "170px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src={
                        avatar
                          ? avatar
                          : process.env.PUBLIC_URL + `/assets/empty_user.png`
                      }
                      alt="avatar"
                      onError={() =>
                        setAvatar(
                          process.env.PUBLIC_URL + `/assets/empty_user.png`
                        )
                      }
                    />
                    <div className="overlay">
                      <span className="upload" onClick={handleShow}>
                        <i className="fas fa-portrait"></i> ubah
                      </span>
                    </div>
                  </div>
                </div>
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="profile">
                      <i className="las la-arrow-right"></i> Detail Profil
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="password">
                      <i className="las la-arrow-right"></i> Ganti Sandi
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link>
                      <i className="las la-arrow-right"></i> Keluar
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col lg="9" md="8">
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    <div className="col-12 d-flex justify-content-between mb-4 pl-0">
                      <h4 className="tab-title mb-0 pt-2">
                        Informasi Pengguna
                      </h4>
                      <button
                        title="Ubah data"
                        className="btn btn-light btn-icon"
                        onClick={() =>
                          setShowFormEdit(showFormEdit ? false : true)
                        }
                      >
                        <i className="fa fa-edit" />
                      </button>
                    </div>
                    <FormInfo
                      showFormEdit={showFormEdit}
                      setShowFormEdit={setShowFormEdit}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="password">
                    <h4 className="tab-title">Ganti Password</h4>
                    <FormPass />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ubah foto profil</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6">
                <Form style={{ marginTop: "40%" }}>
                  <Form.File
                    accept="image/*"
                    id="custom-file"
                    label={
                      nameFileCrop == ""
                        ? "Tidak ada file yang dipilih"
                        : nameFileCrop
                    }
                    custom
                    onChange={(event) => handleChangeAvatar(event.target.files)}
                  />
                </Form>
              </div>
              <div style={{ textAlign: "-webkit-center" }} className="col-6">
                {fileToCrop ? (
                  <div
                    className=""
                    style={{
                      position: "relative",
                      height: "170px",
                      width: "170px",
                    }}
                  >
                    <Cropper
                      image={fileToCrop}
                      crop={crop}
                      // rotation={rotation}
                      zoom={zoom}
                      aspect={3 / 3}
                      onCropChange={setCrop}
                      cropShape="round"
                      // onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                ) : (
                  <img
                    style={{
                      height: "170px",
                      width: "170px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    src={
                      previewAvatar
                        ? previewAvatar
                        : process.env.PUBLIC_URL + `/assets/empty_user.png`
                    }
                    // src={process.env.PUBLIC_URL + `/assets/images/author.jpg`}
                    alt="avatar"
                    onError={() =>
                      setPreviewAvatar(
                        process.env.PUBLIC_URL + `/assets/empty_user.png`
                      )
                    }
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer> */}
          <Button
            disabled={!fileToCrop || loading}
            variant="danger"
            size="lg"
            onClick={showCroppedImage}
          >
            Simpan{" "}
            {loading && (
              <Spinner className="ml-1" animation="border" variant="light" />
            )}
          </Button>
          {/* </Modal.Footer> */}
        </Modal>
      </section>
    </Styles>
  );
}
