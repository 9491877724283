import React, { useState, useContext } from "react";
import moment from "moment";
import { ContextProvider } from "../../../context/BaseContext";
import { useHistory } from "react-router-dom";

export default function LiveListItem({ data }) {
  const history = useHistory();
  const {
    topic,
    agenda,
    start_time,
    status,
    duration,
    registrants,
    zoom_records,
  } = data;
  const date = moment(start_time).locale("id").format("dddd, DD MMMM YYYY");
  const startTime = moment(start_time).locale("id").format("HH:mm");
  const calculateEnd = new Date(start_time).getTime() + duration * 60 * 1000; // in miliseconds
  const endTime = moment(new Date(calculateEnd)).locale("id").format("HH:mm");
  const context = useContext(ContextProvider);

  var e = moment(start_time, "YYYY-MM-DD hh:mm:ss")
    .add(duration, "minutes")
    .unix();
  var s = moment(start_time, "YYYY-MM-DD hh:mm:ss")
    .subtract(15, "minutes")
    .unix();
  var n = moment().unix();

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }

  async function getSignatureSiswa() {
    try {
      if (registrants.length > 0) {
        const response = await context.getRequest(
          `signature-siswa?registrant_id=${registrants[0].registrant_id}`
        );
        // console.log("signature: ", response.data.data);
        if (response.data.status) {
          const url = `${process.env.PUBLIC_URL}/zoom-fullscreen/${response.data.data}`;
          window.open(url);
        } else {
          alert("gagal generate signature");
        }
        // console.log("data", data);
        // console.log(">>", registrants[0].parse_query);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  function joinViaZoom() {
    try {
      if (registrants.length > 0) {
        // console.log("data", data);
        // console.log(">>", registrants[0]);
        window.open(registrants[0].join_url);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <li className="live-class-item">
      <div className="live-item-body">
        <p className="live-topic">{topic}</p>
        {agenda ? <p>{agenda}</p> : null}
        <div className="live-class-item-footer">
          <span className="live-date lecture-title d-flex align-items-center">
            <i className="las la-calendar" />
            <span className="mx-1">{date}</span>
            <span style={{ color: "#969696" }}>
              {" "}
              ({startTime} - {endTime})
            </span>
          </span>
        </div>
      </div>
      <div className="live-item-join">
        {registrants.length > 0 ? (
          <Button />
        ) : (
          <div className="text-center p-1">
            <button
              disabled={!zoom_records}
              type="button"
              onClick={() => {
                if (zoom_records) window.open(zoom_records.linkDownload);
              }}
              className="btn btn-danger mb-2"
            >
              Download
            </button>
            {zoom_records ? (
              <p>{bytesToSize(zoom_records.size)}</p>
            ) : (
              <p
                style={{
                  color: "red",
                  maxWidth: "fit-content",
                  fontSize: "11px",
                }}
              >
                *video record belum tersedia
              </p>
            )}
          </div>
        )}
      </div>
    </li>
  );
  function Button() {
    if (n >= s && n <= e) {
      return (
        <>
          <button
            onClick={getSignatureSiswa}
            className="btn btn-sm btn-danger d-flex align-items-center mr-2"
          >
            <i className="las la-video mr-1" />
            Join
          </button>
          <button
            onClick={joinViaZoom}
            className="btn btn-sm btn-primary d-flex align-items-center"
          >
            <i className="las la-video mr-1" />
            Join via zoom
          </button>
        </>
      );
    } else if (n <= s) {
      return (
        <div className="mb-1">
          <span className="badge badge-secondary mr-1">Belum dimulai</span>
        </div>
      );
    } else if (n >= e) {
      return (
        <div className="mb-1">
          <span className="badge badge-secondary mr-1">berakhir</span>
        </div>
      );
    }
  }
}
