import React, { useContext } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/about.js";
import DetailProfile from "./components/DetailProfile";
import { ContextProvider } from "../../context/BaseContext";
import Error401 from "../../components/Error401";

function Profile() {
  const context = useContext(ContextProvider);

  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page" style={{ position: 'relative' }}>
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="Profil" image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}/>

        {/* Tab Detail */}
        {context.login ? <DetailProfile /> : <Error401 />}

        {/* Footer */}
        <div style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}>
            <Footer />
        </div>
      </div>
    </Styles>
  );
}

export default Profile;
