import React, { Component } from "react";
import Datas from "../data/about-us/about-us.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import CountUp from "react-countup";
import { Styles } from "./styles/aboutUs.js";
import Highlight from '../Highlight'
import Prakerja from "../Prakerja";

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">Tentang kartu prakerja</h4>
                  <div className="about-image" style={{ marginTop: 30 }}>
                    <img
                      src={process.env.PUBLIC_URL + `/kartu-prakerja.jpeg`}
                      className="main-img"
                      alt=""
                    />
                    <p style={{ fontSize: 17, marginTop: 20 }}>Program Kartu Prakerja adalah program pengembangan kompetensi kerja dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan kecil.</p>
                    <p style={{ fontSize: 17, marginTop: 20 }}><i>
                      Program Kartu Prakerja merupakan program ramah difabel. Difabel dianjurkan untuk mendaftar dan mengikuti Program Kartu Prakerja.</i></p>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <h4 className="about-title">Cara Mengikuti Kursus Prakerja di Wakool Academy</h4>
                  <div className="faq-area">
                    <div className="faq-box">
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Beli Kupon</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Beli kupon pelatihan Prakerja Wakool Academy di digital platform/marketplace Prakerja:</p>
                          {/* <p>
                            <a href="https://www.tokopedia.com/kartu-prakerja/partner/arkademi/" target="_blank" tabindex="0">Tokopedia, </a><a href="https://m.bukalapak.com/kartu-prakerja/pencarian?keyword=wakool academy" target="_blank" tabindex="0">Bukalapak, </a></p> */}
                        </div>
                      </div>
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Buat Akun</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Buat akun di Wakool Academy. Pastikan anda mengisi nama dengan benar dan lengkap untuk tampil di sertifikat elektronik.</p>
                          <p>
                            <a href="https://wakool.academy/registration" target="_blank" tabindex="0">Daftar</a></p>
                        </div>
                      </div>
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Order pakai kupon</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Validasi dan gunakan kupon pelatihan Prakerja yang sudah anda dapatkan dapatkan dari marketplace untuk masuk ke dalam pelatihan.</p>

                        </div>
                      </div>
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Mulai belajar</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Masuk dan mulailah belajar di dalam kelas. Pelatihan diselenggarakan full online dan sudah tersedia. Anda bisa belajar kapan saja dimana saja.</p>

                        </div>
                      </div>
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Selesaikan pelatihan</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Ikuti dan selesaikan pelatihan online secara tuntas dengan menyelesaikan semua seri ajar, pre-test, dan post-test.</p>

                        </div>
                      </div>
                      <div className="faq-item">
                        <button className="accordion-button active">
                          <div className="accordion-icon"></div>
                          <p>Dapatkan sertifikat</p>
                        </button>
                        <div className="accordion-content show">
                          <p>Dapatkan sertifikat elektronik usai lulus pelatihan. Walkool Academy akan melaporkan sertifikat anda ke PMO Prakerja max 2 hari kerja. </p>

                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Row>
                                        <Col sm="4">
                                            <div className="counter-box box1 text-center">
                                                <h3><CountUp end={970} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Happy Students</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box2 text-center">
                                                <h3><CountUp end={130} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Teachers</p>
                                            </div>
                                        </Col>
                                        <Col sm="4">
                                            <div className="counter-box box3 text-center">
                                                <h3><CountUp end={340} duration={5} delay={1.5} /><i className="las la-plus"></i></h3>
                                                <p>Courses</p>
                                            </div>
                                        </Col>
                                    </Row> */}

                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="about-content" style={{ marginTop: 50 }}>
                  <h4 className="about-title text-center">Beli dimarketplace pilihanmu</h4>
                </div>
                <img className="text-center" src={process.env.PUBLIC_URL + "/tokopedia.jpeg"}></img>
                {/* <img className="text-center" src={process.env.PUBLIC_URL + "/bukalapak.jpeg"}></img> */}

              </Col>
              <Col md="6">
                <h4 className="about-title" style={{ marginTop: 50 }}>Syarat mendaftar</h4>
                <table style={{ marginTop: 20 }} cellSpacing={20} cellPadding>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20, width: 40 }}>1. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>WNI berusia 18 tahun ke atas.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }}>2. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>Tidak sedang menempuh pendidikan formal.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }}>3. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja bukan penerima upah, termasuk pelaku usaha mikro & kecil.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }}>4. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>Bukan penerima bantuan sosial lainnya selama pandemi COVID-19.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }}>5. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, ASN, Prajurit TNI, Anggota Polri, Kepala Desa dan perangkat desa dan Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }}>6. </td>
                    <td style={{ fontSize: 17, marginTop: 20 }}>Maksimal 2 NIK dalam 1 KK yang menjadi Penerima Kartu Prakerja.</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: 17, marginTop: 20 }} colSpan={2}>
                      <a href="https://dashboard.prakerja.go.id/daftar?utm_source=wakoolacademy" target="_blank" className="btn btn-danger btn-lg" style={{marginTop:30}}>Daftar sekarang</a>
                    </td>
                  </tr>
                </table>
              </Col>
            </Row>

          </Container>
          <br /><br />
          <Prakerja />
        </section>
      </Styles>
    );
  }
}

export default AboutUs;
