import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { set, useForm } from "react-hook-form";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { LoadingBall } from "../../components/LoadingBall";
import { Styles } from "./styles/course.js";
import Footer from "../../components/Footer";
import { ContextProvider } from "../../context/BaseContext";
import { removeSecTime, renderStatusPrivat } from "../../util/Functions";
import { Modal } from "react-bootstrap";

export default function PrivateDiscuss() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const context = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const [listDiscuss, setListDiscuss] = useState([]);
  const [detailClass, setDetailClass] = useState("");

  async function getDiscuss() {
    // console.log(id);
    try {
      let response = await context.getRequest(
        `getDiscussPrivateRequestSiswa/${id}`
      );
      console.log("RESPONSE : ", response);
      if (response) {
        setListDiscuss(response.data.data.discuss);
        setDetailClass(response.data.data);
        // console.log(response.data.data);
      }
    } catch (error) {
      console.log("ERROR : ", error);
    }
  }

  function hendleDefaultModalData(val) {
    if (val) {
      console.log(val);
      setValue("peserta", val.qty_user);
      setValue("date_start", val.date_start);
      setValue("date_end", val.date_end);
      setValue("time_start", removeSecTime(val.time_start));
      setValue("time_end", removeSecTime(val.time_end));
      setValue("type", val.type);
      setValue("alamat", val.alamat_lengkap);
      setValue("discuss", "");
      setValue("price", val.price);
      setValue("no_wa", val.no_wa);
      setValue("message", val.message);
    }
  }

  async function changeStatusKelas(status) {
    setLoading(true);
    let form = {
      id: id,
      status,
    };

    try {
      let response = await context.postRequest(
        `request-private-change-status`,
        form
      );
      console.log("RESPONSE : ", response);
      if (response) {
        context.setNotification(
          "success",
          `Pengajuan kelas berhasil ${status == 1 ? "disetujui" : "dibatalkan"}`
        );
        getDiscuss();
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR : ", error);
      context.setNotification(
        "danger",
        "Terjadi kesalahan, coba beberapa saat lagi"
      );
      setLoading(false);
    }
  }

  const onSubmit = async (data) => {
    // setloading(true);
    console.log(data, id);
    let form = {
      id: id,
      qty_user: data.peserta,
      date_start: data.date_start,
      date_end: data.date_end,
      time_start: data.time_start,
      time_end: data.time_end,
      type: data.type,
      alamat_lengkap: data.alamat,
      price: data.price,
      discuss: data.discuss,
      class_id: detailClass.class_id,
      no_wa: data.no_wa,
      message: data.message,
    };

    try {
      let response = await context.postRequest(`request-private`, form);
      console.log("RESPONSE : ", response);
      if (response) {
        setShow(false);
        context.setNotification("success", "Data diskusi berhasil dikirim");
        getDiscuss();
        // console.log(response.data.data);
        // setListDataPrivate(response.data.data);
        // setLoading(false);
      }
    } catch (error) {
      console.log("ERROR : ", error);
      // setLoading(false);
    }
  };

  useEffect(() => {
    getDiscuss();
  }, []);
  return (
    <div className="main-wrapper course-details-page">
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Diskusi Privat Kelas"
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {detailClass == "" ? (
        <section className="course-details-area" style={{ paddingBottom: 60 }}>
          <LoadingBall />
        </section>
      ) : (
        <Styles>
          {/* Course Details */}
          <section
            className="course-details-area"
            style={{ paddingBottom: 60 }}
          >
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title p-2">Detail Pengajuan Kelas</h5>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Dari
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.name}</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Kelas
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.title}</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "20px" }} className="p-2">
                          Metode
                        </td>
                        <td>:</td>
                        <td className="p-2">
                          {detailClass?.type == 1 ? "Online" : "Offline"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Tanggal
                        </td>
                        <td>:</td>
                        <td className="p-2">
                          <p className="m-0">
                            {moment(detailClass?.date_start).format(
                              "DD MMMM YYYY"
                            )}{" "}
                            s/d
                          </p>
                          <p className="m-0">
                            {moment(detailClass?.date_end).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Waktu
                        </td>
                        <td>:</td>
                        <td className="p-2">
                          {removeSecTime(detailClass?.time_start)} -{" "}
                          {removeSecTime(detailClass?.time_end)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Kuantitas
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.qty_user} Orang</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          WhatsApp
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.no_wa}</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Alamat
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.alamat_lengkap}</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Imformasi Tambahan
                        </td>
                        <td>:</td>
                        <td className="p-2">{detailClass?.message}</td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Harga
                        </td>
                        <td>:</td>
                        <td className="p-2">
                          Rp {detailClass?.price ? detailClass.price : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ maxWidth: "100px" }} className="p-2">
                          Status
                        </td>
                        <td>:</td>
                        <td className="p-2">
                          {detailClass?.status == 1 ? (
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#0f5132",
                                backgroundColor: "#d1e7dd",
                              }}
                              className="badge badge-pill badge-light"
                            >
                              Sepakat
                            </span>
                          ) : (
                            renderStatusPrivat(detailClass?.status)
                          )}
                        </td>
                      </tr>
                      <hr />
                      {detailClass.status == 5 ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-danger col-12 mb-2"
                            onClick={() => changeStatusKelas(40)}
                            disabled={loading}
                          >
                            Batalkan
                          </button>
                          <button
                            type="button"
                            className="btn btn-success col-12 mb-2"
                            onClick={() => changeStatusKelas(1)}
                            disabled={loading}
                          >
                            Setuju
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="text-right">
                    <button
                      disabled={detailClass.status != 5 || loading}
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        hendleDefaultModalData(listDiscuss[0]);
                        setShow(true);
                      }}
                    >
                      Ajukan penawaran
                    </button>
                  </div>
                  <hr />
                  <div style={{ height: "100vh", overflowX: "scroll" }}>
                    {listDiscuss.length == 0 ? (
                      <div className="text-center">
                        <p>Diskusi masih kosong</p>
                      </div>
                    ) : (
                      listDiscuss.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <div className="p-2 mt-3 mb-3">
                              <p
                                className="mb-1"
                                style={{
                                  fontStyle: "italic",
                                  fontSize: "14px",
                                }}
                              >
                                {moment(item.created_at).format(
                                  "DD MMM YYYY - HH:mm:ss"
                                )}
                              </p>
                              <h5 className="mb-4">Dari {item.from_role}</h5>
                              <div
                                className="p-1"
                                style={{
                                  backgroundColor: "floralwhite",
                                  fontSize: "12px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div className="p-1">
                                  <RenderStatusPenawaran stat={item.status} />
                                </div>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Jumlah Peserta
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {item?.qty_user} Orang
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Tanggal
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {moment(item?.date_start).format(
                                      "DD MMM YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(item?.date_end).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Waktu
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {removeSecTime(item?.time_start)} -{" "}
                                    {removeSecTime(item?.time_end)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Metode
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    {item?.type == 1 ? "Online" : "Offline"}
                                  </td>
                                </tr>
                                {item?.type == 2 && (
                                  <tr>
                                    <td
                                      style={{ maxWidth: "150px" }}
                                      className="p-1"
                                    >
                                      Alamat
                                    </td>
                                    <td>:</td>
                                    <td className="p-1">
                                      {item?.alamat_lengkap}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td
                                    style={{ maxWidth: "150px" }}
                                    className="p-1"
                                  >
                                    Harga
                                  </td>
                                  <td>:</td>
                                  <td className="p-1">
                                    Rp {item?.price ? item.price : "-"}
                                  </td>
                                </tr>
                              </div>
                              <p style={{ fontSize: "14px" }} className="mt-4">
                                {item.discuss ? `"${item.discuss}"` : null}
                              </p>
                            </div>
                            <hr />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* MODAL PENGAJUAN */}
          <Modal
            size="lg"
            backdrop="static"
            keyboard={false}
            show={show}
            onHide={() => setShow(false)}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group row">
                  <label htmlFor="users" className="col-sm-3 col-form-label">
                    Jumlah Peserta
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("peserta", {
                        required: "Jumlah peserta harus diisi",
                      })}
                      type="number"
                      className="form-control"
                      id="users"
                      placeholder="Masukkan jumlah peserta"
                    />
                    {errors.peserta ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.peserta.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="date_start"
                    className="col-sm-3 col-form-label"
                  >
                    Tanggal Mulai
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("date_start", {
                        required: "Tanggal mulai harus diisi",
                      })}
                      type="date"
                      className="form-control"
                      id="date_start"
                      placeholder="Masukkan tanggal mulai"
                    />
                    {errors.date_start ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.date_start.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="date_end" className="col-sm-3 col-form-label">
                    Tanggal Akhir
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("date_end", {
                        required: "Tanggal akhir harus diisi",
                      })}
                      type="date"
                      className="form-control"
                      id="date_end"
                      placeholder="Masukkan tanggal akhir"
                    />
                    {errors.date_end ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.date_end.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="time_start"
                    className="col-sm-3 col-form-label"
                  >
                    Waktu Mulai
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("time_start", {
                        required: "Waktu mulai harus diisi",
                      })}
                      type="time"
                      className="form-control"
                      id="time_start"
                      placeholder="Masukkan waktu mulai"
                    />
                    {errors.time_start ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.time_start.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="time_end" className="col-sm-3 col-form-label">
                    Waktu Akhir
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("time_end", {
                        required: "Waktu akhir harus diisi",
                      })}
                      type="time"
                      className="form-control"
                      id="time_end"
                      placeholder="Masukkan waktu akhir"
                    />
                    {errors.time_end ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.time_end.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="type" className="col-sm-3 col-form-label">
                    Metode
                  </label>
                  <div className="col-sm-8">
                    <select
                      {...register("type", {
                        required: "Pilih salah satu metode belajar",
                      })}
                      className="form-control"
                      id="type"
                    >
                      <option value="">- Pilih Metode -</option>
                      <option value="1">Online</option>
                      <option value="2">Offline</option>
                    </select>
                    {errors.type ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.type.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {watch("type") == 2 && (
                  <div className="form-group row">
                    <label htmlFor="alamat" className="col-sm-3 col-form-label">
                      Alamat
                    </label>
                    <div className="col-sm-8">
                      <textarea
                        {...register("alamat", {
                          required: "Alamat harus diisi",
                        })}
                        rows={3}
                        className="form-control"
                        id="alamat"
                        placeholder="Masukkan alamat"
                      ></textarea>
                      {errors.alamat ? (
                        <span className="ml-1" style={{ color: "red" }}>
                          {errors.alamat.message}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label htmlFor="price" className="col-sm-3 col-form-label">
                    Harga
                  </label>
                  <div className="col-sm-8">
                    <input
                      {...register("price", {
                        required: "Harga harus diisi",
                      })}
                      type="number"
                      className="form-control"
                      id="price"
                      placeholder="Masukkan harga kelas"
                    />
                    {errors.price ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.price.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="discuss" className="col-sm-3 col-form-label">
                    Catatan
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      {...register("discuss", {
                        required: "Catatan harus diisi",
                      })}
                      rows={3}
                      className="form-control"
                      id="discuss"
                      placeholder="Masukkan catatan"
                    ></textarea>
                    {errors.discuss ? (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.discuss.message}
                      </span>
                    ) : null}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => setShow(false)}
                  type="button"
                  className="btn btn-secondary mr-2"
                >
                  Batal
                </button>
                <button type="submit" className="btn btn-danger">
                  Ajukan Penawaran
                </button>
              </Modal.Footer>
            </form>
          </Modal>
          {/* END MODAL PENGAJUAN */}
        </Styles>
      )}

      {/* Footer 2 */}
      <Footer />
    </div>
  );

  function RenderStatusPenawaran({ stat }) {
    let status = "Penawaran Baru";
    let bgColor = "#d1e7dd";
    let fColor = "#0f5132";

    switch (stat) {
      case 45:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Penawaran Anda ditolak Admin";
        break;

      case 42:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Mentor membatalkan penawaran";
        break;

      case 40:
        bgColor = "#f8d7da";
        fColor = "#842029";
        status = "Anda membatalkan penawaran";
        break;

      default:
        break;
    }

    return (
      <span
        style={{
          fontSize: "11px",
          color: fColor,
          backgroundColor: bgColor,
        }}
        className="badge badge-pill badge-light"
      >
        {status}
      </span>
    );
  }
}
