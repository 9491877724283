import React, { useState,useEffect,useContext } from "react";
import { Styles } from "./styles/course.js";
import { currencyFormat, convertToSlug } from "../../util/Functions";
import { Link, useLocation,useParams } from "react-router-dom";
// import { Nav, Tab } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import Error500 from "../../components/Error500";
import moment from "moment";
import MetaTags from "react-meta-tags";
import { encode, decode, Base64 } from "js-base64";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Spinner,
  Modal,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { ContextProvider } from "../../context/BaseContext.js";
export default function WebinarDetail() {
  const context = useContext(ContextProvider)
  const { id,slug} = useParams();
  const location = useLocation();
  const [webinar, setWebinar] = useState(null);
  const [copied, setcopied] = useState(false);
  const [media,setMedia]=useState(getParameterByNameNoDecode('media'))
  const message = `Hai, saya ingin membagikan webinars ${webinar?.title}`;
  function returnLink(type) {
    const apilink = `https://share.wakool.academy/${id}/webinar/${convertToSlug(webinar?.title)}?source=${webinar?.source}&media=${type}`;
    // let links = apilink + `${id}/webinar/${slug}?source=${webinar?.source}&media=${type}`;
    // console.log("LINKS : ",links)
    let links= apilink
    return links;
  }
  function formatTime(time) {
    let times = time.split(":");
    return times[0] + "." + times[1];
  }
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    var dec = decodeURIComponent(results[2].replace(/\+/g, " "));
    return Base64.decode(dec);
  }
  function getParameterByNameNoDecode(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    var dec = decodeURIComponent(results[2].replace(/\+/g, " "));
    return dec;
  }
  async function getWebinarDetail(){
    let response = await context.getRequest(`webinar-detail?id=${id}&source=${getParameterByNameNoDecode('source')}`)
    if(response){
      console.log("DETAIL ; ",response.data.data)
      setWebinar(response.data.data)
    }
  }
  useEffect(() => {
    getWebinarDetail()
    // console.log("MEDIA : ",getParameterByNameNoDecode('media'));
  },[])
  return (
    <div className="main-wrapper course-page" style={{ position: "relative" }}>
      {!media?(<MetaTags>
        <title>{getParameterByName("title")} | Wakool Academy</title>
        <link
          rel="canonical"
          href={"https://wakool.academy/webinars-detail/" + id+"/"+slug+"?source="+getParameterByNameNoDecode('source')}
        />
        <meta name="title" content={getParameterByName("title")} />
        <meta
          name="description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        <meta property="og:type" data-react-helmet="true" content="website" />
        <meta
          property="og:url"
          data-react-helmet="true"
          content={"https://wakool.academy/webinars-detail/" + id+"/"+slug+"?source="+getParameterByNameNoDecode('source')}
        />
        <meta
          property="og:title"
          data-react-helmet="true"
          content={getParameterByName("title")}
        />
        <meta
          property="og:description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        {/* <meta
          property="og:image"
          data-react-helmet="true"
          content={getParameterByName("img")}
        /> */}
        <meta
          property="twitter:card"
          data-react-helmet="true"
          content="summary_large_image"
        />
        <meta
          property="twitter:url"
          data-react-helmet="true"
          content={"https://wakool.academy/webinars-detail/" + id+"/"+slug+"?source="+getParameterByNameNoDecode('source')}
        />
        <meta
          property="twitter:title"
          data-react-helmet="true"
          content={getParameterByName("title")}
        />
        <meta
          property="twitter:description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        {/* <meta
          property="twitter:image"
          data-react-helmet="true"
          content={getParameterByName("img")}
        /> */}
      </MetaTags>):null}
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Webinar Detail"
        image={`${process.env.PUBLIC_URL}/assets/illustration9.png`}
      />

      <Styles>
        {/* Course Details */}
        <section className="course-details-area" style={{ paddingBottom: 150 }}>
          {webinar ? (
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <div className="course-details-top">
                    <div className="heading">
                      <h4>
                        {webinar.is_active==0?<>[Tidak Tersedia]</>:null}{webinar.title}{" "}
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: 400,
                            color: "#969696",
                          }}
                        >
                          by Glints
                        </p>
                      </h4>
                    </div>
                    <div className="course-top-overview">
                      <div className="d-flex overviews">
                        <div className="author">
                          <Link onClick={(e) => e.preventDefault()} to="">
                            <img
                              src={webinar.mentor_avatar}
                              alt="avatar-mentor"
                            />
                            <div className="author-name">
                              <h6>{webinar.mentor_name}</h6>
                              <p>{webinar.mentor_title}</p>
                            </div>
                          </Link>
                        </div>
                        <div className="price">
                          <h6>Harga</h6>
                          <p>Rp {currencyFormat(webinar.price.toString())}</p>
                        </div>
                      </div>
                    </div>
                    <div className="course-details-banner text-center">
                      <img
                        src={webinar.img}
                        alt="gambar-kelas"
                        className="img-fluid"
                      />
                    </div>
                    <div className="course-tab-list">
                      <Tab.Container defaultActiveKey="overview">
                        {/* <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="overview">Deskripsi</Nav.Link>
                          </Nav.Item>
                        </Nav> */}
                        {/* CONTENT */}
                        <Tab.Content>
                          {/* Deskripsi */}
                          <Tab.Pane
                            eventKey="overview"
                            className="overview-tab"
                          >
                            <div className="course-desc">
                              <h5>Voucher</h5>
                              <div
                                class="alert alert-warning d-flex flex-row"
                                role="alert"
                              >
                                <div className="p-2 mr-2 d-flex align-items-center border-right border-secondary">
                                  <p>
                                    Gunakan kode voucher <strong>{webinar.voucher}</strong> dari Wakool
                                    Academy untuk mendapatkan potongan harga
                                    sebesar 40% pada kelas ini
                                  </p>
                                </div>
                                <div className="p-2 ml-2 text-center">
                                  <h6>
                                    <i
                                      style={{ color: "#EE2025", fontSize:"25px" }}
                                      class="las la-ticket-alt"
                                    ></i>
                                    {webinar.voucher}
                                  </h6>
                                  <a
                                    href={webinar.link_url}
                                    target="_blank"
                                    style={{ backgroundColor: "#EE2025" }}
                                    className="btn btn-danger col-12 mt-4"
                                  >
                                    Lihat Kelas
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="course-desc">
                              <h5>Deskripsi</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: webinar.description,
                                }}
                              />
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="single-details-sidbar">
                    <div className="row">
                    <Col className="mb-4" md="12">
                        <div
                          id="share-social"
                          className="course-details-feature"
                        >
                          <h5 className="title">Bagikan kelas ini</h5>
                          <ul className="share social list-unstyled list-inline text-center">
                            <li className="list-inline-item">
                              <FacebookShareButton
                                url={returnLink(
                                  "facebook"
                                )}
                                quote={message}
                                hashtags={["#hastag", "tag"]}
                              >
                                <a>
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                              </FacebookShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TwitterShareButton
                                url={returnLink(
                                  "twitter"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-twitter"></i>
                                </a>
                              </TwitterShareButton>
                            </li>
                            <li className="list-inline-item">
                              <LinkedinShareButton
                                url={returnLink(
                                  "linkedin"
                                )}
                                summary={message}
                              >
                                <a>
                                  <i className="fab fa-linkedin-in"></i>
                                </a>
                              </LinkedinShareButton>
                            </li>
                            <li className="list-inline-item">
                              <WhatsappShareButton
                                url={returnLink(
                                  "whatsapp"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-whatsapp"></i>
                                </a>
                              </WhatsappShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TelegramShareButton
                                url={returnLink(
                                  "telegram"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-telegram-plane"></i>
                                </a>
                              </TelegramShareButton>
                            </li>

                            <li className="list-inline-item">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="tooltip-copy">
                                    {copied ? "Copied" : "Copy to clipboard"}
                                  </Tooltip>
                                }
                              >
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(
                                      returnLink(
                                        "url"
                                      )
                                    );
                                    setcopied(true);
                                  }}
                                  onMouseLeave={() =>
                                    setTimeout(function () {
                                      setcopied(false);
                                    }, 1000)
                                  }
                                >
                                  <i className="fas fa-copy"></i>
                                </a>
                              </OverlayTrigger>
                            </li>
                          </ul>
                        </div>
                      </Col>

                      <div className="mb-4 col-md-12">
                        <div className="course-details-feature">
                          <h5 className="title">Webinar</h5>
                          <div className="mt-2">
                            <h6>
                              <i
                                style={{ color: "#EE2025" }}
                                className="las la-calendar mr-1"
                              ></i>{" "}
                              Tanggal
                            </h6>
                            <p
                              style={{
                                marginLeft: "25px",
                                color: "#969696",
                                fontWeight: 400,
                              }}
                              className="mt-2"
                            >
                              {moment(webinar.date_start).format("DD MMM YYYY")}{" "}
                              - {moment(webinar.date_end).format("DD MMM YYYY")}
                            </p>
                          </div>
                          <div className="mt-2">
                            <h6>
                              <i
                                style={{ color: "#EE2025" }}
                                className="lar la-clock mr-1"
                              ></i>{" "}
                              Waktu
                            </h6>
                            <p
                              style={{
                                marginLeft: "25px",
                                color: "#969696",
                                fontWeight: 400,
                              }}
                              className="mt-2"
                            >
                              {formatTime(webinar.time_start)} -{" "}
                              {formatTime(webinar.time_end)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mb-4 col-md-12">
                        <div className="course-details-feature">
                          <h5 className="title">Kode Voucher</h5>
                          <div className="mt-2 text-center">
                            <h6>
                              <i
                                style={{ color: "#EE2025" }}
                                class="las la-ticket-alt"
                              ></i>{" "}
                              {webinar.voucher}
                            </h6>
                            <p
                              style={{
                                marginTop: "10px",
                                color: "#969696",
                                fontWeight: 400,
                              }}
                            >
                              Gunakan kode voucher{" "}
                              <strong>{webinar.voucher}</strong> dari Wakool
                              Academy untuk mendapatkan potongan harga pada
                              kelas ini
                            </p>
                          </div>
                          <a
                            href={webinar.link_url}
                            style={{ backgroundColor: "#EE2025" }}
                            className="btn btn-danger col-12 mt-4"
                          >
                            Lihat Kelas
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Error500 />
          )}
        </section>
      </Styles>

      <Footer />
    </div>
  );
}
