import { getToken } from "../../../config/firebase.config";

export async function initNotification(context) {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else {
    const permit = Notification.permission;
    switch (permit) {
      case "granted":
        if (localStorage.getItem("firebase_token") === null) {
          await initToken(context);
          window.location.reload();
          break;
        } else {
          console.log("User fb token already registered.");
          break;
        }
      case "denied":
        blockedNotifPopUp();
        break;
      case "default":
        reqNotifPopUp();
        break;
      default:
        break;
    }
  }
}

async function initToken(context) {
  let token = await getToken();
  let form = new FormData();
  // form.append('firebase_token',token)
  form.append("firebase_token_web", token);
  let response = await context.postRequest("updateUser", form, "form");
  if (response) {
    localStorage.setItem("firebase_token", token);
  }
}

const blockedNotifPopUp = () => {
  const container = document.createElement("div");
  container.innerHTML = `
        <i style="font-size:64px;" class="fas fa-info-circle text-warning mt-2 mb-2"></i>
        <p style="font-size:14px;" class="mb-0">Notifikasi diblokir. Izinkan notifikasi di pengaturan browser Anda.</p>
    `;
  window
    .swal({
      content: container,
      closeOnClickOutside: false,
      closeOnEsc: false,
      closeModal: true,
    })
    .then(() => {
      // window.location.reload()
    });
};

async function reqNotifPopUp() {
  const container = document.createElement("div");
  container.innerHTML = `
        <h3 class="text-dark">Akses Notifikasi</h3>
        <i style="font-size:64px;" class="fas fa-bell text-warning mt-2 mb-2"></i>
        <p style="font-size:15px;" class="mb-0">Wakool Academy membutuhkan akses notifikasi, izinkan?</p>
    `;
  window
    .swal({
      content: container,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ["Lain kali", "Izinkan"],
    })
    .then((permit) => {
      if (permit) {
        const overlayElement = document.createElement("div");
        overlayElement.innerHTML = `
            <div class="notif-overlay">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
        `;
        document.body.append(overlayElement);
        Notification.requestPermission().then(async (permit) => {
          if (permit === "granted") {
            await initToken();
            window.location.reload();
          } else if (permit === "denied") {
            document.querySelector(".notif-overlay").remove();
            blockedNotifPopUp();
          } else {
            document.querySelector(".notif-overlay").remove();
          }
        });
      }
    });
}
