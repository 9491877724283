import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  button.toChat {
    background: ${colors.gr_bg};
    position: fixed;
    bottom: 0px;
    right: 140px;
    width: 215px;
    height: 40px;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border: none;
    color: #ffffff;
    text-decoration: none;
    z-index: 1000;
    opacity: 1;
    transition: all 0.4s;
    cursor: pointer;
    &:hover {
      background: ${colors.gr_bg2};
    }

    .notif-unread {
      background-color: whitesmoke;
      border-radius: 50%;
      margin-left: 3px;
      color: black;
      padding: 1px;
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .box-chat {
    background: gainsboro;
    position: fixed;
    bottom: 40px;
    right: 140px;
    max-width: 465px;
    max-height: 520px;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    border: none;
    color: #ffffff;
    text-decoration: none;
    z-index: 1000;
    opacity: 1;
    transition: all 0.4s;

    .form-chat {
      .form-img{
        width: 100%;
        background: whitesmoke;
        border-radius: 5px;

        img{
          width: 65px;
          height: 65px;
          object-fit: cover;
        }
      }
      .remove-image {
        position: absolute;
        width: 20px;
        height: 20px;
        background: grey;
        border-radius: 50%;
        font-size: 14px;
        left: 123px;
        bottom: 63px;

        &:hover {
          background: dimgrey;
          cursor: pointer;
        }
      }

      textarea{
        background: whitesmoke; 
        border-color: whitesmoke;

        &:focus {
          box-shadow: none;
          border-color: #ee2025;
        }
      }
    }
  }

  .card-header {
    background: ${colors.gr_bg};
  }
`;
