import React, { Component, useContext, useEffect, useState } from "react";
import Datas from "../../data/instructor/details.json";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swiper from "react-id-swiper";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/instructor";
import { ContextProvider } from "../../context/BaseContext";
import {Base64} from 'js-base64'
import {
  getRating,
  minuteToHour,
  generateParamsQueryClass,
} from "../../util/Functions";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {shareHost} from '../../util/host'
import MetaTags from "react-meta-tags";

function InstructorDetails() {
  const { id, type,slug } = useParams();
  const context = useContext(ContextProvider);
  const [mentor, setMentor] = useState({});
  const [imageError, setImageError] = useState(false);
  const [jmlSiswa, setJmlSiswa] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const [kelas, setKelas] = useState([]);
  const [copied, setcopied] = useState(false);
  const settings = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    autoplay: false,
    spaceBetween: 30,
    watchSlidesVisibility: true,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  const message = `Hai, saya ingin membagikan mentor di Wakool Academy atas nama ${mentor?.name}`;
  function returnLink(id, slug, type) {
    const apilink = "https://share.wakool.academy/";
    let links = apilink + `${id}/mentor/${slug}?media=${type}`;
    // console.log("LINKS : ",links)
    return links;
  }
  async function getDetailMentor() {
    setRefresh(true);
    if (type == "personal") {
      let response = await context.getRequest("profile_mentor/" + id);
      if (response.hasOwnProperty("data")) {
        // console.log("RESPONSE", response.data.data);
        setMentor(response.data.data);
        setKelas(response.data.data.kelas);
        setJmlSiswa(response.data.data.total_siswa);
      }
    } else {
      let response = await context.getRequest("profile_instruktur/" + id);
      if (response) {
        var data = response.data.data;
        // console.log(">>", data);
        setMentor(data);
        setKelas(data.kelas);
        setJmlSiswa(0);
      }
    }

    setRefresh(false);
    //console.log(response.data)
  }

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {
    getDetailMentor();
  }, []);
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    var dec = decodeURIComponent(results[2].replace(/\+/g, " "));
    return Base64.decode(dec);
  }
  return (
    <Styles>
      <MetaTags>
        <title>{getParameterByName("name")} | Wakool Academy</title>
        <link
          rel="canonical"
          href={"https://wakool.academy/instructor-details/" + id+"/"+type+'/'+slug}
        />
        <meta name="title" content={getParameterByName("name")} />
        <meta
          name="description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        <meta property="og:type" data-react-helmet="true" content="website" />
        <meta
          property="og:url"
          data-react-helmet="true"
          content={"https://wakool.academy/instructor-details/" + id+"/"+type+'/'+slug}
        />
        <meta
          property="og:title"
          data-react-helmet="true"
          content={getParameterByName("name")}
        />
        <meta
          property="og:description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
      </MetaTags>
      {/* Main Wrapper */}
      <div
        className="main-wrapper instructor-details-page"
        style={{ position: "relative" }}
      >
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox
          title="Detail Instruktur"
          image={`${process.env.PUBLIC_URL}/assets/illustration9.png`}
        />

        {/* Instructor Details Area */}
        {refresh ? (
          <Col md="12" className="text-center mt-2 mb-2">
            <Spinner animation="border" variant="danger" />
          </Col>
        ) : (
          <section className="instructor-details-area">
            <Container>
              <Row>
                <Col md="3">
                  <div className="instructor-img">
                    <img
                      onError={() => setImageError(true)}
                      src={
                        imageError
                          ? process.env.PUBLIC_URL + `/assets/images/team-7.jpg`
                          : mentor.avatar
                      }
                      alt=""
                      className="img-fluid"
                      style={{ width: 150, height: 150 }}
                    />
                    <div className="instructor-content">
                      <h4>{mentor.name}</h4>
                      <span>{mentor.title}</span>
                      <p>{mentor.description}</p>
                      <p>
                      {mentor.youtube == null || mentor.youtube=='' ? null : (
                        <><b>Youtube</b> : <a onClick={(e) => {
                              e.preventDefault();
                              let check = isValidHttpUrl(mentor.youtube);
                              if (check) window.open(mentor.youtube);
                            }} href="#">{mentor.youtube}</a><br/></>
                        )}
                      {mentor.website == null || mentor.website=='' ? null : (
                        <><b>Website</b> : <a onClick={(e) => {
                              e.preventDefault();
                              let check = isValidHttpUrl(mentor.website);
                              if (check) window.open(mentor.website);
                            }} href="#">{mentor.website}</a><br/></>
                        )}
                      {mentor.linkedin == null || mentor.linkedin=='' ? null : (
                        <><b>Linkedin</b> : <a onClick={(e) => {
                              e.preventDefault();
                              let check = isValidHttpUrl(mentor.linkedin);
                              if (check) window.open(mentor.linkedin);
                            }} href="#">{mentor.linkedin}</a><br/></>
                        )}
                      {mentor.facebook == null || mentor.facebook=='' ? null : (
                        <><b>Facebook</b> : <a onClick={(e) => {
                              e.preventDefault();
                              let check = isValidHttpUrl(mentor.facebook);
                              if (check) window.open(mentor.facebook);
                            }} href="#">{mentor.facebook}</a><br/></>
                        )}
                      </p>
                      <div
                          id="share-social"
                          className="course-details-feature"
                        >
                          <h5 className="title">Bagikan mentor ini</h5><br/>
                          <ul className="share social list-unstyled list-inline text-center">
                            <li className="list-inline-item">
                              <FacebookShareButton
                                url={returnLink(
                                  id,
                                  slug,
                                  "facebook"
                                )}
                                quote={message}
                                hashtags={["#hastag", "tag"]}
                              >
                                <a>
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                              </FacebookShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TwitterShareButton
                                url={returnLink(
                                  id,
                                  slug,
                                  "twitter"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-twitter"></i>
                                </a>
                              </TwitterShareButton>
                            </li>
                            <li className="list-inline-item">
                              <LinkedinShareButton
                                url={returnLink(
                                  id,
                                  slug,
                                  "linkedin"
                                )}
                                summary={message}
                              >
                                <a>
                                  <i className="fab fa-linkedin-in"></i>
                                </a>
                              </LinkedinShareButton>
                            </li>
                            <li className="list-inline-item">
                              <WhatsappShareButton
                                url={returnLink(
                                  id,
                                  slug,
                                  "whatsapp"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-whatsapp"></i>
                                </a>
                              </WhatsappShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TelegramShareButton
                                url={returnLink(
                                  id,
                                  slug,
                                  "telegram"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-telegram-plane"></i>
                                </a>
                              </TelegramShareButton>
                            </li>

                            <li className="list-inline-item">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="tooltip-copy">
                                    {copied ? "Copied" : "Copy to clipboard"}
                                  </Tooltip>
                                }
                              >
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(
                                      returnLink(
                                        id,
                                        slug,
                                        "url"
                                      )
                                    );
                                    setcopied(true);
                                  }}
                                  onMouseLeave={() =>
                                    setTimeout(function () {
                                      setcopied(false);
                                    }, 1000)
                                  }
                                >
                                  <i className="fas fa-copy"></i>
                                </a>
                              </OverlayTrigger>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                </Col>
                <Col md="9">
                  <div className="instructor-course-title">
                    <h5>Kursus {mentor.name}</h5>
                  </div>
                  <div className="instructor-course-slider">
                    <Swiper {...settings}>
                      {kelas.map((data, i) => {
                        if (data != null) {
                          return <RenderItem data={data} key={i} />;
                        }
                        return null;
                      })}
                    </Swiper>
                  </div>
                </Col>
                <Col md="12"></Col>
              </Row>
            </Container>
          </section>
        )}

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );

  function RenderItem({ data }) {
    return (
      <Col md="4">
        <div className="course-item">
          <a
            // to={
            //   process.env.PUBLIC_URL +
            //   "/detail-class/" +
            //   data.id +
            //   generateParamsQueryClass(data)
            // }
            href={linkHref(data)}
          >
            <div
              className="course-image"
              style={{
                backgroundImage: `url(${data.img}), url(${process.env.PUBLIC_URL}'/assets/images/team-7.jpg')`,
              }}
            >
              <div className="author-img d-flex">
                <div className="img">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/${mentor.avatar}`
                    }
                    alt=""
                  />
                </div>
                {/* <div className="title">
                                                                    <p>{mentor.title}</p>
                                                                    <span>{data.authorCourses}</span>
                                                                </div> */}
              </div>
              {/* <div className="course-price">
                                                                <p>{data.price}</p>
                                                            </div> */}
            </div>
          </a>
          <div className="course-content">
            <h6 className="heading">
              <a
                // to={"/detail-class/" + data.id + generateParamsQueryClass(data)}
                href={linkHref(data)}
              >
                {data.title}
              </a>
            </h6>
            <p
              className="desc max-character"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            <div className="course-face d-flex justify-content-between">
              <div className="duration">
                <p>
                  <i className="las la-clock"></i>
                  {minuteToHour(data.total_durasi)}
                </p>
              </div>
              <div className="rating">
                <ul className="list-unstyled list-inline">
                  {getRating(parseFloat(data.total_vote ? data.total_vote : 0))}
                  <li className="list-inline-item">
                    ({parseFloat(data.total_vote ? data.total_vote : 0)})
                  </li>
                </ul>
              </div>
              <div className="student">
                <p>
                  <i className="las la-user"></i>
                  {data.total_siswa}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
    function linkHref(val){
      var link=''
      console.log("VAL : ",val)
      link=shareHost+val.id+"/"+val.slug
      // if(val.type=='mandiri' && val.source=='wakool'){
      //   val.desc=val.description
      //   // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
      //   link=shareHost+val.class_id+"/"+val.slug
      // }else if(val.type=='webinar' && val.source!='wakool'){
      //   val.id=val.class_id
      //   // link=paramsWebinars(val)
      //   link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
      // }
      return link
      // console.log(link)
    }
  }
}

export default InstructorDetails;
