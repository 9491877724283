import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Styles } from "./styles/search.js";

function Search() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showSearchInput, setshowSearchInput] = useState(false);
  const history = useHistory();

  const hendleSearch = async (data) => {
    // console.log(data);
    setshowSearchInput(false);
    history.push("/classes/0?search=" + data.search);
  };

  useEffect(() => {
    if (errors.search) {
      setshowSearchInput(false);
    }
  }, [errors.search]);

  return (
    <Styles>
      {/* Search Box */}
      {!showSearchInput && (
        <Link
          onClick={(e) => {
            e.preventDefault();
            setshowSearchInput(true);
          }}
          to=""
          className="nav-link nav-search"
        >
          <i className="las la-search"></i>
        </Link>
      )}
      {/* Input Search */}
      {showSearchInput && (
        <div className="mb-search-box mt-1">
          <form onSubmit={handleSubmit(hendleSearch)}>
            <input
              {...register("search", {
                required: "Maaf, form search tidak boleh kosong!",
              })}
              type="text"
              name="search"
              placeholder="Search Here"
            />
            <button type="submit">
              <i className="las la-search"></i>
            </button>
          </form>
        </div>
      )}
    </Styles>
  );
}

export default Search;
