import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import ReviewForm from "./ReviewForm";
import { getRating } from '../../../util/Functions'
import moment from 'moment'
import 'moment/locale/id'

export default function WebinarRutin({ historyWebinar = [], upcomingWebinar = [], isLoading, joinWebinar = () => { } }) {

  return (
    <Row>
      <Col md="12">
        <div className="review-comments">
          <h5>Webinar Mendatang</h5>
          {upcomingWebinar.length == 0 ? <p className="text-center mt-3">belum ada ulasan</p> : null}
          {upcomingWebinar.map((item, idx) => {
            return (
              <div key={item.id} className="mb-2">
                <p><b>{item.title}</b></p>
                <p>{moment(item.tanggal).format('DD MMM YYYY')} {moment(item.jam_mulai, "hh:mm").format("LT")} - {moment(item.jam_selesai, "hh:mm").format("LT")}</p>
                {
                  (item.status == 1 || item.status == 11) &&
                  <button
                    type="button"
                    disabled={isLoading}
                    onClick={() => joinWebinar(item.id, item.link_invitation)}
                    className="btn btn-sm btn-join mt-1"
                  >
                    Join
                  </button>
                }
              </div>
            )
          })}
          <h5 className="mt-5">Riwayat Webinar</h5>
          {historyWebinar.length == 0 ? <p className="text-center mt-3">belum ada ulasan</p> : null}
          {historyWebinar.map((item, idx) => {
            let style = "badge badge-sm badge-secondary"
            let status = "Menunggu Approval Mentor"
            if (item.kehadiran == 1) {
              style = "badge badge-sm badge-success"
              status = "Hadir"
            } else if (item.kehadiran == 4) {
              style = "badge badge-sm badge-danger"
              status = "Tidak Hadir"
            }
            return (
              <div key={item.id} className="mb-2">
                <p><b>{item.title}</b> <span className={style}>{status}</span></p>
                <p>{moment(item.tanggal).format('DD MMM YYYY')} {moment(item.jam_mulai, "hh:mm").format("LT")} - {moment(item.jam_selesai, "hh:mm").format("LT")}</p>
              </div>
            )
          })}
        </div>
      </Col>
    </Row>
  );
}
