import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  #highlight.testimonial-area {
      &:before {
        background: ${colors.bg2};
      }
  }

  .testimonial-area {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 63px 0;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      background: ${colors.bg1};
      opacity: 0.98;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .sec-title {
      h4 {
        color: #ffffff;
        line-height: 35px;
        font-weight: 600;
        max-width: 550px;
        margin: auto;
        margin-bottom: 43px;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .testimonial-slider {
      .slider-item {
        .desc {
          background-color: rgba(255, 255, 255, 0.08);
          padding: 30px 38px;
          border-radius: 5px;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            border-width: 15px 15px;
            border-style: solid;
            border-top-color: rgba(255, 255, 255, 0.08);
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            top: 100%;
            left: 47px;
            z-index: 1;
          }

          h5 {
            font-size: 18px;
            color: ${colors.border1};
            margin-bottom: 15px;
          }

          p {
            font-size: 13px;
            color: ${colors.border3};
            line-height: 25px;
          }
        }

        .writer {
          margin-top: 35px;
          margin-left: 30px;

          img {
            width: 65px;
            height: 65px;
            object-fit: cover;
            border-radius: 50%;
            float: left;
            margin-right: 15px;
          }

          h6 {
            color: ${colors.border1};
            padding-top: 10px;
            margin-bottom: 3px;
          }

          p {
            color: ${colors.border3};
          }
        }
      }

      .slider-dot {
        margin-top: 48px !important;

        .swiper-pagination-bullet {
          width: 22px;
          height: 9px;
          background: ${colors.text4};
          display: inline-block;
          margin: 3px;
          opacity: 1 !important;
          border-radius: 5px;
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background: ${colors.green};
        }
      }


      .course-item {
        border: 1px solid ${colors.border1};
        border-radius : 5px;
        transition : all 0.2s ease;
        overflow : hidden;
        margin-bottom: 30px;

        .course-image {
            width              : 100%;
            height             : 240px;
            background-size    : cover;
            background-position: center;
            background-repeat  : no-repeat;
            border-radius : 5px 5px 0 0;
            position: relative;

            .author-img {
                position: absolute;
                left    : 20px;
                bottom  : 20px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius : 50%;
                    margin-right: 5px;
                    object-fit: cover;
                }

                .title {
                    background: #ffffff;
                    padding   : 3px 8px;
                    border-radius : 5px;

                    p {
                        font-size    : 12px;
                        color        : ${colors.black1};
                        font-weight  : 500;
                        margin-bottom: -4px;
                    }

                    span {
                        font-size  : 11px;
                        color      : ${colors.text3};
                        font-weight: 500;
                    }
                }

            }

            .course-price {
                p {
                    font-size  : 21px;
                    // color      : #DE1D22;
                    background : #00000030;
                    position   : absolute;
                    right      : 20px;
                    bottom     : 20px;
                    padding    : 4px 7px;
                    font-weight: 500;
                    border-radius : 5px;
                    cursor : pointer;
                }
            }

            @media(max-width: 767px) {
                height: 185px;
            }
        }

        .course-content {
            background: #fff;
            padding   : 17px 22px;

            h6.heading {
                a {
                    font-size    : 17px;
                    color        : ${colors.black1};
                    font-weight  : 600;
                    // display      : inline-block;
                    margin-bottom: 10px;

                    &:hover {
                        color: ${colors.green};
                    }
                }
            }

            h6.category {
                font-size    : 14px;
                color        : ${colors.text2};
                font-weight  : 400;
                margin-bottom: 10px;
            }

            h6.price {
                color        : ${colors.green};
                font-weight  : 400;
                // display      : inline-block;
                margin-bottom: 10px;
            }

            p.desc {
                font-size     : 14px;
                color         : ${colors.text3};
                line-height   : 25px;
                border-bottom : 1px solid ${colors.border1};
                padding-bottom: 10px;
                margin-bottom : 12px;
            }

            .course-face {

                .duration,
                .student {
                    p {
                        font-size: 13px;
                        color    : ${colors.text3};

                        i {
                            font-size     : 16px;
                            color         : ${colors.green};
                            vertical-align: text-bottom;
                            margin-right  : 3px;
                        }
                    }
                }

                .rating {
                    ul {
                        li {
                            margin-right: 0;

                            i {
                                font-size: 14px;
                                color    : ${colors.yellow};
                            }

                            &:last-child {
                                font-size: 13px;
                                color    : ${colors.text3};
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        }
      }
    }

    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }
`;
