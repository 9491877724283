import React, { useContext, useEffect, useState } from "react";
import { Col, FormControl, InputGroup, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../../context/BaseContext";

export default function Practice({
  setPraktikDone,
  praktikDone,
  idContentActive,
  getDataKelas,
  setActivityDone,
}) {
  // DECLARATAION
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  console.log("dari komponen praktik", praktikDone);
  // STATE
  const [data, setData] = useState([]);
  const [detailAssigment, setDetailAssigment] = useState({});
  const [loading, setLoading] = useState(false);

  // FUNCTION
  const onSubmit = async (data) => {
    console.log("ini data upload", data);
    const form = new FormData();
    form.append("file", data.file[0]);
    form.append("content_id", idContentActive);
    let response = await context.postRequest("upload_assignment", form);
    if (response) {
      console.log("ini respon", response);
      context.setNotification("success", response.message);
      await setActivityDone(idContentActive);
      // await getDataKelas();
      window.location.reload()
    }
  };
  async function getDetailAssigment() {
    setLoading(true);
    let response = await context.getRequest(
      `preview_file_assignment?content_id=${idContentActive}`
    );
    if (response) {
      setData(response.data);
      setLoading(false);
    }
  }

  async function getAssigment() {
    setLoading(true);
    let response = await context.getRequest(
      `get_assignment_by_content_id/${idContentActive}`
    );
    if (response) {
      setDetailAssigment(response.data.data);
      setLoading(false);
      console.log("assigment by content", response.data.data);
    }
  }
  useEffect(() => {
    if (idContentActive) {
      setData({});
    }
    getDetailAssigment();
  }, [praktikDone, idContentActive]);

  useEffect(() => {
    if (idContentActive) {
      setData({});
    }
    getAssigment();
  }, [idContentActive, idContentActive]);
  //   async function getFileAssigment(){

  //   }
  // LIFECYCLE

  //RENDER
  return (
    <div
      style={{
        border: "none",
        height: "80vh",
        width: "100%",
      }}
    >
      {praktikDone ? (
        data ? (
          loading ? (
            <Col md="12" className="text-center">
              <Spinner animation="border" variant="danger" className="mt-2" />
            </Col>
          ) : (
            <>
              {data.type_file == "video/mp4" ? (
                <video controls  autoPlay={true}>
                  <source src={data.url} type="video/mp4" />
                </video>
              ) : data.type_file == "application/pdf" ||
                data.type_file == "image/png" || data.type_file == "image/jpg" || data.type_file == "image/jpeg" ? (
                <embed
                  type={data.type_file}
                  src={data.url}
                  style={{
                    border: "none",
                    height: "80vh",
                    width: "100%",
                  }}
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{
                    border: "none",
                    height: "80vh",
                    width: "100%",
                    paddingRight: 20,
                    paddingLeft: 20,
                  }}
                >
                  <h4>
                    OOppsss!, file tidak support untuk ditampilkan. Silahkan
                    download file!
                  </h4>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/nodata.svg`}
                    alt="not supported"
                    style={{ height: 250 }}
                  />
                  <a
                    href={data.url}
                    download="proposed_file_name"
                    className="btn-custom"
                  >
                    Download
                  </a>
                </div>
              )}
            </>
          )
        ) : (
          <p>Data Tidak DItemukan</p>
        )
      ) : loading ? (
        <Col md="12" className="text-center">
          <Spinner animation="border" variant="danger" className="mt-2" />
        </Col>
      ) : (
        <>
          <h5 className="pt-3">
            {detailAssigment ? detailAssigment.title : null}
          </h5>
          <img
            src={`${process.env.PUBLIC_URL}/upload.svg`}
            alt="upload"
            style={{ height: 230 }}
          />
          <div style={{ textAlign: "center", marginTop: 20, marginLeft: 20 }}>
            <p>{detailAssigment ? detailAssigment.description : null}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
            <div
              className="input-group mb-3"
              style={{ paddingLeft: 120, paddingRight: 120, paddingTop: 20 }}
            >
              <input
                type="file"
                className="form-control"
                placeholder="Upload File"
                aria-label="Upload File"
                aria-describedby="basic-addon2"
                {...register("file")}
                required
              />
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <i className="las la-file-upload"></i>
                </span>
              </div>
            </div>
            <button className="btn btn-success mb-4">Kirim Tugas</button>
          </form>
        </>
      )}
    </div>
  );
}
