import React, { useState, useContext, useEffect } from 'react'
import HeaderTwo from '../../components/HeaderTwo'
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import MetaTags from "react-meta-tags";
import { ContextProvider } from '../../context/BaseContext'
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Styles } from './styles/course.js';
import Error401 from '../../components/Error401';
import Error500 from '../../components/Error500';
import EmptyData from '../../components/EmptyData';
import { host } from '../../util/host';

export default function Index() {
    const context = useContext(ContextProvider)
    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [error, setError] = useState(false)

    async function getCertificate() {
        setRefresh(true)
        let response = await context.getRequest(`list-certificate`)
        if (response) {
            setData(response.data.data)
            console.log("CERTIFICATE : ", response.data.data)
        }
        setRefresh(false)
    }
    useEffect(() => {
        getCertificate()
    }, [])
    return (
        <div className="main-wrapper course-page" style={{ position: 'relative' }}>
            <MetaTags>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            {/* Header 2 */}
            <HeaderTwo />

            {/* Breadcroumb */}
            <BreadcrumbBox title="Daftar Sertifikat" image={`${process.env.PUBLIC_URL}/assets/illustration9.png`} />
            {refresh ?
                <Col md="12" className="text-center mt-2 mb-2">
                    <Spinner animation="border" variant="danger" />
                </Col> :
                <Styles>
                    {context.login ?
                        error ? <Error500 refresh={getCertificate.bind(this)} /> :
                            data.data?.length != 0 ?
                                <section className="course-grid-area" style={{ paddingBottom: 150 }}>
                                    <Container>
                                        <div className="course-items">
                                            <Row>
                                                <Items />
                                            </Row>
                                        </div>
                                    </Container>
                                </section>
                                :
                                <EmptyData />
                        :
                        <Error401 />}
                </Styles>}
        </div>
    )
    function Items() {
        return (
            <>
                {data.map((val, i) => {
                    var href=""
                    var img=""
                    if(val.source=='wakool'){
                        var spl=val.img.split('.')
                        href="/certificate/"+val.key
                        img=host+`images/kelas/${spl[0]}?ext=${spl[1]}`
                    }else{
                        var spl=val.img.split('.')
                        href='/wakool-certificate/'+val.key
                        img=host+`images/tiktalk/${spl[0]}?ext=${spl[1]}`
                    }
                    return (
                        <Col lg="4" md="6" sm="12" >
                            <div className="course-item" style={{minHeight:'26rem'}}>
                                <a
                                    href={href}
                                    target='_blank'
                                >
                                    <div
                                        className="course-image"
                                        style={{ backgroundImage: `url('${img}')` }}
                                    >
                                    </div>
                                </a>
                                <div className="course-content">
                                    <h6 className="heading">
                                        <a
                                            href={href}
                                            target='_blank'
                                        >
                                            {val.title}
                                        </a>
                                    </h6>
                                    {/* <button type="button" style={{marginVertical:20,marginBottom:20}} className="btn btn-danger float-right">Lihat Sertifikat</button> */}
                                </div>
                            </div>
                        </Col>
                    )
                })}

            </>
        )
    }
}