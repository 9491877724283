import React from "react";
import { useHistory, useParams } from "react-router-dom";

export default function ZoomFullscreen() {
  const history = useHistory();
  const {token} = useParams();
  
  return (
    <div>
      {/* <div style={{ background: "black" }} className="text-right p-2">
        <button
          onClick={() => history.goBack()}
          type="button"
          className="btn btn-outline-light mr-2"
        >
          <i className="las la-compress"></i> Exit fullscreen
        </button>
      </div> */}
      {token && (
        <iframe
          allowusermedia
          allow="camera *;microphone *"
          src={`https://zoom.wakool.academy:9999/meeting.html?${token}`}
          title="Zoom Academy"
          style={{ width: "100%", height: "100vh" }}
        />
      )}
    </div>
  );
}
