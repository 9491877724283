import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .loading-page {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding-top: 300px;
    font-size: 30px;
    font-family: sans-serif;
  }
`;
