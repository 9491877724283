import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { ContextProvider } from "../../context/BaseContext";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { LoadingBall } from "../../components/LoadingBall";
import { Styles } from "./styles/course.js";
import LiveList from "./components/LiveList";
import Deskripsi from "./components/Deskripsi";
import FileContents from "./components/fileContents";
import Review from "./components/Review";
import Error401 from "../../components/Error401";
import Error500 from "../../components/Error500";
import EmptyData from "../../components/EmptyData";
var fileDownload = require("js-file-download");

export default function LearnLiveCLass() {
  let mounted = useRef(true);
  const params = useParams();
  const { id, class_id } = useParams();
  const context = useContext(ContextProvider);
  const dummyAvatar = process.env.PUBLIC_URL + `/assets/images/author.jpg`;
  const dummyCover =
    process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`;
  const [kelas, setKelas] = useState(null);
  const [eventKelas, setEventKelas] = useState(null);
  const [mentor, setMentor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [joinZoom, setJoinZoom] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState([]);

  async function getData() {
    setRefresh(true);
    setLoading(true);
    try {
      const response = await context.getRequest(
        `detail-class-live?event_id=${id}`
      );
      // console.log("detail live class: ", params);
      setLoading(false);
      if (response) {
        setKelas(response.data.data);
        setMentor(response.data.data.kelas_mentor.mentors);
        setRefresh(false);
        setError(false);
        var kls = response.data.data;
        // alert(kls.id)
        // getFilePendukung(kls.id)
      }
    } catch (error) {
      setLoading(false);
      setRefresh(false);
      setError(true);
    }
    setRefresh(false);
  }
  async function getFilePendukung() {
    try {
      let response = await context.getRequest(
        "file-pendukung?class_id=" + class_id
      );
      // console.log("FILES : ", response.data);
      if (response) {
        setFile(response.data.data);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }
  useEffect(() => {
    if (mounted.current === true) {
      getData();
      getFilePendukung();
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <div className="main-wrapper course-details-page">
      {/* On get data loading */}

      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title={`Kelas Live`}
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {loading ? (
        <LoadingBall />
      ) : context.login ? (
        !error ? (
          <Styles>
            {/* Course Details */}
            <section className="course-details-area">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="course-details-top">
                      <div className="heading">
                        <h4>{kelas?.kelas_mentor.title}</h4>
                      </div>
                      <div className="course-top-overview">
                        <div className="d-flex overviews">
                          <div
                            className="author"
                            style={{ borderRight: "none" }}
                          >
                            <Link
                              to={`/instructor-details/${
                                mentor ? mentor.id : ""
                              }`}
                            >
                              <img
                                src={mentor ? mentor.avatar : dummyAvatar}
                                alt={mentor ? mentor.name : "Intruktur"}
                                onError={(e) =>
                                  (e.currentTarget.src = dummyAvatar)
                                }
                              />
                              <div className="author-name">
                                <h6>Instruktur</h6>
                                <p>{mentor ? mentor.name : ""}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <Container className="course-tab-list">
                        <Row className="tab-content">
                          <Col className="mb-3" xl="6">
                            <div className="course-details-banner text-center">
                              <img
                                className="img-fluid rounded"
                                src={kelas?.kelas_mentor.img}
                                alt={`${kelas?.kelas_mentor.title}`}
                                onError={(e) =>
                                  (e.currentTarget.src = dummyCover)
                                }
                              />
                            </div>
                          </Col>
                          <Col className="curriculum-tab mb-3" xl="6">
                            {kelas ? (
                              <LiveList
                                setJoinZoom={setJoinZoom}
                                data={kelas.event}
                              />
                            ) : (
                              <LoadingBall />
                            )}
                          </Col>
                        </Row>
                      </Container>
                      <div className="course-tab-list">
                        <Tab.Container defaultActiveKey="overview">
                          <Nav className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="overview">Deskripsi</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                            <Nav.Link eventKey="file-pendukung">File Pendukung</Nav.Link>
                          </Nav.Item> */}
                            <Nav.Item>
                              <Nav.Link eventKey="filePendukung">
                                File Pendukung
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="review">Ulasan</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane
                              eventKey="overview"
                              className="overview-tab"
                            >
                              {kelas ? (
                                <Deskripsi data={kelas.kelas_mentor} />
                              ) : (
                                ""
                              )}
                            </Tab.Pane>
                            <Tab.Pane
                              eventKey="filePendukung"
                              className="overview-tab"
                            >
                              <h5>File Pendukung</h5>
                              <div className="col-sm-8">
                                <ul className="list-unstyled feature-list">
                                  {file.length != 0 ? (
                                    <>
                                      {file.map((file, index) => (
                                        <RenderListFile
                                          key={index}
                                          file={file}
                                        />
                                      ))}
                                    </>
                                  ) : (
                                    <li className="text-center">
                                      <p>
                                        <i
                                          style={{
                                            fontSize: "30px",
                                            color: "red",
                                          }}
                                          className="fas fa-exclamation-circle mb-1"
                                        ></i>
                                      </p>
                                      <p>
                                        Kelas ini tidak memiliki file pendukung
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              {/* <table>
                              {file.map((val,i)=>{
                                return (
                                  <tr>
                                    <td>
                                      <a href={val.file_url} target="_blank">
                                        <h5>{val.nama_file}</h5>
                                      </a>
                                    </td>
                                  </tr>
                                )
                              })}
                              
                            </table> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="review" className="review-tab">
                              <Review
                                sertifikat="show"
                                data={[]}
                                reloadPage={function () {
                                  return null;
                                }}
                                ratings={null}
                                getRatings={function () {
                                  return null;
                                }}
                                getDataKelas={getData.bind(this)}
                                reload={refresh}
                                liveClass={true}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Styles>
        ) : (
          <Error500 refresh={getData.bind(this)} />
        )
      ) : (
        <Error401 />
      )}

      {/* Footer 2 */}
      <Footer />
    </div>
  );

  function RenderListFile({ file }) {
    const [loading, setLoading] = useState(false);

    async function handleDownload(url, fileName) {
      setLoading(true);
      // console.log(url.split("="));
      let name = fileName.replace(".", "");
      let type = url.split("=");
      context
        .downloadRequestWithoutHost(url)
        .then((response) => {
          //   console.log(response);
          fileDownload(response, `${name}.${type[1]}`);
          setLoading(false);
        })
        .catch((error) => console.log("ERROR", error));
    }
    return (
      <li>
        <div className="d-flex justify-content-between p-2">
          <p className="mb-1">{file.nama_file}</p>
          <button
            onClick={() => handleDownload(file.file_url, file.nama_file)}
            className="btn btn-danger btn-sm"
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border text-light" role="status" />
            ) : (
              <>Donwload</>
            )}
          </button>
        </div>
      </li>
    );
  }
}
