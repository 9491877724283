import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import ReviewForm from "./ReviewForm";
import { getRating } from '../../../util/Functions'
import moment from 'moment'
import 'moment/locale/id'

export default function Review({ sertifikat, data, reloadPage, getRatings, ratings, getDataKelas, reload, liveClass }) {
  return (
    <Row>
      <Col md="12">
        {sertifikat==''?null:<div className="review-form mb-5">
          <h5>Kirim Ulasan</h5>
          <ReviewForm liveClass={liveClass} getRatings={getRatings.bind(this)} ratings={ratings} getDataKelas={getDataKelas.bind(this)} />
        </div>}
        <div className="review-comments">
          <h5>Ulasan</h5>
          {data?.length == 0 ? <p className="text-center mt-3">belum ada ulasan</p> : null}
          {data?.map((v, idx) => {
            return (
              <div key={idx} className="comment-box d-flex">
                {/* <div className="comment-image">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/assets/images/testimonial-2.jpg`
                    }
                    alt=""
                  />
                </div> */}
                <div className="comment-content">
                  <div className="content-title d-flex justify-content-between">
                    <div className="comment-writer">
                      <h6>{v.user}</h6>
                      <p>{moment(v.created_at).calendar()}</p>
                      <ul className="list-unstyled list-inline">
                        {getRating(v.rate)}
                        <li className="list-inline-item">({v.rate})</li>
                      </ul>
                    </div>
                    {/* <div className="reply-btn">
                        <button type="button">
                        <i className="las la-reply-all"></i> Reply
                  </button>
                      </div> */}
                  </div>
                  <div className="comment-desc">
                    <p>
                      {v.comment}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
          {data?.length >= 5 ? <div className="reply-btn text-center">
            <button disabled={reload} onClick={() => reloadPage()} type="button">
              Lihat ulasan lainnya {reload ? <Spinner Spinner animation="border" variant="danger" size='sm' /> : null}
            </button>
          </div>:null}
        </div>
      </Col>
    </Row>
  );
}
