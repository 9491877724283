import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Instruktur({ data }) {
  const [avatarError, setAvatarError] = useState(false);

  return (
    <div className="container-instructor">
      <h5>Instruktur Kelas</h5>
      <div className="instructor-item">
        <Row>
          <Col md="4">
            <div className="instructor-img">
              <img
                src={
                  avatarError
                    ? process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`
                    : data.avatar
                }
                alt="avatar-mentor"
                className="img-fluid"
                onError={() => setAvatarError(true)}
              />
            </div>
          </Col>
          <Col md="8">
            <div className="instructor-content">
              <div className="instructor-box">
                <div className="top-content d-flex justify-content-between">
                  <div className="instructor-name">
                    <h6 className="heading">
                      <Link
                        to={`${process.env.PUBLIC_URL}/instructor-details/${data.id}/personal`}
                      >
                        {data.name}
                      </Link>
                    </h6>
                    <p>{data.title}</p>
                  </div>
                  <div className="instructor-social">
                    <ul className="social list-unstyled list-inline">
                      {data.facebook && (
                        <li className="list-inline-item">
                          <a className="facebook" href={data.facebook}>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {data.twitter && (
                        <li className="list-inline-item">
                          <a className="twitter" href={data.twitter}>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {data.linkedin && (
                        <li className="list-inline-item">
                          <a className="linkedin" href={data.linkedin}>
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      )}
                      {data.youtube && (
                        <li className="list-inline-item">
                          <a className="youtube" href={data.youtube}>
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="instructor-desk">
                  <p>{data.description}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
