import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ContextProvider } from "../../../context/BaseContext";
import { setStorage } from "../../../util/Functions";

export default function FormInfo({ showFormEdit, setShowFormEdit }) {
  const context = useContext(ContextProvider);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [refresh, setRefresh] = useState(false);

  async function getUser() {
    let dataProfile = JSON.parse(await localStorage.getItem("profile"));
    setValue("email", dataProfile.email, { shouldDirty: true });
    setValue("name", dataProfile.name, { shouldDirty: true });
    setValue("phone", dataProfile.phone, { shouldDirty: true });
    setValue("birthdate", dataProfile.birthdate, { shouldDirty: true });
    setValue("gender", dataProfile.gender, { shouldDirty: true });
  }

  const onSubmitProfile = async (data) => {
    // console.log(data);
    setRefresh(true);
    let form = new FormData();
    form.append("name", data.name);
    form.append("phone", data.phone);
    form.append("gender", data.gender);
    form.append("birthdate", data.birthdate);
    //console.log("test 1",saveAvatar)
    let response = await context.postRequest("updateUser", form, "form");
    // console.log("Data",response);
    if (response) {
      await setStorage("profile", JSON.stringify(response.data));
      context.setNotification("success", "Data berhasil di ubah");
      setShowFormEdit(false);
      setRefresh(false);
    } else {
      context.setNotification("danger", "Terjadi kesalahan, coba beberapa saat lagi");
      setRefresh(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return (
    <form id="user-info" onSubmit={handleSubmit(onSubmitProfile)}>
      <div className="form-group row">
        <label htmlFor="email" className="col-sm-2 col-form-label">
          Email
        </label>
        <div className="col-sm-10">
          <input
            {...register("email", {
              required: "Maaf, email tidak boleh kosong.",
            })}
            type="email"
            className={showFormEdit ? "form-control" : "form-control-plaintext"}
            id="email"
            disabled
          />
          <div style={{ color: "red" }}>
            {errors.email && errors.email.message}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-2 col-form-label">
          Nama Lengkap
        </label>
        <div className="col-sm-10">
          <input
            {...register("name", {
              required: "Maaf, nama lengkap tidak boleh kosong.",
            })}
            type="text"
            className={showFormEdit ? "form-control" : "form-control-plaintext"}
            readOnly={!showFormEdit}
            id="name"
          />
          <div style={{ color: "red" }}>
            {errors.name && errors.name.message}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="phone" className="col-sm-2 col-form-label">
          No Telepon
        </label>
        <div className="col-sm-10">
          <input
            {...register("phone", {
              required: "Maaf, no telepon tidak boleh kosong.",pattern: {
                value: /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/g,
                message: "Nomor telepon tidak valid"
            }
            })}
            type="text"
            className={showFormEdit ? "form-control" : "form-control-plaintext"}
            readOnly={!showFormEdit}
            id="phone"
          />
          <div style={{ color: "red" }}>
            {errors.phone && errors.phone.message}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="birthdate" className="col-sm-2 col-form-label">
          Tanggal Lahir
        </label>
        <div className="col-sm-10">
          <input
            {...register("birthdate", {
              required: "Maaf, tanggal lahir tidak boleh kosong.",
            })}
            type="date"
            className={showFormEdit ? "form-control" : "form-control-plaintext"}
            readOnly={!showFormEdit}
            id="birthdate"
          />
          <div style={{ color: "red" }}>
            {errors.birthdate && errors.birthdate.message}
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="gender" className="col-sm-2 col-form-label">
          Jenis Kelamin
        </label>
        <div className="col-sm-10">
          <div className="form-group">
            <select
              {...register("gender", {
                required: "Maaf, mohon di pilih jenis kelamin.",
              })}
              className={
                showFormEdit ? "form-control" : "form-control-plaintext"
              }
              disabled={!showFormEdit}
              id="gender"
            >
              <option value="" disabled={true}>
                == Pilih Jenis Kelamin ==
              </option>
              <option value="L">Laki-Laki</option>
              <option value="P">Perempuan</option>
            </select>
          </div>
          <div style={{ color: "red" }}>
            {errors.gender && errors.gender.message}
          </div>
        </div>
      </div>
      <div className="col-12 mt-4 text-right">
        {showFormEdit && (
          <button disabled={refresh} type="submit" className="btn btn-wakool">
            {refresh && <Spinner animation="border" variant="light" />} Simpan
          </button>
        )}
      </div>
    </form>
  );
}
