import React, { useContext, useEffect, useRef, useState } from "react";
import { Styles } from "./styles/buttonChat.js";
import { ContextProvider } from "../../context/BaseContext";
import { useParams } from "react-router";
import { messaging } from "../../config/firebase.config";
import ListChat from "./chat-components/ListChat.js";
import { initNotification } from "./chat-components/initNotification.js";
import FormChat from "./chat-components/FormChat.js";

function ButtonChat() {
  const context = useContext(ContextProvider);
  const { id } = useParams();

  const [refresh, setRefresh] = useState(false);
  const [showBoxChat, setshowBoxChat] = useState(false);
  const [userData, setUserData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [notifUnread, setnotifUnread] = useState(0);

  const chatRef = useRef();

  async function getChatData() {
    let response = await context.getRequest(`chats-detail-siswa/${id}`);
    // console.log(response.data.data);
    if (response) {
      setRefresh(false);
      setChatData(response.data.data.reverse());
    }
  }

  async function getUserData() {
    let userData = JSON.parse(await localStorage.getItem("user_profile"));
    if (userData) {
      setUserData(userData);
    }
  }

  async function countUnreadMessage() {
    let response = await context.getRequest("chat-notif-count");
    // console.log("COUNTTT", response.data.data);
    if (response) {
      setnotifUnread(response.data.data.unread);
    }
  }

  function handleReadMessageUnread() {
    if (chatData.length == 0) {
      setRefresh(true);
    }
    getChatData();
    setnotifUnread(0);
  }

  // messaging.onMessage((payload) => {
  //   // console.log("Message received. ", payload);
  //   if (showBoxChat) {
  //     getChatData();
  //   } else {
  //     countUnreadMessage();
  //   }
  // });

  useEffect(() => {
    // getChatData();
    initNotification(context);
    countUnreadMessage();
    getUserData();
  }, []);
  // return null;
  return (
    <Styles>
      {/* CHAT BOX */}
      {showBoxChat && (
        <div className="box-chat">
          <div className="card chat-card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="pt-1">
                <i className="far fa-comments mr-1" /> Chat Grup
              </h5>
              <div className="card-option">
                <button
                  onClick={() => setshowBoxChat(showBoxChat ? false : true)}
                  className="btn"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="card-body pt-2 pb-2">
              <div
                className="message"
                style={{
                  overflowY: "scroll",
                  height: "350px",
                  width: "403px",
                  overflowX: "hidden",
                }}
              >
                {!refresh ? (
                  <>
                    {chatData.length != 0 ? (
                      <>
                        {chatData.map((item, idx) => (
                          <ListChat
                            key={idx}
                            data={item}
                            userData={userData}
                            chatRef={chatRef}
                          />
                        ))}
                      </>
                    ) : (
                      <div className="col-12 text-center text-dark p-4">
                        <i
                          style={{ fontSize: "50px" }}
                          className="far fa-comments"
                        ></i>
                        <h6>Belum memiliki chat.</h6>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center m-5 text-secondary">
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Memuat Chat ...</p>
                  </div>
                )}
              </div>
              {/* FORM CHAT */}
              <FormChat
                id={id}
                userData={userData}
                context={context}
                setChatData={setChatData}
              />
            </div>
          </div>
        </div>
      )}
      {/* END CHAT BOX */}
      <button
        onClick={() => {
          setshowBoxChat(showBoxChat ? false : true);
          handleReadMessageUnread();
        }}
        className="toChat d-flex justify-content-between pl-3 pr-3"
      >
        <div>
          <i className="far fa-comments mr-1" />
          <span>Chat Grup</span>
        </div>
        {notifUnread !== 0 && (
          <div className="notif-unread">
            <span>{notifUnread}</span>
          </div>
        )}
      </button>
    </Styles>
  );
}

export default ButtonChat;
