import React, { Component } from 'react';
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Styles } from './styles/error'

export default function Error({refresh}) {
    return (
        <Styles>
            <Col md="12" className="text-center mt-2 mb-2">
                <img src={`${process.env.PUBLIC_URL}/assets/error_image.jpg`} height='400px' width='600px' />
                <p>Terjadi kesalahan</p>
                <button className='enroll-btn' onClick={()=>refresh()}>Reload page</button>
            </Col>
        </Styles>
    )
}