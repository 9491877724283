import React, { useEffect, useContext, useState } from "react";
import { Container, Row, Spinner, Col, Tab, Nav } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Pagination from "../../components/Pagination";
import { Styles } from "./styles/product.js";
import { ContextProvider } from "../../context/BaseContext";
import RenderClassItem from "./components/RenderClassItem.js";
import RenderLiveClassItem from "./components/RenderLiveClassItem";
import Error401 from "../../components/Error401";
import Error500 from "../../components/Error500";
import EmptyData from "../../components/EmptyData";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";

function ListMyClass() {
  const context = useContext(ContextProvider);
  const history = useHistory();

  const [listKelasUser, setListKelasUser] = useState([]);
  const [listLiveClass, setListLiveClass] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);

  async function getData() {
    setRefresh(true);
    try {
      let response = await context.getRequest("kelas_user");
      console.log("kelas user",response.data);
      if (response) {
        setRefresh(false);
        setListKelasUser(response.data.data);
        setError(false);
      }
    } catch (error) {
      setRefresh(false);
      setError(true);
    }
    setRefresh(false);
  }

  async function getLiveClass() {
    setRefresh(true);
    try {
      let response = await context.getRequest("class-live");
      // console.log("class live: ", response);
      if (response) {
        setRefresh(false);
        setListLiveClass(response.data.data);
        setError(false);
      }
    } catch (error) {
      setRefresh(false);
      setError(true);
    }
    setRefresh(false);
  }

  useEffect(() => {
    getData();
    getLiveClass();
  }, []);

  return (
    <Styles>
      <MetaTags>
          <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <div
        className="main-wrapper product-page"
        style={{ position: "relative" }}
      >
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox
          title="Kelasku"
          image={`${process.env.PUBLIC_URL}/assets/illustration12.png`}
        />
        {refresh ? (
          <Col md="12" className="text-center mt-2 mb-2">
            <Spinner animation="border" variant="danger" />
          </Col>
        ) : (
          <>
            {context.login ? (
              error ? (
                <Error500 refresh={getData.bind(this)} />
              ) : listKelasUser.length != 0 ? (
                <section
                  className="product-area"
                  style={{ paddingBottom: 150 }}
                >
                  <Tab.Container defaultActiveKey="default">
                    <Container>
                      <Nav className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="default">Kelas Mandiri</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="live-kelas">Live Kelas <span class="badge badge-info">Beta</span></Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="private-kelas">
                            Private Kelas
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </Container>
                    <Tab.Content>
                      <Tab.Pane eventKey="default" className="overview-tab">
                        <Container>
                          <Row>
                            {listKelasUser.length !== 0 &&
                              listKelasUser.map((data, i) => (
                                <RenderClassItem data={data} key={i} />
                              ))}
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane eventKey="live-kelas" className="overview-tab">
                        <Container>
                          <Row>
                            {listLiveClass.length !== 0 &&
                              listLiveClass.map((data, i) => (
                                <RenderLiveClassItem data={data} key={i} />
                              ))}
                          </Row>
                        </Container>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="private-kelas"
                        className="overview-tab"
                      >
                        <Container>
                          <h1>Private Kelas</h1>
                        </Container>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </section>
              ) : (
                <EmptyData />
              )
            ) : (
              <Error401 />
            )}
          </>
        )}

        {/* Footer 2 */}
        {/* <div
          style={{
            position: refresh ? "fixed" : "fixed",
            width: "100%",
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: 20,
            zIndex: 100,
          }}
        > */}
          <Footer />
        {/* </div> */}
      </div>
    </Styles>
  );
}

export default ListMyClass;
