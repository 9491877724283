import React, { useState, useContext, useEffect } from 'react'
import HeaderTwo from '../../components/HeaderTwo'
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import MetaTags from "react-meta-tags";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ContextProvider } from '../../context/BaseContext'
import {
    Container,
    Row,
    Col,
    Tab,
    Nav,
    Spinner,
    Modal,
    Alert,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import { Link, useLocation, useParams } from "react-router-dom";
import { Styles } from './styles/courseDetail';
import Error401 from '../../components/Error401';
import Error500 from '../../components/Error500';
import EmptyData from '../../components/EmptyData';
import { host } from '../../util/host';
import moment from 'moment'
import "moment/locale/id"
import { convertToSlug } from '../../util/Functions';

export default function Detail() {
    const context = useContext(ContextProvider)
    const { id } = useParams();
    const [data, setData] = useState(null)
    const [copied, setcopied] = useState(false);
    const [linkImage, setLinkImage] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const [availableCert, setAvailableCert] = useState(false)
    const [certificate, setCertificate] = useState(null)

    const message = `Hai, saya ingin membagikan TIKTalk webinar ${data?.title}`;
    async function checkAvailableCertificate() {
        let profile = localStorage.getItem('user_profile')
        if (profile) {
            var spl = id.split('-')
            var json = JSON.parse(profile)
            let response = await context.getRequest(`tiktalk-certificate-check?tiktalk_id=${spl[0]}&email=${json.email}`)
            if (response) {
                if (response.data.status) {
                    setAvailableCert(true)
                    setCertificate(response.data.data)
                }
                console.log("AVAILABLE CERT : ", response.data)
            }
        }
    }
    async function getDetail() {
        setRefresh(true)
        var spl = id.split('-')
        let response = await context.getRequest(`tiktalk/${spl[0]}/detail`)
        if (response) {
            // console.log("DETAIL : ",response.data)
            setLinkImage(response.data.link_image)
            setData(response.data.data)
        }
        setRefresh(false)
    }
    useEffect(() => {
        getDetail()
        checkAvailableCertificate()
    }, [])
    function formatTime(time) {
        let times = time.split(":");
        return times[0] + "." + times[1];
    }
    function returnLink(type) {
        const apilink = `https://share.wakool.academy/${data.id}/tiktalk/${convertToSlug(data?.title)}`;
        // let links = apilink + `${id}/webinar/${slug}?source=${webinar?.source}&media=${type}`;
        // console.log("LINKS : ",links)
        let links = apilink
        return links;
    }
    if (refresh) {
        return (
            <div className="main-wrapper course-details-page">
                <HeaderTwo />

                <BreadcrumbBox
                    title={"TIKTalk"}
                    image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}
                />
                <Col md="12" className="text-center mt-2 mb-2">
                    <Spinner animation="border" variant="danger" />
                </Col>
            </div>
        )
    }
    function ButtonCert() {
        if (availableCert) {
            return (
                <div className="course-desc">
                    <h5>Sertifikat</h5>
                    <a href={`/wakool-certificate/${certificate?.key}`} className="btn btn-danger">Link sertifikat</a>
                </div>
            )
        } else {
            return null
        }

    }
    return (
        <div className="main-wrapper course-details-page">
            <HeaderTwo />

            <BreadcrumbBox
                title={"TIKTalk"}
                image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}
            />
            {data ? (
                <Styles>
                    <section className="course-details-area" style={{ paddingBottom: 150 }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-8 col-lg-8">
                                    <div className="course-details-top">
                                        <div className="heading">
                                            <h4>
                                                {data.title}
                                                <p
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: 400,
                                                        color: "#969696",
                                                    }}
                                                >
                                                    by TIKTalk
                                                </p>
                                            </h4>
                                        </div>
                                        <div className="course-top-overview">
                                            <div className="d-flex overviews">
                                                <div className="author">
                                                    {/* <Link onClick={(e) => e.preventDefault()} to="">
                                                    <img
                                                        src={webinar.mentor_avatar}
                                                        alt="avatar-mentor"
                                                    />
                                                    <div className="author-name">
                                                        <h6>{webinar.mentor_name}</h6>
                                                        <p>{webinar.mentor_title}</p>
                                                    </div>
                                                </Link> */}
                                                </div>
                                                <div className="price">
                                                    {/* <h6>Harga</h6>
                                                <p>Rp {currencyFormat(webinar.price.toString())}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="course-details-banner text-center">
                                            <img
                                                src={linkImage}
                                                alt="gambar-kelas"
                                                style={{ width: '100rem', height: '30rem', objectFit: 'contain' }}
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="course-tab-list">
                                            <Tab.Container defaultActiveKey="overview">
                                                <Tab.Content>
                                                    <Tab.Pane
                                                        eventKey="overview"
                                                        className="overview-tab"
                                                    >
                                                        <ButtonCert />
                                                        <div className="course-desc">
                                                            <h5>Narasumber</h5>
                                                            {data.mentor.map((vv, ii) => {
                                                                return (
                                                                    <div style={{ marginBottom: 20 }}>
                                                                        <h6>{vv.mentor_name}</h6>
                                                                        <p>{vv.mentor_title}</p>
                                                                    </div>

                                                                )
                                                            })}

                                                        </div>
                                                        <div className="course-desc">
                                                            <h5>Deskripsi</h5>
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={data.description}
                                                                onReady={editor => {
                                                                    editor.isReadOnly = true
                                                                    
                                                                    const toolbarElement = editor.ui.view.toolbar.element;
                                                                    toolbarElement.style.display = 'none';
                                                                    // You can store the "editor" and use when it is needed.
                                                                    console.log('Editor is ready to use!', editor);
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    console.log({ event, editor, data });
                                                                }}
                                                                onBlur={(event, editor) => {
                                                                    console.log('Blur.', editor);
                                                                }}
                                                                onFocus={(event, editor) => {
                                                                    console.log('Focus.', editor);
                                                                }}
                                                            />
                                                            {/* <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            /> */}
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="single-details-sidbar">
                                        <div className="row">
                                            <Col className="mb-4" md="12">
                                                <div
                                                    id="share-social"
                                                    className="course-details-feature"
                                                >
                                                    <h5 className="title">Bagikan kelas ini</h5>
                                                    <ul className="share social list-unstyled list-inline text-center">
                                                        <li className="list-inline-item">
                                                            <FacebookShareButton
                                                                url={returnLink(
                                                                    "facebook"
                                                                )}
                                                                quote={message}
                                                                hashtags={["#hastag", "tag"]}
                                                            >
                                                                <a>
                                                                    <i className="fab fa-facebook-f"></i>
                                                                </a>
                                                            </FacebookShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <TwitterShareButton
                                                                url={returnLink(
                                                                    "twitter"
                                                                )}
                                                                title={message}
                                                            >
                                                                <a>
                                                                    <i className="fab fa-twitter"></i>
                                                                </a>
                                                            </TwitterShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <LinkedinShareButton
                                                                url={returnLink(
                                                                    "linkedin"
                                                                )}
                                                                summary={message}
                                                            >
                                                                <a>
                                                                    <i className="fab fa-linkedin-in"></i>
                                                                </a>
                                                            </LinkedinShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <WhatsappShareButton
                                                                url={returnLink(
                                                                    "whatsapp"
                                                                )}
                                                                title={message}
                                                            >
                                                                <a>
                                                                    <i className="fab fa-whatsapp"></i>
                                                                </a>
                                                            </WhatsappShareButton>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <TelegramShareButton
                                                                url={returnLink(
                                                                    "telegram"
                                                                )}
                                                                title={message}
                                                            >
                                                                <a>
                                                                    <i className="fab fa-telegram-plane"></i>
                                                                </a>
                                                            </TelegramShareButton>
                                                        </li>

                                                        {/* <li className="list-inline-item">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip id="tooltip-copy">
                                                                    {copied ? "Copied" : "Copy to clipboard"}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    navigator.clipboard.writeText(
                                                                        returnLink(
                                                                            "url"
                                                                        )
                                                                    );
                                                                    setcopied(true);
                                                                }}
                                                                onMouseLeave={() =>
                                                                    setTimeout(function () {
                                                                        setcopied(false);
                                                                    }, 1000)
                                                                }
                                                            >
                                                                <i className="fas fa-copy"></i>
                                                            </a>
                                                        </OverlayTrigger>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </Col>

                                            <div className="mb-4 col-md-12">
                                                <div className="course-details-feature">
                                                    <h5 className="title">Webinar</h5>
                                                    <div className="mt-2">
                                                        <h6>
                                                            <i
                                                                style={{ color: "#EE2025" }}
                                                                className="las la-calendar mr-1"
                                                            ></i>{" "}
                                                            Tanggal
                                                        </h6>
                                                        <p
                                                            style={{
                                                                marginLeft: "25px",
                                                                color: "#969696",
                                                                fontWeight: 400,
                                                            }}
                                                            className="mt-2"
                                                        >
                                                            {moment(data.date_start).format("DD MMM YYYY")}{" "}
                                                            - {moment(data.date_end).format("DD MMM YYYY")}
                                                        </p>
                                                    </div>
                                                    <div className="mt-2">
                                                        <h6>
                                                            <i
                                                                style={{ color: "#EE2025" }}
                                                                className="lar la-clock mr-1"
                                                            ></i>{" "}
                                                            Waktu
                                                        </h6>
                                                        <p
                                                            style={{
                                                                marginLeft: "25px",
                                                                color: "#969696",
                                                                fontWeight: 400,
                                                            }}
                                                            className="mt-2"
                                                        >
                                                            {formatTime(data.time_start)} -{" "}
                                                            {formatTime(data.time_end)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Styles>
            ) : <EmptyData />}

        </div>
    )
}
ClassicEditor
    .create(document.querySelector('#editor'), {
        // ...
    })
    .then(editor => {
        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        // editor.on( 'change:isReadOnly', ( evt, propertyName, isReadOnly ) => {
        //     if ( isReadOnly ) {
        //         toolbarElement.style.display = 'none';
        //     } else {
        //         toolbarElement.style.display = 'flex';
        //     }
        // } );
    })
    .catch(error => {
        console.log(error);
    });