import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Col,
  Container,
  Row,
  Table,
  Modal,
  Spinner,
} from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Pagination from "../../components/Pagination";
import { Styles } from "./styles/cart.js";
import { ContextProvider } from "../../context/BaseContext";
import { currencyFormat } from "../../util/Functions";
import { LoadingBall } from "../../components/LoadingBall";
import Error401 from "../../components/Error401";
import MetaTags from "react-meta-tags";
import Error500 from "../../components/Error500";
import EmptyData from "../../components/EmptyData";

function Transaction() {
  const context = useContext(ContextProvider);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [error, setError] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [getDataAgain, setGetDataAgain] = useState(true);

  async function getData(page) {
    setLoading(true);
    try {
      let res = await context.getRequest(`transaction-pending?page=${page}`);
      if (res.data.data.length != 0) {
        console.log("??", page, [...data, ...res.data.data]);
        setData([...data, ...res.data.data]);
      } else {
        setGetDataAgain(false);
      }
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  }

  function atEnd() {
    var c = [
      document.scrollingElement.scrollHeight,
      document.body.scrollHeight,
      document.body.offsetHeight,
    ].sort(function (a, b) {
      return b - a;
    }); // select longest candidate for scrollable length
    return window.innerHeight + window.scrollY + 2 >= c[0]; // compare with scroll position + some give
  }

  async function checkScrollPosition() {
    if (atEnd() && getDataAgain) {
      setLoading(true);
      setPage(page + 1);
    }
  }

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getData(page);
      window.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      isSubscribed = false;
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [page]);

  return (
    <Styles>
      <MetaTags>
          <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <div
        className="main-wrapper transaction"
        style={{ position: "relative" }}
      >
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox
          title="Riwayat Transaksi"
          image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
        />

        {loading && page == 1 ? (
          <div
            className="p-4 d-flex align-items-center"
            style={{ height: "50vh" }}
          >
            <LoadingBall />
          </div>
        ) : (
          <section className="cart-area" style={{ paddingBottom: 150 }}>
            {context.login ? (
              error ? (
                <Error500 refresh={getData.bind(this)} />
              ) : data.length != 0 ? (
                <Container>
                  <Row>
                    <Col lg="12" md="12">
                      <div className="product-list table-responsive">
                        <Table className="table-bordered">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th>No Transaksi</th>
                              <th>Nama Kelas</th>
                              <th>Harga</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((v, idx) => {
                              let variant = "secondary";
                              let ty = v.order_id.split("-");
                              switch (v.transaction_status) {
                                case "expire":
                                  variant = "danger";
                                  break;
                                case "pending":
                                  variant = "warning";
                                  break;
                                case "settlement":
                                  variant = "success";
                                  break;

                                default:
                                  break;
                              }

                              if (ty[0] == "ACD") {
                                return (
                                  <tr key={idx}>
                                    <td className="product-remove">
                                      <a
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setModalDetail(true);
                                          setDetail(v);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </a>
                                    </td>
                                    <td className="product-thumbnail">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/detail-class/" +
                                          v.kelas.id
                                        }
                                      >
                                        <img
                                          src={v.kelas.img}
                                          onError={() => setImageError(true)}
                                          alt=""
                                          style={{
                                            height: 70,
                                            width: 70,
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                    </td>
                                    <td className="product-title">
                                      <a
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setModalDetail(true);
                                          setDetail(v);
                                        }}
                                      >
                                        {v.order_id}
                                      </a>
                                    </td>
                                    <td className="product-price">
                                      <span className="amount">
                                        {v.kelas.title}
                                      </span>
                                    </td>
                                    <td className="product-price">
                                      <span className="subtotal">
                                        Rp. {currencyFormat(v.gross_amount)}
                                      </span>
                                    </td>
                                    <td className="product-price">
                                      <Badge variant={`${variant} text-white`}>
                                        {v.status}
                                      </Badge>
                                    </td>
                                  </tr>
                                );
                              } else if (ty[0] == "LE") {
                                return (
                                  <tr key={idx}>
                                    <td className="product-remove">
                                      <a
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setModalDetail(true);
                                          setDetail(v);
                                        }}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </a>
                                    </td>
                                    <td className="product-thumbnail">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/detail-class/" +
                                          v.live_class.class_id
                                        }
                                      >
                                        <img
                                          src={v.live_class.class.img}
                                          onError={() => setImageError(true)}
                                          alt=""
                                          style={{
                                            height: 70,
                                            width: 70,
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                    </td>
                                    <td className="product-title">
                                      <a
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setModalDetail(true);
                                          setDetail(v);
                                        }}
                                      >
                                        {v.order_id}
                                      </a>
                                    </td>
                                    <td className="product-price">
                                      <span className="amount">
                                        {v.live_class.title}
                                      </span>
                                    </td>
                                    <td className="product-price">
                                      <span className="subtotal">
                                        Rp. {currencyFormat(v.gross_amount)}
                                      </span>
                                    </td>
                                    <td className="product-price">
                                      <Badge variant={`${variant} text-white`}>
                                        {v.status}
                                      </Badge>
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                  {loading && page > 1 ? (
                    <Col md="12" className="text-center mt-2 mb-2">
                      <Spinner
                        style={{
                          width: "35px",
                          height: "35px",
                          fontSize: "20px",
                        }}
                        animation="border"
                        variant="danger"
                      />
                    </Col>
                  ) : null}
                </Container>
              ) : (
                <EmptyData />
              )
            ) : (
              <Error401 />
            )}
          </section>
        )}

        {/* Footer */}
        {/* <div style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}> */}
        <Footer />
        {/* </div> */}
      </div>
      <Modal show={modalDetail} onHide={() => setModalDetail(false)} centered>
        <div style={{ padding: 20 }}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <h4>Detail Pembayaran</h4>
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                setModalDetail(false);
              }}
            >
              <i className="fas fa-times" style={{ fontSize: 20 }}></i>
            </a>
          </div>
          <hr />
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <p>Status Pembayaran</p>
            <h6>{detail.status}</h6>
          </div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              marginTop: 10,
            }}
          >
            <p>Total Pembayaran</p>
            <h6>
              {detail.currency} {currencyFormat(detail.gross_amount)}
            </h6>
          </div>
          <RenderDetailTransaction />
        </div>
      </Modal>
    </Styles>
  );

  function RenderDetailTransaction() {
    if (detail.transaction_detail != undefined) {
      let dt = JSON.parse(detail.transaction_detail);
      // console.log("detail", dt);
      if (dt.transaction_status == "pending") {
        if (dt.hasOwnProperty("va_numbers")) {
          let va = dt.va_numbers[0];
          return (
            <div>
              <p style={{ marginTop: 10 }}>Bank</p>
              <h6>{va.bank}</h6>
              <div
                style={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <p>Nomor Virtual Account</p>
                <h6
                  style={{
                    fontSize: 22,
                    textAlign: "center",
                    marginTop: 20,
                  }}
                >
                  {va.va_number}
                </h6>
              </div>
            </div>
          );
        } else if (dt.hasOwnProperty("store")) {
          let va = dt;
          return (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <p>Toko</p>
                <h6>{va.store}</h6>
              </div>
              <div className="text-center" style={{ marginTop: 20 }}>
                <p>Kode Pembayaran</p>
                <h6
                  style={{
                    fontSize: 22,
                    marginTop: 10,
                  }}
                >
                  {va.payment_code}
                </h6>
              </div>
            </>
          );
        } else if (dt.hasOwnProperty("bill_key")) {
          let va = dt;
          return (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <p>Tipe Pembayaran</p>
                <h6>Mandiri {va.payment_type}</h6>
              </div>
              <div className="text-center" style={{ marginTop: 20 }}>
                <p>Kode pembayaran</p>
                <h6 style={{ fontSize: 22, marginTop: 10 }}>{va.bill_key}</h6>
              </div>
            </>
          );
        } else if (dt.hasOwnProperty("permata_va_number")) {
          let va = dt;
          return (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <p>Tipe Pembayaran</p>
                <h6>Permata</h6>
              </div>
              <div className="text-center" style={{ marginTop: 20 }}>
                <p>Nomor Virtual Account</p>
                <h6 style={{ fontSize: 22, marginTop: 10 }}>
                  {va.permata_va_number}
                </h6>
              </div>
            </>
          );
        } else if (dt.hasOwnProperty("merchant_id")) {
          let va = dt;
          return (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <p>Tipe Pembayaran</p>
                <h6>{va.payment_type}</h6>
              </div>
              <p className="text-center mt-3">
                kode pembayaran tidak ditemukan
              </p>
            </>
          );
        } else {
          return (
            <p className="text-center mt-3">kode pembayaran tidak ditemukan</p>
          );
        }
      } else if (dt.transaction_status == "capture") {
        if (dt.hasOwnProperty("card_type")) {
          let va = dt;
          return (
            <div>
              <div style={{ marginTop: 10 }}>
                <p>Tipe Kartu</p>
                <h6 style={{ fontFamily: "Roboto-Bold" }}>{va.card_type}</h6>
              </div>
              <div style={{ marginTop: 10 }}>
                <p>Masked Card</p>
                <h6 style={{ fontFamily: "Roboto-Bold" }}>{va.masked_card}</h6>
              </div>
              <div style={{ marginTop: 10 }}>
                <p>Bank</p>
                <h6 style={{ fontFamily: "Roboto-Bold" }}>{va.bank}</h6>
              </div>
              <div style={{ marginTop: 10 }}>
                <p>Response</p>
                <h6 style={{ fontFamily: "Roboto-Bold" }}>
                  {va.channel_response_message}
                </h6>
              </div>
            </div>
          );
        }
      } else {
        return <p className="text-center mt-3">kode pembayaran kadaluarsa</p>;
      }
    } else {
      return <p className="text-center mt-3">data tidak ditemukan</p>;
    }
  }
}

export default Transaction;
