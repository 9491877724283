import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from "../../components/HeaderTwo";
import AboutPrakerja from "../../components/AboutPrakerja";
import TestimonialSlider from "../../components/TestimonialSlider";
import FooterInfo from "../../components/FooterInfo";
import Footer from "../../components/Footer";

class HomeBlog extends Component {
    render() {
        return (
            <div className="main-wrapper">
                <HeaderTwo />

                {/* About Area */}
                <AboutPrakerja full={true} />
                <TestimonialSlider />
                <FooterInfo />
                <Footer />
            </div>
        )
    }
}

export default HomeBlog
