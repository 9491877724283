import React, { useEffect, useContext, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";
import { ContextProvider } from "../../context/BaseContext";
import { getStorage } from "../../util/Functions";

function StickyMenu({ categorylist, username }) {
  // const context= useContext(ContextProvider)
  const [showSticky, setShowSticky] = useState(false);
  // const [username,setUsername]=useState('')
  // const categorylist = [1, 2, 3, 4, 5, 6]
  // const [categorylist,setCategorylist]=useState([])
  // async function getKategory(){
  //     let ca=await localStorage.getItem('kategori')
  //     if(ca){
  //       let json=JSON.parse(ca)
  //       setCategorylist(json)
  //     }
  //     let response=await context.getRequest('getAllCategory')
  //     if(response){
  //       setCategorylist(response.data.data)
  //       console.log("KATEGORI : ",response.data.data)
  //       await localStorage.setItem('kategori',JSON.stringify(response.data.data))
  //     }
  //   }
  // useEffect(() => {
  //     getKategory()
  // },[]);
  // useEffect(() => {
  //     getStorageUser();
  // }, [])

  // async function getStorageUser(){
  //     let user = await getStorage('username')
  //     setUsername(user)
  // }
  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 160) {
        setShowSticky(true);
      } else {
        setShowSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className={showSticky ? "sticky-menu sticky" : "sticky-menu"}>
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    style={{ width: "170px", height: "45px" }}
                    src={
                      process.env.PUBLIC_URL + "/assets/images/wakoollogo1.png"
                    }
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/"}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/classes/0"}
                      data-toggle="dropdown"
                    >
                      Kategori <i className="las la-angle-down"></i>
                    </Link>
                    <ul
                      style={{ display: "block" }}
                      className="dropdown list-unstyled"
                    >
                      {categorylist.map((idx) => {
                        return (
                          <li key={"sticky" + idx.id} className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/classes/" +
                                idx.id +
                                "-" +
                                idx.slug
                              }
                            >{`${idx.title}`}</Link>
                          </li>
                          // <li key={idx} className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/classes/0"}>{`Kategori ${idx}`}</Link></li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/prakerja"}
                    >
                      Prakerja
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/tiktalk"}
                    >
                      TIKTALK
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/webinars"}
                    >
                      Webinar
                    </Link>
                  </li>
                  {username ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/certificate"}
                        >
                          Sertifikat
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/wishlist"}
                        >
                          Favorit
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/my-class"}
                        >
                          Kelasku
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/transaction"}
                        >
                          Riwayat Transaksi
                        </Link>
                      </li> */}
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle"
                          to={"#"}
                          data-toggle="dropdown"
                        >
                          Riwayat <i className="las la-angle-down"></i>
                        </Link>
                        <ul
                          style={{ display: "block" }}
                          className="dropdown list-unstyled"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/transaction"}
                            >
                              Riwayat Transaksi
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/list-private-user"}
                            >
                              Riwayat Private Kelas
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : null}
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/faq"}
                    >
                      FAQ
                    </Link>
                  </li>
                </ul>
                
                {!username ? (
                  <div className="apply-btn">
                    <Link
                      to={process.env.PUBLIC_URL + "/login"}
                      style={{ width: "150px" }}
                    >
                      <i className="las la-clipboard-list"></i>daftar Sekarang
                    </Link>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
