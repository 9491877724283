import React from 'react'
import ReactGA from 'react-ga'
// ReactGA.initialize('UA-198977621-1',{
//     debug: true,
//     titleCase: false,
//     gaOptions: {
//         name:'Track1' 
//     }
//   });
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.initialize('UA-198977621-1')

export const RenderGA=(Component)=>{
    return <Component/>
}
export default ReactGA