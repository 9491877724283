import React from "react";
import BaseContext from "./context/BaseContext";
import Routes from "./Routes";
import SnackbarProvider from "react-simple-snackbar";
import { BrowserRouter } from "react-router-dom";
export default function App() {
  return (
    <BrowserRouter>
      <BaseContext>
        <SnackbarProvider>
          <Routes />
        </SnackbarProvider>
      </BaseContext>
    </BrowserRouter>
  );
}
