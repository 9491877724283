import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Search from "./common/Search";
import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerTwo.js";
import { getStorage } from "../util/Functions";
import { ContextProvider } from "../context/BaseContext";
import firebase from "../config/firebase.config";
import moment from "moment";
import Swal from "sweetalert2";
function HeaderTwo() {
  const context = useContext(ContextProvider);
  const [username, setUsername] = useState("");
  const [avatarUser, setAvatarUser] = useState("");
  const [avatarError, setAvatarError] = useState(false);
  const [textTime, setTextTime] = useState("00:00:00");
  const [showTextTime, setShowTextTime] = useState(false);
  const [ddiskon, setDdiskon] = useState({ percentage: 0 });

  // const categorylist = [1, 2, 3, 4, 5, 6];
  const [categorylist, setCategorylist] = useState([]);

  async function getKategory() {
    let ca = await localStorage.getItem("kategori");
    if (ca) {
      let json = JSON.parse(ca);
      setCategorylist(json);
    }
    let response = await context.getRequest("getAllCategory");
    if (response) {
      setCategorylist(response.data.data);
      // console.log("KATEGORI : ", response.data.data);
      await localStorage.setItem(
        "kategori",
        JSON.stringify(response.data.data)
      );
    }
  }

  async function getUserProfile() {
    let userProfile = localStorage.getItem("profile");
    if (userProfile) {
      userProfile = JSON.parse(userProfile);
      setAvatarUser(userProfile.avatar);
    }
  }

  useEffect(() => {
    getUserProfile();
    getStorageUser();
    getKategory();
    countDown();
  }, []);

  async function getStorageUser() {
    let user = await getStorage("username");
    setUsername(user);
  }

  async function logOut() {
    await localStorage.clear();
    context.setValue("user", {});
    context.setValue("login", false);
    setUsername(null);
    firebase.auth().signOut();
  }
  function countDown() {
    var diskon = localStorage.getItem("diskon_new_user");
    if (!diskon) {
      diskon = {
        percentage: 0,
        days: 0,
      };
    } else {
      diskon = JSON.parse(diskon);
      console.log("DISKON CONFIG", diskon);
      setDdiskon(diskon);
    }

    var user = localStorage.getItem("profile");
    if (user) {
      user = JSON.parse(user);
      var ca = user.created_at.split(" ");
      var nowDate = moment().format("YYYY-MM-DD");
      var date1 = moment(ca[0]);
      var date2 = moment(nowDate);
      var diff = date2.diff(date1, "days");
      // console.log("DIFF : ",diff,diskon)
      if (diff < diskon.days) {
        var event = moment()
          .add((diskon.days - diff)-1, "days")
          .format("YYYY-MM-DD");
        var now = moment().format("YYYY-MM-DD h:mm:ss");
        //console.log("DIFF ", event, now);
        // console.log("DIFF : ",price,price-((price*diskon.percentage)/100))
        var eventTime = new Date(event + " 23:59:59").getTime() / 1000; // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
        var currentTime = new Date(now).getTime() / 1000; // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT

        var diffTime = eventTime - currentTime;
        var duration = moment.duration(diffTime * 1000, "milliseconds");
        var interval = 1000;
        // console.log("DIFF : ", diffTime, eventTime, currentTime);
        setInterval(function () {
          duration = moment.duration(duration - interval, "milliseconds");
          setTextTime(
            duration.days() +
              ":" +
              duration.hours() +
              ":" +
              duration.minutes() +
              ":" +
              duration.seconds()
          );
          // $('.countdown').text(duration.hours() + ":" + duration.minutes() + ":" + duration.seconds())
        }, interval);
        setShowTextTime(true);
      } else {
        // console.log("DIFF : ",price)
        setShowTextTime(false);
      }
    } else {
      var days = parseInt(diskon.days);
      if (days > 0) {
        var event = moment()
          .add(days - 1, "days")
          .format("YYYY-MM-DD");
        var now = moment().format("YYYY-MM-DD h:mm:ss");

        var eventTime = new Date(event + " 23:59:59").getTime() / 1000; // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
        var currentTime = new Date(now).getTime() / 1000; // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT

        var diffTime = eventTime - currentTime;
        var duration = moment.duration(diffTime * 1000, "milliseconds");
        var interval = 1000;
        // console.log("DIFF : ", diffTime, eventTime, currentTime);
        setInterval(function () {
          duration = moment.duration(duration - interval, "milliseconds");
          setTextTime(
            duration.days() +
              ":" +
              duration.hours() +
              ":" +
              duration.minutes() +
              ":" +
              duration.seconds()
          );
          // $('.countdown').text(duration.hours() + ":" + duration.minutes() + ":" + duration.seconds())
        }, interval);
        setShowTextTime(true);
      } else {
        setShowTextTime(false);
      }
    }
  }
  return (
    <Styles>
      {/* Topbar 2 */}
      {context.supportFirebase && showTextTime ? (
        <div
          className="alert alert-warning mb-0"
          role="alert"
          style={{ textAlign: "center" }}
        >
          Bagi kamu pengguna baru, kami punya diskon khusus loh untuk kamu,{" "}
          <b>
            dapatkan diskon khusus {ddiskon.percentage}% untuk pembelian pertama semua kelas kami
          </b>
          .<br />
          <div style={{ fontWeight: "bold", fontSize: 20 }}>{textTime}</div>
        </div>
      ) : null}
      {!context.supportFirebase ? (
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Browser ini belum support!</strong> Beberapa fitur tidak bisa
          berjalan dibrowser ini
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
      <section className="top-bar2">
        <Container>
          <Row>
            <Col lg="7" md="9">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="las la-phone"></i>(022) 7537609
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-envelope"></i>info@wakool.academy
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-map-marker"></i>Bandung, Indonesia
                  </li>
                  <li className="list-inline-item">
                    <a href="/check-certificate" style={{color:'#666666'}}><i className="las la-check"></i>Cek sertifikat</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="5" md="3">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-social">
                  <li className="list-inline-item">
                    <a href={"https://www.facebook.com/wakool.academy"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={"https://twitter.com/wakool_academy"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href={
                        "https://www.youtube.com/channel/UCB_5WWvk0x8qA6zeo2gJ6JQ"
                      }
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={"https://www.instagram.com/wakool.academy/"}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>

                <ul className="list-unstyled list-inline bar-login">
                  {!username ? (
                    <>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/login"}>
                          <i className="las la-user"></i>Masuk
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/registration"}>
                          <i className="las la-user-edit"></i>Daftar
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="list-inline-item">
                        <Link to={process.env.PUBLIC_URL + "/profile"}>
                          <i className="las la-user-edit"></i>Edit Profile
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            
                            Swal.fire({
                              title: "Anda yakin akan meninggalkan wakool academy?",
                              text: "",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: 'Ya, saya ingin keluar!',
                              cancelButtonText: 'Tidak'
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                logOut();
                              }
                            });
                          }}
                        >
                          <i className="las la-user"></i>Keluar
                        </a>
                      </li>
                    </>
                  )}
                </ul>
                <ul className="list-unstyled list-inline sidebar-button">
                  <li className="list-inline-item nav-item side-box">
                    <Sidebar />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Logo Area 2 */}
      <section className="logo-area2">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    style={{ width: "170px", height: "45px" }}
                    src={
                      process.env.PUBLIC_URL + "/assets/images/wakoollogo1.png"
                    }
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/"}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={"#"}
                      data-toggle="dropdown"
                    >
                      Kategori <i className="las la-angle-down"></i>
                    </Link>
                    <ul
                      style={{ display: "block" }}
                      className="dropdown list-unstyled"
                    >
                      {categorylist.map((idx) => {
                        return (
                          <li key={"menus" + idx.id} className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                process.env.PUBLIC_URL +
                                "/classes/" +
                                idx.id +
                                "-" +
                                idx.slug
                              }
                            >{`${idx.title}`}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/prakerja"}
                    >
                      PRAKERJA
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/tiktalk"}
                    >
                      TikTALK
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/webinars"}
                    >
                      Webinar
                    </Link>
                  </li>
                  {username ? (
                    <>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/certificate"}
                        >
                          Sertifikat
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/wishlist"}
                        >
                          Favorit
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/my-class"}
                        >
                          Kelasku
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        {/* <Link
                          className="nav-link dropdown-toggle"
                          to={"#"}
                          data-toggle="dropdown"
                          //
                        >
                          Riwayat <i className="las la-angle-down"></i>
                        </Link> */}
                        <ul
                          style={{ display: "block" }}
                          className="dropdown list-unstyled"
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/transaction"}
                            >
                              Riwayat Transaksi
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={process.env.PUBLIC_URL + "/list-private-user"}
                            >
                              Riwayat Private Kelas
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : null}
                  {/* <li className="nav-item active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/faq"}
                    >
                      FAQ
                    </Link>
                  </li> */}
                </ul>
                <div className="search-box">
                  <Search />
                </div>

                {!username ? (
                  <div className="apply-btn">
                    <Link
                      to={process.env.PUBLIC_URL + "/registration"}
                      style={{ width: 150 }}
                    >
                      <i className="las la-clipboard-list"></i>Daftar Sekarang
                    </Link>
                  </div>
                ) : (
                  <div
                    className="image-profile mr-2 ml-3"
                    //ref={ref}
                    onClick={(e) => {
                      e.preventDefault();
                      // onClick(e);
                    }}
                    style={{ textAlign: "center" }}
                  >
                    <img
                      onError={() => {
                        setAvatarError(true);
                      }}
                      src={
                        !avatarError
                          ? avatarUser
                          : process.env.PUBLIC_URL + `/assets/empty_user.png`
                      }
                      alt="avatar-profile"
                      style={{
                        width: "85%",
                        height: "85%",
                        textAlign: "center",
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      <StickyMenu categorylist={categorylist} username={username} />

      {/* Mobile Menu */}
      <MobileMenu
        username={username}
        logOut={logOut}
        categorylist={categorylist}
      />
    </Styles>
  );
}

export default HeaderTwo;
