import React, { Component, useContext, useEffect, useState } from "react";
import HeaderTwo from "./components/HeaderTwo";
import HeroSlider from "./components/HeroSlider";
import IconBox from "./components/IconBox";
import AboutUs from "./components/AboutUs";
import CourseFilter from "./components/CourseFilter";
import TestimonialSlider from "./components/TestimonialSlider";
import FaqEvent from "./components/FaqEvent";
import TeamSlider from "./components/TeamSlider";
import HelpArea from "./components/HelpArea";
import HomeBlog from "./components/HomeBlog";
import CampusTour from "./components/CampusTour";
import NewsletterForm from "./components/NewsletterForm";
import Footer from "./components/Footer";
import { ContextProvider } from "./context/BaseContext";
import FooterInfo from "./components/FooterInfo";
import { encryptString, getStorage, setStorage } from "./util/Functions";
import MetaTags from "react-meta-tags";
import IntructorArea from './IntructorArea'
import Highlight from './Highlight'
import Webinar from './Webinar'
import Prakerja from "./Prakerja";
import { Col, Container, Row } from "react-bootstrap";
import { host, shareHost } from "./util/host";

export default function HomeOne() {
  const context = useContext(ContextProvider);

  const [dataListKelas, setDataListKelas] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [voucher, setVoucher] = useState('')
  const [message, setMessage] = useState('')
  const [kelas, setKelas] = useState(null)
  const [status, setStatus] = useState(false)

  async function getDataHomes() {
    setRefresh(true)
    // let response=await context.getRequest('check-referer');
    // console.log("REFERER : ",response)
    context
      .getRequest("/homes")
      .then((response) => {
        setRefresh(false)
        setDataListKelas(response.data);
      })
      .catch((error) => {
        console.log("ERORR", error);
      });
  }

  async function generateVoucher() {

    if (context.login) {
      if (voucher) {
        setBtnLoading(true)
        let form = new FormData()
        form.append('vouchers', voucher)
        let res = await context.postRequest('generate-voucher-homes', form)
        console.log(res)
        setKelas(res.data)
        setStatus(res.status)
        setMessage(res.message)
        setBtnLoading(false)
        setVoucher('')
      }
    } else {
      context.setNotification(
        "danger",
        "Anda belum login, silahkan login terlebih dahulu"
      );
    }
  }

  useEffect(() => {
    getDataHomes();
  }, []);
  return (
    <div className="main-wrapper">
      <MetaTags>
        <title>Dashboard | Wakool Academy</title>
        <link
          rel="canonical"
          href={"https://wakool.academy"}
        />
        <meta name="title" content={'Dashboard'} />
        <meta itemprop="name" content={'Dashboard'} />
      </MetaTags>
      {/* Header */}
      <HeaderTwo />

      {/* Hero Slider */}
      <HeroSlider />

      {/* Icon Box */}
      <IconBox />

      <Container>
        <div className="banner-info">
          <div className="d-flex flex-direction-row justify-content-between align-items-center">
            <input
              readOnly={btnLoading}
              value={voucher}
              placeholder="Masukan kode vouchermu di sini"
              autoCapitalize="characters"
              onChange={(e) => { setVoucher(e.nativeEvent.target.value) }}
            />
            <button disabled={btnLoading} className="btn btn-danger btn-lg" onClick={generateVoucher}>
              Cek Voucher
            </button>
          </div>
          {message && <div className="alert alert-dark mt-3" role="alert">
            {message}
            {kelas && <a href={shareHost + kelas?.id + "/" + kelas?.slug} className="alert-link">{kelas?.title}</a>}
            {status && <a href={"/my-class"} className="alert-link">di sini</a>}
          </div>}
        </div>
      </Container>

      {/* About Area */}
      <AboutUs full={true} />
      <Highlight />
      <Prakerja />
      <Webinar />
      {/* Course Filter */}
      <CourseFilter data={dataListKelas.data} refresh={refresh} />

      {/* Testimonial Slider */}

      <IntructorArea />
      {/* Faq & Event Area */}
      {/* <FaqEvent /> */}

      {/* Team Slider */}
      {/* <TeamSlider /> */}

      {/* Help Area */}
      <HelpArea />
      <TestimonialSlider />
      {/* Blog Area */}
      {/* < HomeBlog /> */}

      {/* Campus Tour */}
      {/* < CampusTour /> */}

      {/* Newsletter Form */}
      {/* < NewsletterForm /> */}

      {/* Footer Information */}
      <FooterInfo />

      {/* Footer */}
      <Footer />
    </div>
  );
}
