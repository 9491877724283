import React, { useState } from "react";
import moment from "moment";
import 'moment/locale/id'

const ListChat = ({ data, userData, chatRef }) => {
  const [errorAvatar, setErrorAvatar] = useState(false);

  if (userData.id == data.user_id) {
    return (
      <div
        ref={chatRef}
        onLoad={() => chatRef.current.scrollIntoView({ block: "start" })}
        className="row send-chat mb-3"
      >
        <div className="col">
          <div className="msg mb-1 text-left">
            <p
              style={{
                fontSize: "11px",
                color: "whitesmoke",
                fontStyle: "italic",
                fontWeight: "500",
              }}
              className="text-light mb-2"
            >
              {data.users.name}
            </p>
            {/* Message */}
            {data.content_type == "text" ? (
              <p>{data.content}</p>
            ) : (
              <img src={data.img} alt="content-imaga" />
            )}
          </div>
          <p style={{ fontSize: "11px" }} className="text-muted">
            {moment(data.created_at, "YYYY-MM-DD h:mm:ss")
              .startOf("second")
              .fromNow()}
          </p>
        </div>
        <div className="col-auto">
          <img
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
            src={
              errorAvatar
                ? process.env.PUBLIC_URL + `/assets/empty_user.png`
                : data.users.avatar
            }
            alt="user-avatar"
            className="img-radius wid-40"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        ref={chatRef}
        onLoad={() => chatRef.current.scrollIntoView({ block: "start" })}
        className={
          data.type == "mentor"
            ? "row received-chat-mentor mb-3"
            : "row received-chat mb-3"
        }
      >
        <div className="col-auto">
          <img
            style={{ width: "40px", height: "40px", objectFit: "cover" }}
            src={
              errorAvatar
                ? process.env.PUBLIC_URL + `/assets/empty_user.png`
                : data.users.avatar
            }
            alt="user-avatar"
            className="img-radius"
            onError={() => setErrorAvatar(true)}
          />
        </div>
        <div className="col">
          <div className="msg mb-1">
            <p
              style={{
                fontSize: "11px",
                color: "whitesmoke",
                fontStyle: "italic",
                fontWeight: "500",
              }}
              className="text-light mb-2"
            >
              {data.users.name} {data.type == "mentor" && " - Mentor"}
            </p>
            {/* Message */}
            {data.content_type == "text" ? (
              <p>{data.content}</p>
            ) : (
              <img src={data.img} alt="content-imaga" />
            )}
          </div>
          <p style={{ fontSize: "11px" }} className="text-muted">
            {moment(data.created_at, "YYYY-MM-DD h:mm:ss")
              .startOf("second")
              .fromNow()}
          </p>
        </div>
      </div>
    );
  }
};

export default ListChat;
