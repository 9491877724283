import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { LoadingBall } from "../../components/LoadingBall";
import { Styles } from "./styles/course.js";
import Footer from "../../components/Footer";
import Error401 from "../../components/Error401";
import { ContextProvider } from "../../context/BaseContext";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export default function NewPrivate() {
  const context = useContext(ContextProvider);
  const history = useHistory();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    console.log(data);
    if (data.time_end >= data.time_start) {
      let form = {
        qty_user: parseInt(data.jml_peserta),
        date_start: data.date_start,
        date_end: data.date_end,
        time_start: data.time_start,
        time_end: data.time_end,
        type: data.metode,
        no_wa: data.whatsApp,
        alamat_lengkap: data.alamat,
        message: data.info,
        class_id: id,
      };

      setLoading(true);
      try {
        var response = await context.postRequest("request-private", form);
        // console.log("RESPONSEEE : ",response);
        if (response) {
          context.setNotification(
            "success",
            "Pengajuan berhasil, silahkan lihat di menu Riwayat pengajuan kelas"
          );
          reset();
          history.push({
            pathname: `${process.env.PUBLIC_URL}/list-private-user`
          });
        }
      } catch (error) {
        context.setNotification(
          "danger",
          "Ooops pengajuan gagal, cobalah beberapa saat lagi"
        );
      }
      setLoading(false);
    } else {
      context.setNotification(
        "danger",
        "Maaf waktu akhir harus lebih besar dari waktu mulai"
      );
    }
  };

  return (
    <div className="main-wrapper course-details-page">
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Pengajuan Kelas Privat"
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {context.login ? (
        <Styles>
          {/* Course Details */}
          <section
            className="course-details-area"
            style={{ paddingBottom: 60 }}
          >
            <div className="container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    Jumlah Peserta
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("jml_peserta", {
                        min: {
                          value: 1,
                          message: "Jumlah peserta harus lebih dari 0",
                        },
                        required: "Jumlah peserta tidak boleh kosong",
                      })}
                      type="number"
                      className="form-control form-control-lg"
                      placeholder="Masukan jumlah peserta yang mengikuti"
                    />
                    {errors.jml_peserta && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.jml_peserta.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    Tanggal Request
                  </label>
                  <div className="col-sm-5">
                    <label>Tanggal Mulai</label>
                    <input
                      {...register("date_start", {
                        required: "Tanggal mulai tidak boleh kosong",
                      })}
                      min={moment().format("YYYY-MM-DD")}
                      type="date"
                      className="form-control form-control-lg"
                    />
                    {errors.date_start && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.date_start.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-5">
                    <label>Tanggal Akhir</label>
                    <input
                      {...register("date_end", {
                        required: "Tanggal mulai tidak boleh kosong",
                      })}
                      min={watch("date_start")}
                      type="date"
                      className="form-control form-control-lg"
                    />
                    {errors.date_end && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.date_end.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    Waktu
                  </label>
                  <div className="col-sm-5">
                    <label>Waktu Mulai</label>
                    <input
                      {...register("time_start", {
                        required: "Waktu mulai tidak boleh kosong",
                      })}
                      type="time"
                      className="form-control form-control-lg"
                    />
                    {errors.time_start && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.time_start.message}
                      </span>
                    )}
                  </div>
                  <div className="col-sm-5">
                    <label>Waktu Akhir</label>
                    <input
                      {...register("time_end", {
                        required: "Waktu akhir tidak boleh kosong",
                      })}
                      type="time"
                      className="form-control form-control-lg"
                    />
                    {errors.time_end && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.time_end.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    Metode
                  </label>
                  <div className="col-sm-10">
                    <select
                      {...register("metode", {
                        required: "Metode belajar tidak boleh kosong",
                      })}
                      className="custom-select custom-select-lg my-1 mr-sm-2"
                    >
                      <option value="">Pilih Metode</option>
                      <option value="1">Online</option>
                      <option value="2">Offline</option>
                    </select>
                    {errors.metode && (
                      <span className="ml-1" style={{ color: "red" }}>
                        {errors.metode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    No WhatsApp
                  </label>
                  <div className="col-sm-10">
                    <input
                      {...register("whatsApp")}
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Masukan no whatsapp yang bisa dihubungi"
                    />
                  </div>
                </div>
                {watch("metode") == "2" && (
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label col-form-label-lg">
                      Alamat
                    </label>
                    <div className="col-sm-10">
                      <textarea
                        {...register("alamat", {
                          required: "Alamat tidak boleh kosong",
                        })}
                        type="text"
                        rows="3"
                        className="form-control form-control-lg"
                        placeholder="Masukan alamat jika metode offline"
                      />
                      {errors.alamat && (
                        <span className="ml-1" style={{ color: "red" }}>
                          {errors.alamat.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label col-form-label-lg">
                    Informasi Tambahan
                  </label>
                  <div className="col-sm-10">
                    <textarea
                      {...register("info")}
                      type="text"
                      rows="3"
                      className="form-control form-control-lg"
                      placeholder="Masukan informasi tambahan"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-2" />
                  <div className="col-sm-10">
                    <button
                      type="submit"
                      className="btn mt-3 btn-danger btn-lg"
                      disabled={loading}
                    >
                      {loading && (
                        <Spinner
                          style={{ width: "20px", height: "20px" }}
                          animation="border"
                          variant="light"
                        />
                      )}{" "}
                      Kirim Permintaan Privat
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Styles>
      ) : (
        <section className="course-details-area" style={{ paddingBottom: 60 }}>
          <Error401 />
        </section>
      )}

      {/* Footer 2 */}
      <Footer />
    </div>
  );
}
