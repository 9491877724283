import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    img {
        object-fit : contain
    }
    button.enroll-btn {
        font-size: 14px;
        color: #fff;
        background: ${colors.gr_bg};
        display: inline-block;
        min-width: 100px;
        height: 40px;
        font-weight : 400;
        border : none;
        padding: 9px;
        border-radius: 5px;
        &:hover {
            background: ${colors.gr_bg2};
        }

        @media(max-width: 575px) {
            font-size : 14px;
        }
    }
    p {
        margin: 15px;
        font-family: Poppins, sans-serif;
        font-size: 17px;
        // font-weight : bold;
    }
`;