import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Accordion,
  Spinner,
} from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/course.js";
import ButtonChat from "../../components/common/ButtonChat";
import { Link, useParams } from "react-router-dom";
import RenderSectionItem from "./components/RenderSectionItem";
import { ContextProvider } from "../../context/BaseContext.js";
import RenderLevelToString from "../../components/RenderLevelToString";
import Catatan from "./components/Catatan";
import Deskripsi from "./components/Deskripsi";
import Instruktur from "./components/Instruktur";
import Review from "./components/Review";
import FileContents from "./components/fileContents";
import { getRating, signatureKey } from "../../util/Functions";
import Quiz from "./components/Quiz";
import { Document, Page, pdfjs } from "react-pdf";
import Practice from "./components/Practice";
import Diskusi from "./components/Diskusi";
import moment from "moment";
import Swal from "sweetalert2";
import WebinarRutin from "./components/WebinarRutin";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function LearnMyCLass() {
  const context = useContext(ContextProvider);
  const { id } = useParams();
  const video = useRef();

  const [time, setTime] = useState(0);
  const [isTimeLine, setIsTimeline] = useState(false);
  // handle ketika user sedang mengisi kuis
  // tidak boleh mengganti content sebelum menyelesaikanya
  const [cannotChangeContent, setCannotChangeContent] = useState(false);

  const [listFilesByContent, setListFilesByContent] = useState([]);
  // const [activity, setActivity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  const [category, setCategory] = useState([]);
  const [mentors, setMentors] = useState({});
  const [sertifkatData, setSertifikatData] = useState("");
  const [isMateriShow, setIsMateriShow] = useState("1");
  const [errorBanner, setErrorBanner] = useState(false);
  const [idContentActive, setidContentActive] = useState("");
  const [rating, setRating] = useState([]);
  const [upcomingWebinar, setUpcomingWebinar] = useState([]);
  const [historyWebinar, setHistoryWebinar] = useState([]);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [errorAvatar, setErrorAvatar] = useState(false);
  const [isBankSoal, setIsBankSoal] = useState(false);
  const [idClass, setIdClass] = useState(0);
  const [testDone, setTestDone] = useState(false);
  const [preTest, setPreTest] = useState([]);
  const [postTest, setPostTest] = useState([]);
  const [emptyChat, setEmptyChat] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const bumper = context.bumper;
  //Preview Content
  const [typeContent, setTypeContent] = useState(0);
  const [urlContent, setUrlContent] = useState("");
  const [urlSource, setUrlSource] = useState(bumper);
  const [signatureValue, setSignatureValue] = useState("");
  const [dataChat, setDataChat] = useState({});
  const [text, setText] = useState("");
  const [praktikDone, setPraktikDone] = useState(false);

  const [messageBrokenFile, setMessageBrokenFile] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [key, setKey] = useState("webinar");
  const [inTime, setInTime] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(pageNumber);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);

    if (pageNumber + 1 == numPages) {
      setActivityDone(idContentActive);
    }
  }
  async function getConfigTime() {
    let response = await context.getRequest(`config?valueOf=minute_video_afk`);
    if (response) {
      setTime(parseInt(response.data.config_value));
    }
  }
  async function getDetailFeedback() {
    try {
      let response = await context.getRequest(
        `get_detail_feedback_users?content_id=${idContentActive}&mentor_id=${mentors.id}`
      );
      if (response) {
        setDataChat(response.data);
      }
    } catch (err) {
      setEmptyChat(true);
    }
  }

  // async function getData() {
  //   let response = await context.getRequest(`kelas_user_detail/${id}`);
  //   if (response) {
  //     setCategory(response.data.data.category_classes);
  //     setMentors(response.data.data.mentors);
  //     if (response.data.certificate) {
  //       let sf = response.data.certificate.split("/");
  //       let token = sf[sf.length - 1];
  //       // console.log("TOKEN",token);
  //       setSertifikatData(token);
  //     }
  //   }
  // }
  async function getWebinar() {
    let response = await context.getRequest(`webinar-prakerja/schedule/${id}`);
    if (response.data.status) {
      setUpcomingWebinar(response.data.data);
      setHistoryWebinar(response.data.history_webinar);
    }
  }

  async function getDataKelas(ld = false) {
    if (ld) {
      setLoading(true);
    }

    let response = await context.getRequest(`kelas_user_detail/${id}`);
    setClassData(response.data.data);
    setCategory(response.data.data.category_classes);
    setMentors(response.data.data.mentors);
    setIsBankSoal(response.data.data.payment_type_prakerja);
    setIdClass(response.data.data.id);
    setPreTest(response.data.data.pretest);
    setPostTest(response.data.data.postest);
    if (response.data.certificate) {
      let sf = response.data.certificate.split("/");
      let token = sf[sf.length - 1];
      // console.log("TOKEN",token);
      setSertifikatData(token);
    }
    setLoading(false);
  }

  function totalVideo(a, b, c) {
    let total = parseInt(a) - parseInt(b) - parseInt(c);
    return total.toString();
  }

  async function setActivityDone(idContent) {
    let form = new FormData();
    form.append("content_id", idContent);
    form.append("class_id", id);

    try {
      let response = await context.postRequest("kelas-activity", form, "form");
      if (response) {
        // getData();
        getDataKelas();
        setPraktikDone(context.isPraktikumDone);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function refreshRatings() {
    setReload(true);
    let response = await context.getRequest(
      "kelas-rating/" + id + "?page=" + page
    );
    setRating(response.data.data.data);
    setReload(false);
  }

  async function joinWebinar(id_webinar, url) {
    Swal.fire({
      title: "Penting!",
      text: "Mintalah form absensi kepada mentor, sebagai bukti Anda mengikuti webinar",
      icon: "warning",
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Gabung",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let res = await context.getRequest(
          `webinar-prakerja/join/${id_webinar}`
        );
        if (res.data.status) {
          window.open(url, "_blank");
        }
        setIsLoading(false);
      }
    });
  }

  async function getRatings() {
    setReload(true);
    let response = await context.getRequest(
      "kelas-rating/" + id + "?page=" + page
    );
    setRating(rating.concat(response.data.data.data));
    setReload(false);
  }

  useEffect(() => {
    setPraktikDone(context.isPraktikumDone);
  }, [context.isPraktikumDone]);

  useEffect(() => {
    getRatings();
  }, [page]);

  function reloadPage() {
    setPage(page + 1);
  }

  useEffect(() => {
    getDataKelas(true);
    getConfigTime();
    getWebinar();
  }, []);

  async function videoToBumper(contentTypeBumper) {
    if (contentTypeBumper == 1 || contentTypeBumper == 3) {
      setUrlSource(bumper);
      let sig = await signatureKey();
      setSignatureValue(sig.url);
      // alert(sig.url)
    }
  }

  async function postFeedback(text) {
    let form = new FormData();
    form.append("text", text);
    form.append("mentor_id", mentors.id);
    form.append("content_id", idContentActive);
    let response = await context.postRequest("create_feedback_user", form);
    if (response) {
      setText("");
      getDetailFeedback();
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      postFeedback(text);
    }
  };
  async function getQuiz() {
    setTypeContent(2);
  }

  useEffect(() => {
    if (idContentActive && typeContent == 4) {
      getDetailFeedback();
    }
    if (typeContent == 4) {
      setKey("diskusi");
    } else {
      setKey("webinar");
    }
  }, [typeContent, idContentActive]);

  function getTime(time) {
    var eventTime = moment().add(time, "minutes").unix(); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
    var currentTime = moment().unix(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime * 1000, "milliseconds");
    var interval = 1000;
    //console.log(moment().unix())
    window.xx = setInterval(function () {
      duration = moment.duration(duration - interval, "milliseconds");
      let h = duration.hours() < 10 ? "0" + duration.hours() : duration.hours();
      let m =
        duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes();
      let s =
        duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds();

      let timeout = h + ":" + m + ":" + s;
      console.log("timeout", timeout);

      if (timeout == "00:00:00") {
        setInTime(timeout);
        clearInterval(window.xx);
      } else {
        setInTime(timeout);
      }
    }, interval);
  }

  function showAlert(type) {
    if (type == "hidden" && isBankSoal && typeContent == 1) {
      setInTime("00:00:00");
      clearInterval(window.xx);
      getTime(time);
    }
    // getTime(1)
  }

  async function _retention() {
    if (document) {
      var hidden = "hidden";
      if (hidden in document)
        document.addEventListener("visibilitychange", onchange);
      else if ((hidden = "mozHidden") in document)
        document.addEventListener("mozvisibilitychange", onchange);
      else if ((hidden = "webkitHidden") in document)
        document.addEventListener("webkitvisibilitychange", onchange);
      else if ((hidden = "msHidden") in document)
        document.addEventListener("msvisibilitychange", onchange);
      // IE 9 and lower:
      else if ("onfocusin" in document)
        document.onfocusin = document.onfocusout = onchange;
      // All others:
      else
        window.onpageshow =
          window.onpagehide =
          window.onfocus =
          window.onblur =
            onchange;

      function onchange(evt) {
        var v = "visible",
          h = "hidden",
          evtMap = {
            focus: v,
            focusin: v,
            pageshow: v,
            blur: h,
            focusout: h,
            pagehide: h,
          };

        evt = evt || window.event;
        if (evt.type in evtMap) {
          // console.log("IF BARU : ", evtMap[evt.type])
          // submitRetention(evtMap[evt.type])
          // document.body.className = evtMap[evt.type];
        } else {
          showAlert(this[hidden] ? "hidden" : "visible");
          // submitRetention(this[hidden] ? "hidden" : "visible")
        }
        // document.body.className = this[hidden] ? "hidden" : "visible";
      }

      // if (document[hidden] !== undefined)
      //     onchange({ type: document[hidden] ? "blur" : "focus" });
    }
  }

  console.log("Type Konten", typeContent);
  useEffect(() => {
    if (typeContent == 1 && isBankSoal) {
      if (window) {
        // Pindah Tab
        window.addEventListener("blur", function () {
          showAlert("visible");
        });
        window.addEventListener("mousemove", function (e) {
          console.log("event dari mouse", e);
          setInTime("00:00:00");
          clearInterval(window.xx);
          getTime(time);
        });
      }
    } else {
      clearInterval(window.xx);
      setInTime("00:00:00");
    }
    window.onbeforeunload = function () {
      _retention();
    };
    _retention();
  }, [typeContent, isBankSoal, idContentActive]);

  // useEffect(()=>{
  //   if (inTime == "00:00:00") {
  //         alert('Muncul Popup')
  //   }
  // },[inTime])

  // useEffect(() => {
  //   if (time) {
  //     getTime(time);
  //   }
  // }, [time]);
  console.log("Waktu====", time);

  useEffect(() => {
    if (inTime && inTime == "00:00:01" && isBankSoal && typeContent == 1) {
      const video = document.getElementById("myVideo");
      video.pause();
      Swal.fire({
        title: "Video Terjeda",
        text: "Apakah ingin lanjut belajar?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            video.play();
          }
        })
        .catch((e) => {
          if (e) {
            video.pause();
          }
        });
    }
  }, [inTime]);

  return (
    <div className="main-wrapper course-details-page">
      {/* On get data loading */}

      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title={`Kelas ${classData.title}`}
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {loading ? (
        <Col md="12" className="text-center">
          <Spinner animation="border" variant="danger" className="mt-2" />
        </Col>
      ) : (
        <Styles>
          {/* Course Details */}
          <section id="contentPlay" className="course-details-area">
            <Container>
              <Row>
                <Col lg="9" md="9" sm="12">
                  {preTest.length == 0 && isBankSoal && (
                    <div
                      className="alert alert-warning"
                      role="alert"
                      style={{ marginBottom: "30px" }}
                    >
                      Silahkan mengerjakan PRE-TEST lebih dahulu untuk mengakses
                      materi kelas {classData.title}.
                    </div>
                  )}
                  {postTest.length > 0
                    ? null
                    : preTest.length > 0 &&
                      sertifkatData &&
                      isBankSoal && (
                        <div
                          className="alert alert-warning"
                          role="alert"
                          style={{ marginBottom: "30px" }}
                        >
                          Silahkan mengerjakan POST-TEST lebih dahulu untuk
                          mendapatkan sertifikat kelas {classData.title}.
                        </div>
                      )}

                  <div className="course-details-top">
                    <div className="course-top-overview">
                      <div className="d-flex overviews">
                        <div className="author">
                          {/* <Link to={"/instructor-details/"+mentors.id}> */}
                          <img
                            src={
                              errorAvatar
                                ? process.env.PUBLIC_URL +
                                  `/assets/images/author.jpg`
                                : mentors.avatar
                            }
                            onError={() => setErrorAvatar(true)}
                            alt=""
                          />
                          <div className="author-name">
                            <h6>Instruktur</h6>
                            <p>{mentors.name}</p>
                          </div>
                          {/* </Link> */}
                        </div>
                        <div className="category">
                          <h6>Kategori</h6>
                          <p>{category[0]?.category.title}</p>
                        </div>
                        <div className="rating">
                          <h6>Rating</h6>
                          <ul className="list-unstyled list-inline">
                            {getRating(
                              parseFloat(
                                classData.total_vote ? classData.total_vote : 0
                              )
                            )}
                            <li className="list-inline-item">
                              (
                              {parseFloat(
                                classData.total_vote ? classData.total_vote : 0
                              )}
                              )
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`course-details-banner text-center ${
                        typeContent == 2 ? "rounded" : ""
                      }`}
                      style={{
                        backgroundColor:
                          typeContent == 4 || typeContent == 2
                            ? "#FFFFFF"
                            : "#ebedef",
                        border: typeContent == 2 ? "1px solid #ebedef" : "",
                        boxShadow:
                          typeContent == 2 ? "0 8px 25px rgb(0 0 0 / 10%)" : "",
                        borderRadius: typeContent == 2 ? 5 : "",
                        padding: typeContent == 2 ? "20px 7px 20px" : "",
                      }}
                    >
                      {messageBrokenFile ? (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ height: "465px" }}
                        >
                          <div className="text-center">
                            <i
                              style={{ fontSize: "30px", color: "#EE2025" }}
                              className="fas fa-exclamation-circle mb-3"
                            ></i>
                            <h6>Tidak dapat menampilkan konten</h6>
                          </div>
                        </div>
                      ) : (
                        <>
                          {typeContent === 0 && (
                            <img
                              src={
                                errorBanner
                                  ? process.env.PUBLIC_URL +
                                    `/assets/images/details-banner.jpg`
                                  : classData?.img
                              }
                              alt="gambar-kelas"
                              className="img-fluid"
                              onError={() => setErrorBanner(true)}
                            />
                          )}
                          {typeContent === 1 && (
                            <video
                              id="myVideo"
                              className={!isTimeLine ? "timeline-control" : ""}
                              src={urlSource}
                              ref={video}
                              // src={urlContent}
                              type="video/mp4"
                              autoPlay={true}
                              disablePictureInPicture
                              onSeeked={(event) => {
                                // console.log(event.target.currentTime)
                              }}
                              onPlay={(event) => {
                                // console.log(event.target.currentTime);
                              }}
                              controls={true}
                              controlsList="nodownload noremoteplayback"
                              onContextMenu={(e) => e.preventDefault()}
                              onEnded={async () => {
                                // var u = urlContent + signatureValue;
                                // setUrlSource(u);
                                let response =
                                  await context.getRequestWithoutHost(
                                    urlContent
                                  );
                                if (response) {
                                  setUrlSource(response.data.url);
                                }
                                if (urlSource != bumper) {
                                  setActivityDone(idContentActive);
                                  setUrlSource("");
                                }
                              }}
                            ></video>
                          )}
                          {typeContent == 2 && (
                            <Quiz
                              preTest={preTest}
                              postTest={postTest}
                              setTestDone={setTestDone}
                              sertifkatData={sertifkatData}
                              idClass={idClass}
                              isBankSoal={isBankSoal}
                              idContentActive={idContentActive}
                              setActivityDone={setActivityDone.bind(this)}
                              getDataKelas={getDataKelas.bind(this)}
                              setCannotChangeContent={setCannotChangeContent}
                              idMentor={mentors?.id}
                            />
                          )}
                          {typeContent == 3 && (
                            <embed
                              title="content-pdf"
                              src={`${urlContent}#toolbar=0`}
                              style={{
                                border: "none",
                                height: "80vh",
                                width: "100%",
                              }}
                              onContextMenu={(e) => e.preventDefault()}
                              onLoad={() => setActivityDone(idContentActive)}
                            />
                          )}
                          {typeContent == 4 && (
                            <Practice
                              setPraktikDone={setPraktikDone}
                              praktikDone={praktikDone}
                              idContentActive={idContentActive}
                              getDataKelas={getDataKelas.bind(this)}
                              setActivityDone={setActivityDone.bind(this)}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div className="course-tab-list">
                      <Tab.Container
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                      >
                        <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="webinar">
                              Webinar Rutin
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            {/* {postTest.length == 0 &&
                              preTest.length == 0 &&
                              isBankSoal == null && (
                                <Nav.Item>
                                  <Nav.Link eventKey="catatan">
                                    Catatan Belajar
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            {preTest.length > 0 && isBankSoal && (
                              <Nav.Item>
                                <Nav.Link eventKey="catatan">
                                  Catatan Belajar
                                </Nav.Link>
                              </Nav.Item>
                            )} */}
                            <Nav.Link eventKey="catatan">
                              Catatan Belajar
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="overview">Deskripsi</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="instructor">
                              Instruktur
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="review">Ulasan</Nav.Link>
                          </Nav.Item>
                          {typeContent == 4 && isBankSoal && (
                            <Nav.Item>
                              <Nav.Link eventKey="diskusi">
                                Diskusi Tugas
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                        <Tab.Content>
                          {typeContent == 4 && isBankSoal && (
                            <Tab.Pane eventKey="diskusi" className="review-tab">
                              <Diskusi
                                mentors={mentors}
                                emptyChat={emptyChat}
                                dataChat={dataChat}
                                postFeedback={() => postFeedback(text)}
                                setText={setText}
                                text={text}
                                handleKeyDown={handleKeyDown}
                              />
                            </Tab.Pane>
                          )}

                          <Tab.Pane eventKey="webinar" className="overview-tab">
                            <WebinarRutin
                              upcomingWebinar={upcomingWebinar}
                              historyWebinar={historyWebinar}
                              joinWebinar={joinWebinar}
                              isLoading={isLoading}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="catatan" className="catatan-tab">
                            <Catatan
                              idContentActive={idContentActive}
                              context={context}
                            />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="overview"
                            className="overview-tab"
                          >
                            <Deskripsi data={classData} />
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="instructor"
                            className="instructor-tab"
                          >
                            <Instruktur data={mentors} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="review" className="review-tab">
                            <Review
                              sertifikat={sertifkatData}
                              data={rating}
                              reloadPage={reloadPage.bind(this)}
                              ratings={classData.ratings}
                              getRatings={refreshRatings.bind(this)}
                              getDataKelas={getDataKelas.bind(this)}
                              reload={reload}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Col>
                <Col className="pl-1 pr-1" lg="3" md="3" sm="12">
                  <div className="single-details-sidbar">
                    <Row>
                      <Col className="mb-4" md="12">
                        <div className="course-details-feature">
                          <h5 className="title">Webinar Mendatang</h5>
                          {upcomingWebinar.length == 0 && (
                            <div className="text-center">
                              <span className="text-muted">
                                Belum ada jadwal webinar
                              </span>
                            </div>
                          )}
                          {upcomingWebinar.map((item) => {
                            return (
                              <div key={item.id} className="mb-2">
                                <p>
                                  <b>{item.title}</b>
                                </p>
                                <p>
                                  {moment(item.tanggal).format("DD MMM YYYY")}{" "}
                                  {moment(item.jam_mulai, "hh:mm").format("LT")}{" "}
                                  -{" "}
                                  {moment(item.jam_selesai, "hh:mm").format(
                                    "LT"
                                  )}
                                </p>
                                {(item.status == 1 || item.status == 11) && (
                                  <button
                                    type="button"
                                    disabled={isLoading}
                                    onClick={() =>
                                      joinWebinar(item.id, item.link_invitation)
                                    }
                                    className="btn btn-sm btn-join w-100 mt-1"
                                  >
                                    Join
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                      {preTest.length == 0 && isBankSoal && (
                        <Col className="mb-3" md="12">
                          <div className="course-details-feature-header">
                            <h5>PRE-TEST</h5>
                          </div>
                          <div className="course-details-feature">
                            <button
                              type="button"
                              className="btn enroll-btn"
                              onClick={getQuiz}
                              disabled={testDone ? true : false}
                            >
                              PRE-TEST SEKARANG
                            </button>
                          </div>
                        </Col>
                      )}
                      {!isBankSoal && (
                        <Col className="mb-3" md="12">
                          <div className="course-details-feature-header">
                            <h5>MATERI KELAS</h5>
                          </div>
                          <div className="course-details-feature">
                            <Accordion defaultActiveKey={1}>
                              {classData.sections_user?.map((item, idx) => (
                                <RenderSectionItem
                                  setIsTimeline={setIsTimeline}
                                  key={idx}
                                  isMateriShow={isMateriShow}
                                  setIsMateriShow={setIsMateriShow}
                                  setTypeContent={setTypeContent}
                                  setUrlContent={setUrlContent}
                                  setidContentActive={setidContentActive}
                                  idContentActive={idContentActive}
                                  videoToBumper={videoToBumper}
                                  section={item}
                                  idx={idx + 1}
                                  cannotChangeContent={cannotChangeContent}
                                  setListFilesByContent={setListFilesByContent}
                                  context={context}
                                  setMessageBrokenFile={setMessageBrokenFile}
                                />
                              ))}
                            </Accordion>
                          </div>
                        </Col>
                      )}

                      {preTest.length > 0 && isBankSoal && (
                        <Col className="mb-3" md="12">
                          <div className="course-details-feature-header">
                            <h5>MATERI KELAS</h5>
                          </div>
                          <div className="course-details-feature">
                            <Accordion defaultActiveKey={1}>
                              {classData.sections_user?.map((item, idx) => (
                                <RenderSectionItem
                                  setIsTimeline={setIsTimeline}
                                  key={idx}
                                  isMateriShow={isMateriShow}
                                  setIsMateriShow={setIsMateriShow}
                                  setTypeContent={setTypeContent}
                                  setUrlContent={setUrlContent}
                                  setidContentActive={setidContentActive}
                                  idContentActive={idContentActive}
                                  videoToBumper={videoToBumper}
                                  section={item}
                                  idx={idx + 1}
                                  cannotChangeContent={cannotChangeContent}
                                  setListFilesByContent={setListFilesByContent}
                                  context={context}
                                  setMessageBrokenFile={setMessageBrokenFile}
                                />
                              ))}
                            </Accordion>
                          </div>
                        </Col>
                      )}

                      {postTest.length > 0
                        ? null
                        : preTest.length > 0 &&
                          sertifkatData &&
                          isBankSoal && (
                            <Col className="mb-3" md="12">
                              <div className="course-details-feature-header">
                                <h5>POST-TEST</h5>
                              </div>
                              <div className="course-details-feature">
                                <button
                                  type="button"
                                  className="btn enroll-btn"
                                  onClick={getQuiz}
                                  disabled={testDone ? true : false}
                                >
                                  POST-TEST SEKARANG
                                </button>
                              </div>
                            </Col>
                          )}
                      {/* File Pendukung */}
                      <FileContents
                        idContentActive={idContentActive}
                        context={context}
                        filesContent={listFilesByContent}
                      />
                      {/* File Pendukung */}
                      <Col className="mt-4" md="12">
                        <div className="course-details-feature">
                          <h5 className="title">Detail Kelas</h5>
                          <ul className="list-unstyled feature-list">
                            <li>
                              <i className="las la-clock"></i> Durasi:{" "}
                              <span>{classData.total_durasi} menit</span>
                            </li>
                            <li>
                              <i className="las la-sort-amount-up"></i> Level:{" "}
                              <RenderLevelToString status={classData.level} />
                            </li>
                            <li>
                              <i className="las la-book"></i> Total Materi:{" "}
                              <span>{classData.total_content}</span>
                            </li>
                            <li>
                              <i className="las la-file-pdf"></i> Artikel:{" "}
                              <span>{classData.total_article}</span>
                            </li>
                            <li>
                              <i className="las la-tasks"></i> Kuis:{" "}
                              <span>{classData.total_quiz}</span>
                            </li>
                            <li>
                              <i className="lar la-file-video"></i> Video:{" "}
                              <span>
                                {totalVideo(
                                  classData.total_content,
                                  classData.total_quiz,
                                  classData.total_article
                                )}
                              </span>
                            </li>
                            <li>
                              <i className="las la-user"></i> Peserta:{" "}
                              <span>{classData.total_siswa}</span>
                            </li>
                            <li>
                              <i className="las la-infinity"></i> Dapat diakses
                              kapan pun
                              <span>
                                <i className="las la-check-circle text-success"></i>
                              </span>
                            </li>
                            <li>
                              <i className="las la-certificate"></i> Mendapatkan
                              sertifikat
                              <span>
                                <i className="las la-check-circle text-success"></i>
                              </span>
                            </li>
                          </ul>
                          <Link
                            to={
                              process.env.PUBLIC_URL +
                              `/certificate/${sertifkatData}`
                            }
                          >
                            <button
                              type="button"
                              className="btn enroll-btn"
                              disabled={
                                postTest.length == 0 && isBankSoal
                                  ? true
                                  : sertifkatData
                                  ? false
                                  : true
                              }
                            >
                              Dapatkan Sertifikat
                            </button>
                          </Link>
                        </div>
                      </Col>
                      {/* <Col md="12">
                            <PopularCourse />
                        </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      )}

      {/* BUTTON CHAT */}
      <ButtonChat />

      {/* Footer 2 */}
      {loading ? null : <Footer />}
    </div>
  );
}

export default LearnMyCLass;
