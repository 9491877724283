import React, { Component, Fragment, useContext, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { LoadingBall } from '../../../components/LoadingBall'
import Pagination from '../../../components/Pagination';
import Error500 from '../../../components/Error500';
import EmptyData from '../../../components/EmptyData';
import GridItem from './GridItem'

function CourseItemGrid({ pagination, changePage, data, founded, refresh, reload, error }) {
    if (refresh) {
        return <LoadingBall/>
    } else {
        if (error) {
            return <Col md="12" className="text-center"><Error500 refresh={reload()} /></Col>
        }
        if (data.length != 0) {
            return (
                <Fragment>
                    {/* Course Item */}
                    {!founded ? (
                        <div className="alert alert-danger col-md-12" role="alert">
                            Data tidak ditemukan, tp kamu masih bisa lihat kelas yang lain loh!
                        </div>
                    ) : null}
                    {
                        data.map((data, i) => {
                            return <GridItem key={i} data={data} />
                        })
                    }

                    <Col md="12" className="text-center">
                        <Pagination pagination={pagination} changePage={changePage.bind(this)} />
                    </Col>
                </Fragment>
            )
        } else {
            return <Col md="12" className="text-center"><EmptyData /></Col>
        }
    }
}


export default CourseItemGrid
