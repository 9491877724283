import React, { useContext, useEffect, useState } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { LoadingBall } from "../../components/LoadingBall";
import { Styles } from "./styles/course.js";
import Footer from "../../components/Footer";
import DataTable from "../../components/DataTable";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { ContextProvider } from "../../context/BaseContext";
import Error500 from "../../components/Error500";
import Error401 from "../../components/Error401";
import moment from "moment";
import { renderStatusPrivat } from "../../util/Functions";

export default function ListPrivate() {
  const context = useContext(ContextProvider);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState(false);

  const [listDataPrivate, setListDataPrivate] = useState([]);

  async function getListPrivate() {
    setLoading(true);
    try {
      let response = await context.getRequest("getListPrivateRequestSiswa");
      console.log(response);
      if (response) {
        setLoading(false);
        setListDataPrivate(response.data.data);
        setError(false);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }

  async function hendleBuyLiveClass(id) {
    console.log(id);
    setReload(true);
    let form = {
      class_id: id,
      tipe: "pc",
    };

    let response = await context.postRequest("transaction-snap-token", form);
    // console.log("RESPONSE ", response);
    if (response != null) {
      setReload(false);
      if (response.data.hasOwnProperty("redirect_url")) {
        window.snap.pay(response.data.token, {
          onSuccess: function (result) {
            console.log("success");
            console.log(result);
            getListPrivate();
          },
          onPending: function (result) {
            console.log("pending");
            console.log(result);
            getListPrivate();
          },
          onError: function (result) {
            console.log("error");
            console.log(result);
          },
          onClose: function () {
            console.log("close");
          },
        });
      } else {
        // console.log('tidak ada');
      }
      // console.log(response.data)
    }
  }

  useEffect(() => {
    getListPrivate();

    //change this to the script source you want to load, for example this is snap.js sandbox env
    // const midtransScriptUrl = "https://app.midtrans.com/snap/snap.js";
    const midtransScriptUrl = "https://app.sandbox.midtrans.com/snap/snap.js";
    //change this according to your client-key
    // const myMidtransClientKey = "Mid-client-BEo9mX5sZY9dZSJd";
    const myMidtransClientKey = "SB-Mid-server-wCeL_HdnpF-QRAyWJzykflp6";

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);
  return (
    <div className="main-wrapper course-details-page">
      <MetaTags>
          <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="List Pengajuan Kelas Privat"
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {context.login ? (
        loading ? (
          <section
            className="course-details-area"
            style={{ paddingBottom: 60 }}
          >
            <LoadingBall />
          </section>
        ) : error ? (
          <Error500 refresh={getListPrivate.bind(this)} />
        ) : (
          <Styles>
            {/* Course Details */}
            <section
              className="course-details-area"
              style={{ paddingBottom: 60 }}
            >
              <div className="container">
                <div className="card">
                  <div className="card-body user-profile-list">
                    <div className="dt-responsive table-responsive">
                      <DataTable
                        className={"table dataTable-style nowrap"}
                        data={listDataPrivate}
                        totalRows={listDataPrivate.length}
                        show={[10, 20, 30, 40, 50]}
                        onChangeSearch={(val) => {
                          console.log(val);
                        }}
                        onChangeLimit={(val) => {
                          console.log(val);
                        }}
                        onChangePage={(val) => {
                          console.log(val);
                        }}
                        column={[
                          // "No",
                          "Kode",
                          "Kelas",
                          "Tanggal",
                          "Kuantitas",
                          "Tipe",
                          "Status",
                          "Harga",
                          "Action",
                        ]}
                        renderItem={(item, i) => (
                          <RenderItem val={item} key={i} />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Styles>
        )
      ) : (
        <Error401 />
      )}

      {/* Footer 2 */}
      <Footer />
    </div>
  );

  function RenderItem({ val }) {
    return (
      <tr>
        <td>{val.id}</td>
        <td>{val.classes?.title}</td>
        <td>
          {val.date_start == val.date_end ? (
            moment(val.date_start).format("DD MMM YYYY")
          ) : (
            <>
              <p className="m-0">
                {moment(val.date_start).format("DD MMM YYYY")} s/d
              </p>
              <p className="m-0">
                {moment(val.date_end).format("DD MMM YYYY")}
              </p>
            </>
          )}
        </td>
        <td>{val.qty_user} Orang</td>
        <td>{val.type.toString() == "1" ? "Online" : "Offline"}</td>
        <td>
          {val.status == 1 &&
          val.payment?.transaction_status == "settlement" ? (
            <span
              style={{
                fontSize: "13px",
                color: "#0f5132",
                backgroundColor: "#d1e7dd",
              }}
              className="badge badge-pill badge-light"
            >
              Pembayaran berhasil
            </span>
          ) : (
            renderStatusPrivat(val.status)
          )}
        </td>
        <td>{val.harga ? val.harga : "-"}</td>
        <td>
          <i className="fas fa-ellipsis-h" />

          <div className="overlay-edit">
            {val.status == 1 &&
            val.payment?.transaction_status != "settlement" ? (
              <button
                onClick={(e) => hendleBuyLiveClass(val.id)}
                className="btn btn-icon btn-info text-light"
              >
                <i className="fas fa-dollar-sign"></i>
              </button>
            ) : null}
            <a
              href="#!"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `${process.env.PUBLIC_URL}/private-discuss/${val.id}`,
                });
              }}
              className="btn btn-icon btn-warning text-light"
            >
              <i className="fas fa-comments"></i>
            </a>
          </div>
        </td>
      </tr>
    );
  }
}
