import React from "react";
import moment from "moment";
import { host, shareHost } from "./host";
import jwt from "jsonwebtoken";
import {encode, decode,Base64} from 'js-base64'

const Cryptr = require("cryptr");
const cryptr = new Cryptr("!wrt@Academy!2)((0");

var apiKey = "Nj1u%tyud4#BestWakoolAcademy2021";
var webKey = "IniW3b2120#y4ngP4l1ngW%w";
var tokenKey = "12esP0n3se#J1112";

export const generateParamsQueryClass=(kelas)=>{
  function stripHtml(html)
  {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      var str=tmp.textContent || tmp.innerText || "";
      return str.substr(0,100)
  }
  var desc=stripHtml(kelas.description)
  return `?title=${Base64.encode(kelas.title)}&img=${Base64.encode(kelas.img)}&description=${Base64.encode(desc)}`
}

export const generateBase64=(title)=>{
  return Base64.encode(title)
}
export const paramsMentor=(mentorData)=>{
  if(mentorData){
    return `?name=${generateBase64(mentorData?.name)}&description=${generateBase64(mentorData?.description)}`
  }
  return ''
}
export const paramsWebinars=(data)=>{
  if(data){
    var a=data.mentor_name+" "+data.mentor_title
    return `${shareHost}${data.id}/webinar/${convertToSlug(data.title)}?source=${data.source}`
    return `webinars-detail/${data.id}/${convertToSlug(data.title)}?source=${data.source}&title=${generateBase64(data.title)}&description=${generateBase64(a)}`
  }
  return ''
}

export const decodeJWT = async (response) => {
  try {
    // console.log("RESPONSE PARAMS : ",response)
    if (typeof response.type != "undefined") {
      var spl = response.data.split(".");
      var keyPair = response.keyPair;
      var data = spl[1].slice(0, -keyPair);
      var token = spl[0] + "." + data + "." + spl[2];
      var decoded = await jwt.verify(token, tokenKey);
      // console.log("decoded",decoded)
      return decoded.parse;
    }
    return response;
  } catch (error) {
    console.log("decodeJWT", error.stack);
    return error.stack;
  }
};
export const signatureKey = async () => {
  try {
    let response = await fetch(host + "signature-key");
    let json = await response.json();
    var decoded = jwt.verify(json.signatureKey, apiKey + json.keyPair);
    var salt = decoded.salt;
    var token = jwt.sign({ salt: salt }, webKey + json.keyPair);
    return {
      keyPair: json.keyPair,
      token,
      url: `&signatureKey=${token}&keyPair=${json.keyPair}`,
    };
  } catch (e) {
    return e.stack;
  }
};

export const Prices = ({ detail, realPrice, usePromo = "" }) => {
  if (detail.discount) {
    var pr = pricesReal(detail.discount.prices_disc);
    var potongan = detail.prices - pr;
    var persen = (potongan / detail.prices) * 100;

    if (typeof realPrice === "function") {
      // do something
      realPrice(pr);
    }
    // console.log("PR : ",detail.discount.prices_disc)
    if (detail.prices < detail.discount.prices_disc) {
      return (
        <>
          <h5 className="price">Rp. {currencyFormat(detail.prices)}</h5>
        </>
      );
    } else {
      return (
        <div className="row" style={{ paddingLeft: 17 }}>
          <div
            style={{
              backgroundColor: "#f8d7da",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              borderRadius: 5,
              padding: 5,
            }}
          >
            <p style={{ color: "#721c24", fontSize: 10 }}>
              {persen.toString().length > 5
                ? persen.toString().substring(0, 5)
                : persen}
              %
            </p>
          </div>
          <h5 className="price">
            Rp. {currencyFormat(pricesReal(detail.discount.prices_disc))}
          </h5>
          <h6
            className="price"
            style={{
              textDecorationLine: "line-through",
              fontSize: 12,
              textVerticalAlign: "bottom",
              color: "grey",
            }}
          >
            Rp. {currencyFormat(detail.prices)}
          </h6>
        </div>
      );
    }
  } else {
    var dis = pricesReal(detail.prices);
    var potongan = detail.prices - dis;
    var persen = (potongan / detail.prices) * 100;
    // console.log("DIS ",dis,detail.prices)
    if (dis != 0 && dis != detail.prices) {
      //realPrice(dis)
      if (typeof realPrice === "function") {
        // do something
        realPrice(dis);
      }
      return (
        <div className="row" style={{ paddingLeft: 17 }}>
          <div
            style={{
              backgroundColor: "#f8d7da",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              borderRadius: 5,
              padding: 5,
            }}
          >
            <p style={{ color: "#721c24", fontSize: 10 }}>{persen}%</p>
          </div>
          <h5 className="price">Rp. {currencyFormat(dis)}</h5>
          <h6
            className="price"
            style={{
              textDecorationLine: "line-through",
              fontSize: 12,
              textVerticalAlign: "bottom",
              color: "grey",
            }}
          >
            Rp. {currencyFormat(detail.prices)}
          </h6>
        </div>
      );
    } else {
      // realPrice=detail.prices
      //realPrice(detail.prices)
      if (typeof realPrice === "function") {
        // do something
        realPrice(detail.prices);
      }
      return (
        <>
          <h5 className="price">Rp. {currencyFormat(detail.prices)}</h5>
        </>
      );
    }
  }
};

export const NewPrices = ({
  detail,
  realPrice,
  usePromo = null,
  priceHome = false,
}) => {
  console.log("NP DETAIL : ",detail);
  console.log("NP REAL PRICE : ",realPrice);
  console.log("NP USE PROMO : ",usePromo);
  console.log("NP PRICE HOME : ",priceHome);

  if (detail?.prices?.toString() == "0") {
    if (typeof realPrice === "function") {
      // do something
      realPrice(detail.prices);
    }
    // return <h5 className="price">Rp. {currencyFormat(detail.prices)}</h5>;
    return <h5 className="price">Gratis</h5>;
  } else if (usePromo) {
    let pr = usePromo.promo_price;
    let potongan = detail.prices - pr;
    let persen = (potongan / detail.prices) * 100;
    if (typeof realPrice === "function") {
      // do something
      realPrice(pr);
    }

    return (
      <div className="row" style={{ paddingLeft: 17 }}>
        <div
          style={{
            backgroundColor: "#f8d7da",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 10,
            borderRadius: 5,
            padding: 5,
          }}
        >
          <p style={{ color: "#721c24", fontSize: 10 }}>
            {persen.toString().length > 5
              ? persen.toString().substring(0, 5)
              : persen}
            %
          </p>
        </div>
        <h5 className="price">Rp. {currencyFormat(usePromo.promo_price)}</h5>
        <h6
          className="price"
          style={{
            textDecorationLine: "line-through",
            fontSize: 12,
            textVerticalAlign: "bottom",
            color: "grey",
          }}
        >
          Rp. {currencyFormat(detail.prices)}
        </h6>
      </div>
    );
  } else if (detail?.discount && priceHome) {
    let priceDiscount = detail.discount.prices_disc;
    let newUserDiscount = pricesReal(detail.prices);
    // console.log("CEKCEKCEKCEKCEK",Math.min(parseInt(priceDiscount),parseInt(newUserDiscount)));

    let pr = Math.min(parseInt(priceDiscount), parseInt(newUserDiscount));
    let potongan = detail.prices - pr;
    let persen = (potongan / detail.prices) * 100;
    if (typeof realPrice === "function") {
      // do something
      realPrice(pr);
    }

    return (
      <div className="row" style={{ paddingLeft: 17 }}>
        <div
          style={{
            backgroundColor: "#f8d7da",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 10,
            borderRadius: 5,
            padding: 5,
          }}
        >
          <p style={{ color: "#721c24", fontSize: 10 }}>
            {persen.toString().length > 5
              ? persen.toString().substring(0, 5)
              : persen}
            %
          </p>
        </div>
        <h5 className="price">Rp. {currencyFormat(pr)}</h5>
        <h6
          className="price"
          style={{
            textDecorationLine: "line-through",
            fontSize: 12,
            textVerticalAlign: "bottom",
            color: "grey",
          }}
        >
          Rp. {currencyFormat(detail.prices)}
        </h6>
      </div>
    );
  } else {
    let price = detail.prices;
    let newUserDiscount = pricesReal(price,usePromo);
    // console.log("NEWWWW", newUserDiscount, price);

    if (price != newUserDiscount && priceHome) {
      let pr = newUserDiscount;
      let potongan = detail.prices - pr;
      let persen = (potongan / detail.prices) * 100;
      if (typeof realPrice === "function") {
        // do something
        realPrice(pr);
      }
      return (
        <div className="row" style={{ paddingLeft: 17 }}>
          <div
            style={{
              backgroundColor: "#f8d7da",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              borderRadius: 5,
              padding: 5,
            }}
          >
            <p style={{ color: "#721c24", fontSize: 10 }}>
              {persen.toString().length > 5
                ? persen.toString().substring(0, 5)
                : persen}
              %
            </p>
          </div>
          <h5 className="price">Rp. {currencyFormat(pr)}</h5>
          <h6
            className="price"
            style={{
              textDecorationLine: "line-through",
              fontSize: 12,
              textVerticalAlign: "bottom",
              color: "grey",
            }}
          >
            Rp. {currencyFormat(detail.prices)}
          </h6>
        </div>
      );
    } else {
      if (typeof realPrice === "function") {
        // do something
        realPrice(detail.prices);
      }
      return (
        <>
          <h5 className="price">Rp. {currencyFormat(price)}</h5>
        </>
      );
    }
  }
};

const pricesReal = (price,usePromo=null) => {
  var diskon = localStorage.getItem("diskon_new_user");
  if (!diskon) {
    diskon = {
      percentage: 0,
      days: 0,
    };
  } else {
    diskon = JSON.parse(diskon);
  }
  // console.log("DISKON : ",price,diskon.percentage)
  var user = localStorage.getItem("profile");
  if (user) {
    user = JSON.parse(user);
    var ca = user.created_at.split(" ");
    var nowDate = moment().format("YYYY-MM-DD");
    var date1 = moment(ca[0]);
    var date2 = moment(nowDate);
    var diff = date2.diff(date1, "days");
    // var startdate = moment().subtract(diskon.days, "days").format("YYYY-MM-DD");
    // if (diff <= diskon.days) {
    if (diff <= diskon.days && usePromo) {
      // console.log("DIFF : ",price,price-((price*diskon.percentage)/100))
      return price - (price * diskon.percentage) / 100;
    } else {
      // console.log("DIFF : ",price)
      return price;
    }
    // console.log("DIFF : ",price,price-((price*diskon.percentage)/100))
  } else {
    // console.log("DISKON : ",price,diskon.percentage)
    return price - (price * diskon.percentage) / 100;
  }
  // if(diskon.days!=0 && diskon.percentage!=0){
  //   console.log(moment)
  // }
};

export const encryptString = async (str) => {
  return await cryptr.encrypt(str);
};
export const decryptString = async (encrypt) => {
  if (encrypt != null) {
    return await cryptr.decrypt(encrypt);
  }
  return;
};

export const setStorage = async (key, value) => {
  try {
    //console.log('function setStorage ',key,value)
    await localStorage.setItem(key, value);
  } catch (e) {
    //console.log('cathc setStorage')
  }
};

export const getStorage = async (key, value) => {
  try {
    //console.log('function setStorage ',key,value)
    return await localStorage.getItem(key);
  } catch (e) {
    //console.log('cathc setStorage')
  }
};

export const currencyFormat = (num) => {
  if (num != null && num != "") {
    let n = parseFloat(num);
    return "" + n.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  } else {
    return 0;
  }
};

export function removeSecTime(time) {
  let newTime = time.split(":");
  return newTime[0] + ":" + newTime[1];
}

export const getRating = (total) => {
  function getValue(value) {
    switch (value) {
      case 0:
        return "lar la-star";
      case 50:
        return "las la-star-half-alt";
      case 100:
        return "las la-star";
    }
  }

  function getStars(value) {
    if (value > 0 && value < 1) {
      return [50, 0, 0, 0, 0];
    } else if (value == 1) {
      return [100, 0, 0, 0, 0];
    } else if (value > 1 && value < 2) {
      return [100, 50, 0, 0, 0];
    } else if (value == 2) {
      return [100, 100, 0, 0, 0];
    } else if (value > 2 && value < 3) {
      return [100, 100, 50, 0, 0];
    } else if (value == 3) {
      return [100, 100, 100, 0, 0];
    } else if (value > 3 && value < 4) {
      return [100, 100, 100, 50, 0];
    } else if (value == 4) {
      return [100, 100, 100, 100, 0];
    } else if (value > 4 && value < 5) {
      return [100, 100, 100, 100, 50];
    } else if (value >= 5) {
      return [100, 100, 100, 100, 100];
    } else {
      return [0, 0, 0, 0, 0];
    }
  }

  return (
    <>
      {getStars(total).map((value, idx) => {
        return (
          <li key={idx} className="list-inline-item">
            <i className={getValue(value)}></i>
          </li>
        );
      })}
    </>
  );
};

export const minuteToHour = (minutes = 0) => {
  if (minutes <= 60) {
    return minutes + " menit";
  } else {
    return Math.ceil(minutes / 60) + " jam";
  }
};

export const convertToBase64 = (file) => {
  return new Promise((resolve) => {
    // let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      // console.log("Called", reader);
      baseURL = reader.result;
      // console.log(baseURL);
      resolve(baseURL);
    };
    // console.log(fileInfo);
  });
};

//===========================FUNCTION CROP IMAGE==========================
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  // As a blob
  //   return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //       resolve(URL.createObjectURL(file))
  //     }, 'image/jpeg')
  //   })
}

//============END CROP IMAGE=============================

export const RenderStatusLiveKelas = ({ status }) => {
  let textStatus = "";
  let style = "";
  switch (status) {
    case 11:
      textStatus = "Berlangsung";
      style = "badge bg-success text-light";
      break;
    case 12:
      textStatus = "Selesai";
      style = "badge bg-danger text-light";
      break;
    default:
      textStatus = "Belum dimulai";
      style = "badge bg-secondary text-light";
      break;
  }
  return (
    <span
      style={{ fontSize: "11px", padding: ".10em 0.4em" }}
      className={style}
    >
      {textStatus}
    </span>
  );
};

export function renderStatusPrivat(status) {
  let stat = "";
  let bgColor = "white";
  let fColor = "white";

  switch (status) {
    case 0:
      stat = "Menunggu persetujuan admin";
      fColor = "#664d03";
      bgColor = "#fff3cd";
      break;
    case 1:
      stat = "Menunggu Pembayaran";
      bgColor = "#d1e7dd";
      fColor = "#0f5132";
      break;
    case 2:
      stat = "Mentor mengajukan penawaran";
      fColor = "#055160";
      bgColor = "#cff4fc";
      break;
    case 3:
      stat = "Menunggu persetujuan mentor";
      fColor = "#664d03";
      bgColor = "#fff3cd";
      break;
    case 5:
      stat = "Menunggu persetujuan Anda";
      fColor = "#084298";
      bgColor = "#cfe2ff";
      break;
    case 40:
      stat = "Anda membatalkan penawaran";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 42:
      stat = "Mentor membatalkan penawaran";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 43:
      stat = "Penawaran mentor ditolak admin";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;
    case 45:
      stat = "Penawaran Anda ditolak admin";
      fColor = "#842029";
      bgColor = "#f8d7da";
      break;

    default:
      break;
  }

  return (
    <span
      style={{ fontSize: "13px", color: fColor, backgroundColor: bgColor }}
      className="badge badge-pill badge-light"
    >
      {stat}
    </span>
  );
}

export const convertToSlug=(Text)=> {
  if(Text){
    return Text
             .toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
  }
  return null
  
}