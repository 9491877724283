import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export default function Deskripsi({data}) {
  const url = `https://wakool.academy/detail-class/${data.id}`
  const message = `Hai, saya ingin membagikan kursus ${data.title}`
  return (
    <>
      <div className="course-desc">
        <h5>Deskripsi</h5>
        <p dangerouslySetInnerHTML={{__html:data.description}}/>
      </div>
      <div className="course-feature">
        <h5>Prerequisite</h5>
        <p dangerouslySetInnerHTML={{__html:data.prerequisite}}/>
        {/* <ul className="list-unstyled">
          <li>
            <i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis
            necessitatibus hic natus facere excepturi aliquid dolor ducimus.
          </li>
          <li>
            <i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis
            necessitatibus hic natus facere excepturi aliquid .
          </li>
          <li>
            <i className="las la-arrow-right"></i> Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis
            necessitatibus hic natus facere excepturi.
          </li>
        </ul> */}
      </div>
      <div className="course-share">
        <h5>Bagikan kelas ini</h5>
        <ul className="social list-unstyled list-inline">
          <li className="list-inline-item">
            <FacebookShareButton
              url={url}
              quote={message}
              hashtags={['#hastag', 'tag']}
            >
              <a>
                <i className="fab fa-facebook-f"></i>
              </a>
            </FacebookShareButton>
          </li>
          <li className="list-inline-item">
            <TwitterShareButton url={url} title={message}>
              <a>
                <i className="fab fa-twitter"></i>
              </a>
            </TwitterShareButton>
          </li>
          <li className="list-inline-item">
            <LinkedinShareButton url={url} summary={message} >
              <a>
                <i className="fab fa-linkedin-in"></i>
              </a>
            </LinkedinShareButton>
          </li>
          <li className="list-inline-item">
            <WhatsappShareButton url={url} title={message}>
              <a>
                <i className="fab fa-whatsapp"></i>
              </a>
            </WhatsappShareButton>
          </li>
          <li className="list-inline-item">
            <TelegramShareButton url={url} title={message}>
              <a>
                <i className="fab fa-telegram-plane"></i>
              </a>
            </TelegramShareButton>
          </li>
        </ul>
      </div>
    </>
  );
}
