import React from "react";
import moment from "moment";
import 'moment/locale/id'
import LiveListItem from "./LiveListItem";

// const data = [{ id: 1 }, { id: 2 }];

export default function LiveList({ setJoinZoom, data }) {
  // console.log("live event: ", data);
  const { id, date_start, date_end, detail } = data;
  const startDate = moment(date_start).locale("id").format("DD MMMM YYYY");
  const endDate = moment(date_end).locale("id").format("DD MMMM YYYY");

  return (
    <div className="course-element">
      <div className="course-element">
        <h5>Jadwal Live ({detail.length})</h5>
        <div className="course-item">
          <div className="course-content show">
            <h6 className="mb-3"><i className="las la-calendar mr-1" />{startDate} - {endDate}</h6>
            <ul className="list-unstyled list-live-class">
              {detail.length !== 0 ?
                detail.map((zoom, index) => (
                  <LiveListItem key={index} data={zoom} />
                ))
                :
                <li className="live-class-item">
                  <span className="badge badge-secondary">Belum ada jadwal</span>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
