import React,{useContext, useEffect, useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import CourseSearch from './CourseSearch';
import CoursePrice from './CoursePrice';
import PopularCourse from './PopularCourse';
import CourseCategory from './CourseCategory';
import { colors } from './../../../components/common/element/elements.js';
import {ContextProvider} from '../../../context/BaseContext'


export default function CourseSidebar({ setGridToList,listCategories,statusPaid,statusFree,setSearchString, setDefaultSearch }) {
    var listing=[]
    const context= useContext(ContextProvider)
    const [categories,setCategories]=useState([])
    const [free,setFree]=useState(0)
    const [paid,setPaid]=useState(0)
    // const [statusFree,setStatusFree]=useState(false)
    // const [statusPaid,setStatusPaid]=useState(false)
    async function getKategori(){
        var ca=await localStorage.getItem('v2category')
        if(ca){
            let res=JSON.parse(ca)
            setCategories(res.data.data)
            setFree(res.data.free)
            setPaid(res.data.paid)
        }
        let response=await context.getRequest('v2/category')
        if(response){
            // console.log(response)
            setCategories(response.data.data)
            setFree(response.data.free)
            setPaid(response.data.paid)
            await localStorage.setItem('v2category',JSON.stringify(response))
        }
    }
    useEffect(()=>{
        getKategori()
    },[])
    return (
        <div className="course-sidebar">
            <Row>
                <Col md="12">
                    <div className="text-center" style={{
                        border: `1px solid ${colors.border1}`,
                        padding: `15px 20px 20px`,
                        borderRadius: '5px',
                        marginBottom: '30px'
                    }}>
                        <button onClick={()=> setGridToList(false)} style={{ background: colors.gr_bg }} className="btn text-white btn-icon mr-2"><i className="fas fa-th-large"></i></button>
                        <button onClick={()=> setGridToList(true)} style={{ background: colors.gr_bg }} className="btn text-white btn-icon"><i className="fas fa-th-list"></i></button>
                    </div>
                </Col>
                <Col md="12">
                    <CourseSearch setSearchString={setSearchString} setDefaultSearch={setDefaultSearch}/>
                </Col>
                <Col md="12">
                    <CourseCategory categories={categories} listCategories={listCategories.bind(this)}/>
                </Col>
                {/* <Col md="12">
                    <CoursePrice free={free} paid={paid} setStatusPaid={statusPaid.bind(this)} setStatusFree={statusFree.bind(this)}/>
                </Col> */}
                {/* <Col md="12">
                    <PopularCourse />
                </Col> */}
                {/* <Col md="12">
                    <CourseTag />
                </Col> */}
            </Row>
        </div>
    )
}


