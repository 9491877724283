import React, { useState } from "react";

export default function Materi({ data, setTypeContent, setUrlContent,videoToBumper,context }) {
  const RenderSection = ({ section }) => {
    const [showOrhideSection, setShowOrhideSection] = useState(true);

    return (
      <div className="course-item">
        <button
          onClick={() => setShowOrhideSection(showOrhideSection ? false : true)}
          className={
            showOrhideSection ? "course-button active" : "course-button"
          }
        >
          {section.title} {/*<span>xx Lectures - xx Min</span> */}
        </button>
        <div
          className={
            showOrhideSection ? "course-content show" : "course-content"
          }
        >
          <ul className="list-unstyled">
            {section.contents.length !== 0 &&
              section.contents.map((item, idx) => (
                <RenderContent content={item} index={idx} key={idx} />
              ))}
          </ul>
        </div>
      </div>
    );
  };

  const RenderContent = ({ content, index }) => {
    const [showPreview, setshowPreview] = useState(false);

    async function getTempUrl(url) {
      let response = await context.getRequestWithoutHost(url);
      if (response) {
        // console.log(response.data.url);
        setUrlContent(response.data.url)
      }
    }

    return (
      <li
        onMouseEnter={() => setshowPreview(true)}
        onMouseLeave={() => setshowPreview(false)}
        onClick={() => {
          if (content.is_preview) {
            if(content.content_type_id == 3){
              let url = content.contents_url.split("/")
              // console.log("Before: ", content.contents_url);
              // console.log("After: ", `${url[0]}//${url[2]}/preview-pdf/${url[4]}`);
              getTempUrl(`${url[0]}//${url[2]}/preview-pdf/${url[4]}`)
            }else{
              let url = content.contents_url.split("/")
              // console.log("Before: ",content.contents_url);
              // console.log("After: ",`${url[0]}//${url[2]}/preview-streaming-video/${url[4]}`);
              setUrlContent(`${url[0]}//${url[2]}/preview-streaming-video/${url[4]}`);
            }
            setTypeContent(content.content_type_id);
            videoToBumper(content.content_type_id)
            window.scrollTo({
              top: 450,
              behavior: "smooth",
            });
          }
        }}
      >
        <span className="play-icon">
          {content.content_type_id === 1 && (
            <i className="lar la-file-video"></i>
          )}
          {content.content_type_id === 2 && (
            <i className="las la-file-invoice"></i>
          )}
          {content.content_type_id === 3 && <i className="lar la-file-pdf"></i>}
          {/* Lecture: {index + 1} */}
        </span>
        <span className="lecture-title pointer">{content.title}</span>
        <span className="lecture-duration">{content.durasi} Menit</span>
        {showPreview && (
          <button
            disabled={!content.is_preview}
            style={{ width: "30px", height: "30px" }}
            className="btn btn-icon btn-light ml-3"
            title={
              content.is_preview
                ? "Lihat konten materi"
                : "Maaf konten tidak dapat dilihat"
            }
          >
            {content.is_preview ? (
              <i className="fa fa-eye"></i>
            ) : (
              <i className="fa fa-eye-slash"></i>
            )}
          </button>
        )}
      </li>
    );
  };

  return (
    <>
      {/* <div className="course-curriculum">
        <h5>Course Curriculum</h5>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quae impedit
          eligendi perspiciatis animi maxime ab minus corporis omnis similique
          excepturi, quidem facere quisquam aperiam neque dolorem saepe.
          Laboriosam, quam aliquam. Optio earum accusantium quam eius
          dignissimos quaerat voluptatem excepturi aliquid dolor ducimus. Illo
          porro maiores fuga dignissimos temporibus odio nulla nobis nemo.
        </p>
      </div> */}
      <div className="course-element">
        <h5>Materi</h5>
        {data.length !== 0 &&
          data.map((item, idx) => <RenderSection section={item} key={idx} />)}
      </div>
    </>
  );
}
