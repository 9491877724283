import React, { useState,useContext,useEffect} from 'react';
import Datas from '../../data/faq/faq.json';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/Footer';
import MetaTags from "react-meta-tags";
// import { Styles } from './styles/faq.js';
import { Styles } from "./styles/tabBox.js";
import { ContextProvider } from '../../context/BaseContext';
function Faq ({ match, location }) {
    const context = useContext(ContextProvider)
    const [data,setData]=useState([])
    const [active,setActive]=useState(0)
    const [tags,setTags]=useState(false)
    const [title,setTitle]=useState('')
    async function getFaq(){
        let response = await context.getRequest('faq')
        if(response){
            console.log(response.data)
            setData(response.data.data)
            if(response.data.data.length>0){
                if(match.params['id']!==undefined){
                    setActive(match.params.id)
                }else{
                    setActive(response.data.data[0].id)
                }
                
            }
        }
    }
    useEffect(()=>{
        getFaq()
        if(match.params['id']!==undefined){
            var title=match.params.slug.replaceAll('-',' ')
            setTitle(title)
            setTags(true)
        }
        // console.log("PARAMS :",match.params)
    },[])
    return (
        <Styles>
            {tags?(<MetaTags>
                <title>{title} | Wakool Academy</title>
                <link
                    rel="canonical"
                    href={"https://wakool.academy/faq-detail/" + match.params.id}
                />
                <meta name="title" content={title} />
                <meta itemprop="name" content={title}/>
                <meta name="description" content={title}/>
                <meta property="og:type" data-react-helmet="true" content="website" />
                <meta
                    property="og:url"
                    data-react-helmet="true"
                    content={"https://wakool.academy/classes/" + match.params.id+'/'+match.params.slug}
                />
                <meta itemprop="name" content={title}/>
                <meta
                    property="og:title"
                    data-react-helmet="true"
                    content={title}
                />
                <meta
                    property="og:description"
                    data-react-helmet="true"
                    content={title}
                />
                <meta
                    property="twitter:url"
                    data-react-helmet="true"
                    content={"https://wakool.academy/classes/" + match.params.id+'/'+match.params.slug}
                />
                <meta
                    property="twitter:description"
                    data-react-helmet="true"
                    content={title}
                />
                <meta
                    property="twitter:title"
                    data-react-helmet="true"
                    content={title}
                />
            </MetaTags>):null}
            {/* Main Wrapper */}
            <div className="main-wrapper faq-page">

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox
                    title="FAQ"
                    image={`${process.env.PUBLIC_URL}/assets/illustration9.png`}
                />

                {/* Faq Area */}
                <section className="tab-section">
                <Container>
                    <Tab.Container defaultActiveKey="why">
                        <Row>
                            <Col lg="4" md="4">
                                <Nav className="flex-column">
                                    {data.map((item,index)=>{
                                        return(
                                            <Nav.Item>
                                                <Nav.Link href={'/faq-detail/'+item.id+'/'+item.slug} eventKey={"tab-"+item.id} className={item.id==active?'active':null}><i className="las la-arrow-right"></i> {item.name}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })}
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Why Edulyn</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Our Mission</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Our Vision</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="ranking"><i className="las la-arrow-right"></i> Our Ranking</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="research"><i className="las la-arrow-right"></i> Our Research</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col lg="8" md="8">
                                <Tab.Content>
                                    {data.map((item,index)=>{
                                        if(item.id==active){
                                            return(
                                                <Tab.Pane eventKey={"tab-"+item.id} className={item.id==active?'active show':null}>
                                                    <h4 className="tab-title">{item.name}</h4>
                                                    <p className="tab-desc" dangerouslySetInnerHTML={{ __html:item.content}}/>
                                                    <ul className="list-unstyled check-list">
                                                    {item.sub.map((item1,index1)=>{
                                                        return(
                                                            <li><a href={'/faq-detail/'+item1.id+'/'+item1.slug}>{item1.name}</a></li>
                                                        )
                                                    })}
                                                    </ul>
                                                    
                                                </Tab.Pane>
                                            )
                                        }
                                        return null
                                    })}
                                    {/* <Tab.Pane eventKey="why">
                                        <h4 className="tab-title">Why Edulyn</h4>
                                        <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                        <ul className="list-unstyled check-list">
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="mission">
                                        <h4 className="tab-title">Our Mission</h4>
                                        <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                        <ul className="list-unstyled check-list">
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="vision">
                                        <h4 className="tab-title">Our Vision</h4>
                                        <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                        <ul className="list-unstyled check-list">
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="ranking">
                                        <h4 className="tab-title">Our Ranking</h4>
                                        <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                        <ul className="list-unstyled check-list">
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                        </ul>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="research">
                                        <h4 className="tab-title">Our Research</h4>
                                        <p className="tab-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere a nisi fuga rem quas molestias, eveniet minima molestiae. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea, recusandae? Assumenda, error. Quam dicta iusto saepe. Odit minus voluptas, fuga ipsum quia debitis totam, tempore laudantium quasi dicta dolorem deleniti.</p>
                                        <ul className="list-unstyled check-list">
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                            <li><i className="fa fa-check"></i>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatum amet quo eius saepe et quis necessitatibus hic natus facere.</li>
                                        </ul>
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>

                {/* Footer 2 */}
                {/* <FooterTwo /> */}

            </div>
        </Styles>
    )
    
}

export default Faq