import moment from "moment";
import React, { useState,useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { currencyFormat, RenderStatusLiveKelas } from "../../../util/Functions";

export default function LiveKelas({ liveKelas, context, getDataDetailKelas }) {
  const { id } = useParams();
  const [chooseLive, setChooseLive] = useState("");
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [listDetail, setlistDetail] = useState([]);
  const [voucher, setVoucher] = useState("");
  const [voucherSuccess, setVoucherSuccess] = useState("");
  const [voucherFailed, setVoucherFailed] = useState("");
  const [priceBeforeDisc, setPriceBeforeDisc] = useState("");
  const [priceAfterDisc, setPriceAfterDisc] = useState("");
  const [reloadVoucher, setReloadVoucher] = useState(false);

  async function hendleBuyLiveClass(id, priceAfterDisc) {
    setLoading(true);
    let form = new FormData();
    form.append("class_id", id);
    form.append("tipe", "le");
    if (priceAfterDisc) {
      form.append("vouchers", priceAfterDisc.voucher);
      form.append(
        "price_desc",
        JSON.stringify(priceAfterDisc.harga_setelah_diskon)
      );
    }

    let response = await context.postRequest(
      "transaction-snap-token",
      form,
      "form",
      true
    );
    // console.log("RESPONSE ", response);
    if (response != null) {
      setLoading(false);
      if (response.data.hasOwnProperty("redirect_url")) {
        window.snap.pay(response.data.token, {
          onSuccess: function (result) {
            console.log("success");
            console.log(result);
            getDataDetailKelas();
          },
          onPending: function (result) {
            console.log("pending");
            console.log(result);
            getDataDetailKelas();
          },
          onError: function (result) {
            console.log("error");
            console.log(result);
          },
          onClose: function () {
            console.log("close");
          },
        });
      } else {
        // console.log('tidak ada');
      }
      // console.log(response.data)
    }
  }

  async function transactionFree(id, priceAfterDisc) {
    setLoading(true);
    let form = new FormData();
    form.append("live_event_id", id);
    if (priceAfterDisc) {
      form.append("vouchers", priceAfterDisc.voucher);
    }

    let response = await context.postRequest("transaction-free-le", form);
    setLoading(false);
    // console.log(response);
    if (response) {
      context.setNotification(
        "success",
        "Kelas berhasil dibeli, segera cek kelasku untuk melihat kelasmu"
      );
      getDataDetailKelas();
    } else {
      context.setNotification(
        "danger",
        "Opss, terjadi kesalahan, coba beberapa saat lagi"
      );
      getDataDetailKelas();
    }
  }

  async function cekVoucher(voucher, id, class_live_id) {
    setReloadVoucher(true);
    try {
      let form = new FormData();
      form.append("vouchers", voucher);
      form.append("class_id", id);
      form.append("type_class", "2");
      form.append("class_live_id", class_live_id);

      let response = await context.postRequest("check-vouchers", form);
      //console.log(response);
      if (response) {
        if (response.data) {
          setVoucherFailed("");
          setPriceAfterDisc(response.data[0]);
          setVoucherSuccess(
            `Anda mendapat diskon dari kode voucher ${response.data[0].voucher}`
          );
          setReloadVoucher(false);
        } else {
          setVoucherSuccess("");
          setVoucherFailed(response.message);
          setReloadVoucher(false);
        }
      }
    } catch (error) {
      setReloadVoucher(false);
      console.log("ERROR", error);
    }
  }
  useEffect(()=>{
    setTimeout(()=>{
      console.log("LIVE KELAS : ",liveKelas)
      if(liveKelas.length>0){
        setPriceBeforeDisc(liveKelas[0]);
        setChooseLive(liveKelas[0].id);
        // alert('a')
      }
    },1000)
    // alert('ab')
  },[liveKelas])
  return (
    <>
      {liveKelas.length > 0 ? (
        <div className="course-details-feature">
          <h5 className="title">Live Kelas</h5>
          <div className="mb-2 mt-2">
            <div className="text-center mb-3">
              {/* <h6>Harga : Rp.{item.price}</h6> */}
            </div>
            <ul className="list-unstyled feature-list mb-0">
              {liveKelas?.map((item, i) => {
                // if(i==0){
                //   setPriceBeforeDisc(item);
                //   setChooseLive(item.id);
                // }
                return (
                  <div key={i}>
                    <li className="row pl-1 pr-1">
                      {item.payment.length > 0 ? null : (
                        <input
                          className="col-1"
                          style={{
                            width: "17px",
                            height: "17px",
                            cursor: "pointer",
                            margin: "auto",
                          }}
                          onClick={(e) => {
                            console.log("Checklist : ",e.target.value);
                            setPriceBeforeDisc(item);
                            setChooseLive(e.target.value);
                          }}
                          defaultChecked={i==0?true:false}
                          // checked={i==0?true:false}
                          type="radio"
                          name={`live-class`}
                          id={item.id}
                          defaultValue={item.id}
                        />
                      )}
                      <div
                        className={
                          item.payment.length > 0
                            ? "col-12 pr-1 pl-1"
                            : "col-11 pr-1 pl-1"
                        }
                      >
                        {item.date_start != item.date_end ? (
                          <>
                            <p>
                              <i className="las la-calendar" />
                              Tanggal :{" "}
                              <span>
                                {moment(item.date_start).format(
                                  "dddd, D MMM YYYY"
                                )}{" "}
                                -
                              </span>
                            </p>
                            <p style={{ display: "flow-root" }}>
                              <span>
                                {moment(item.date_end).format(
                                  "dddd, D MMM YYYY"
                                )}
                              </span>
                            </p>
                          </>
                        ) : (
                          <p>
                            <i className="las la-calendar" />
                            Tanggal :{" "}
                            <span>
                              {moment(item.date_start).format(
                                "dddd, D MMM YYYY"
                              )}
                            </span>
                          </p>
                        )}
                        <p>
                          <i className="las la-clock" />
                          Waktu :{" "}
                          <span>
                            {moment(item.time_start, "HH:mm:ss").format(
                              "HH:mm"
                            )}{" "}
                            -{" "}
                            {moment(item.time_end, "HH:mm:ss").format("HH:mm")}
                          </span>
                        </p>
                        <p>
                          <i className="las la-money-bill" />
                          Harga : <span>Rp {currencyFormat(item.price)}</span>
                        </p>
                        {parseInt(item.min_user) > 0 && (
                          <p>
                            <i className="las la-user-minus"></i>
                            Min Pendaftar : <span>{item.min_user} Orang</span>
                          </p>
                        )}
                        {item.payment_count > 0 && (
                          <p>
                            <i className="las la-users"></i>
                            Pendaftar : <span>{item.payment_count} Orang</span>
                          </p>
                        )}
                        {item.status == 11 && (
                          <p>
                            <i className="las la-chalkboard-teacher"></i>
                            Status :{" "}
                            <RenderStatusLiveKelas status={item.status} />
                          </p>
                        )}
                      </div>
                    </li>
                    {item.payment.length > 0 ? (
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/learn-live-class/" +
                          item.id +
                          "/" +
                          item.class_id
                        }
                        className="btn btn-danger col-12 mb-2"
                      >
                        <i className="las la-eye mr-1"></i>Lihat Kelas
                      </Link>
                    ) : (
                      <button
                        onClick={() => {
                          setShow(true);
                          // console.log(item.webinar);
                          setlistDetail(item.webinar);
                        }}
                        className="btn btn-light text-secondary col-12 mb-2"
                      >
                        <i className="las la-search mr-1"></i>Detail
                      </button>
                    )}
                  </div>
                );
              })}
              <hr />
              {chooseLive && (
                <>
                  <h5 className="title">Voucher</h5>
                  <div className="voucher-disc">
                    <form id="form-voucher-live" className="form">
                      <p className="form-control">
                        <div className="row">
                          <div className="col-sm-7 pr-0 pt-2">
                            <input
                              disabled={voucherSuccess}
                              value={voucher}
                              onChange={(v) =>
                                setVoucher(v.currentTarget.value)
                              }
                              placeholder="Masukan Voucher"
                            />
                          </div>
                          <div className="col-sm-5 pt-2">
                            <button
                              disabled={voucher == ""}
                              onClick={() => {
                                if (context.login) {
                                  if (voucherSuccess) {
                                    setPriceAfterDisc("");
                                    setVoucherSuccess("");
                                    setVoucherFailed("");
                                    setVoucher("");
                                  } else {
                                    cekVoucher(voucher, id, chooseLive);
                                  }
                                } else {
                                  context.setNotification(
                                    "danger",
                                    "Anda belum login, silahkan login terlebih dahulu"
                                  );
                                }
                              }}
                              type="button"
                              className="btn-voucher"
                            >
                              {voucherSuccess ? "Batal" : "Cek"}{" "}
                              {reloadVoucher ? (
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  variant="light"
                                />
                              ) : null}
                            </button>
                          </div>
                        </div>
                      </p>
                      {voucherSuccess && (
                        <>
                          <div className="alert alert-success" role="alert">
                            <p>
                              <strong>Selamat !</strong> {voucherSuccess}
                            </p>
                          </div>
                          <div className="row text-left justify-content-center">
                            <span
                              style={{
                                backgroundColor: "#f8d7da",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 10,
                                borderRadius: 5,
                                padding: 5,
                                color: "#721c24",
                                fontSize: 10,
                              }}
                            >
                              {priceAfterDisc.harga_diskon}%
                            </span>
                            <h5 className="price">
                              Rp.{" "}
                              {currencyFormat(
                                priceAfterDisc.harga_setelah_diskon
                              )}
                            </h5>
                            <h6
                              className="price"
                              style={{
                                textDecorationLine: "line-through",
                                fontSize: 12,
                                textVerticalAlign: "bottom",
                                color: "grey",
                              }}
                            >
                              Rp. {currencyFormat(priceBeforeDisc.price)}
                            </h6>
                          </div>
                        </>
                      )}
                      {voucherFailed && (
                        <div className="alert alert-danger" role="alert">
                          <strong>Maaf, </strong> {voucherFailed}
                        </div>
                      )}
                    </form>
                  </div>
                </>
              )}
              <p className="text-center">
                <button
                  style={{
                    height: "30px",
                    fontSize: "13px",
                  }}
                  className="btn enroll-btn p-1"
                  disabled={!chooseLive || loading}
                  onClick={() => {
                    if (context.login) {
                      if (voucherSuccess) {
                        if (priceAfterDisc.harga_setelah_diskon == 0) {
                          transactionFree(chooseLive, priceAfterDisc);
                        } else {
                          hendleBuyLiveClass(chooseLive, priceAfterDisc);
                        }
                      } else {
                        // console.log(
                        //   "PRICEE",
                        //   {price: currencyFormat(priceBeforeDisc.price).toString()}
                        // );
                        if(currencyFormat(priceBeforeDisc.price).toString() == "0"){
                          transactionFree(chooseLive, null);
                        }else{
                          hendleBuyLiveClass(chooseLive, null);
                        }
                      }
                    } else {
                      context.setNotification(
                        "danger",
                        "Anda belum login, silahkan login terlebih dahulu"
                      );
                    }
                  }}
                >
                  {loading && (
                    <Spinner
                      className="mr-1"
                      size="sm"
                      animation="border"
                      variant="light"
                    />
                  )}
                  Daftar Live Kelas
                </button>
              </p>
            </ul>
            <hr />
          </div>
          <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Detail Live</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {listDetail.map((val, idx) => {
                return (
                  <div key={idx}>
                    <table>
                      <tbody>
                        <tr>
                          <td className="p-1">Topic</td>
                          <td className="p-1">:</td>
                          <td className="p-1">{val.topic}</td>
                        </tr>
                        <tr>
                          <td className="p-1">Agenda</td>
                          <td className="p-1">:</td>
                          <td className="p-1">{val.agenda}</td>
                        </tr>
                        <tr>
                          <td className="p-1">Tanggal</td>
                          <td className="p-1">:</td>
                          <td className="p-1">
                            {moment(val.start_time).format(
                              "dddd, DD MMMM YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1">Jam</td>
                          <td className="p-1">:</td>
                          <td className="p-1">
                            {moment(val.start_time).format("HH:mm")} s/d{" "}
                            {moment(val.start_time)
                              .add(val.duration, "m")
                              .format("HH:mm")}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1">Status</td>
                          <td className="p-1">:</td>
                          <td className="p-1">
                            <RenderStatusJadwal val={val} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                );
              })}
            </Modal.Body>
          </Modal>
        </div>
      ) : null}
    </>
  );

  function RenderStatusJadwal({ val }) {
    var e = moment(val.start_time, "YYYY-MM-DD hh:mm:ss")
      .add(val.duration, "minutes")
      .unix();
    var s = moment(val.start_time, "YYYY-MM-DD hh:mm:ss")
      .subtract(30, "minutes")
      .unix();
    var n = moment().unix();

    if (n >= s && n <= e) {
      return (
        <span className="badge badge-success mr-1">Sedang berlangsung</span>
      );
    } else if (n <= s) {
      return <span className="badge badge-secondary mr-1">Belum dimulai</span>;
    } else if (n >= e) {
      return <span className="badge badge-danger mr-1">Berakhir</span>;
    } else {
      return "-";
    }
  }
}
