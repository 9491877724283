import React, { Component, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import CourseSidebar from "./components/CourseSidebar";
import CourseItemGrid from "./components/CourseItemsGrid";
import CourseItemList from "./components/CourseItemsList";
import Footer from "../../components/Footer";
import MetaTags from "react-meta-tags";
import { Styles } from "./styles/course.js";
import { ContextProvider } from "../../context/BaseContext";
import { encryptString, decryptString } from "../../util/Functions";
const desc=`Pelajari berbagai skill dan pengetahuan baru menggunakan metode belajar online, webminar ataupun offline untuk mengembangkan karir atau meningkatkan bisnis Anda bersama Wakool Academy. Dapatkan sertifikat setelah menyelesaikan kursusnya.`
export default function Classes({ match, location }) {
  const context = useContext(ContextProvider);
  const [gridToList, setGridToList] = useState(false);
  const [wishlist, setWishlist] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [defaultSearch, setDefaultSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [categories, setCategories] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [founded, setFounded] = useState(true);
  const [first, setFirst] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    bef: 0,
    aft: 0,
  });
  // const [listCategories,setListCategories]=useState([])
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  var listCategories = (e) => {
    var unique = e.filter(onlyUnique);
    // setCategories(unique.join(','))
    var j = unique.join(",");
    setCategories(j);
    setOffset(0);

    // console.log("RESPONSE : ", unique);
  };
  var statusFree = (e) => {
    var ll = status;
    if (e) {
      ll.push("free");
      setStatus(ll);
    } else {
      var ind = ll.indexOf("free");
      if (ind > -1) {
        ll.splice(ind, 1);
        setStatus(ll);
      }
    }
    // console.log(ll);
  };
  var statusPaid = (e) => {
    var ll = status;
    if (e) {
      ll.push("paid");
      setStatus(ll);
    } else {
      var ll = status;
      var ind = ll.indexOf("paid");
      if (ind > -1) {
        ll.splice(ind, 1);
        setStatus(ll);
      }
    }
    // console.log(ll.join(","));
  };
  function setSearchString(e) {
    setSearch(e);
    // console.log(e);
  }
  // var first=0;
  async function getData() {
    // let c=categories.join(',')
    // alert(match.params.categoryId)
    // console.log("LOCATION : ", location.search);

    setRefresh(true);
    try {
      var cc = categories;
      if (
        first == 0 &&
        match.params.categoryId != "" &&
        match.params.categoryId != 0
      ) {
        var spl = match.params.categoryId.split("-");
        var a = [];
        a.push(spl[0]);
        cc = a.join(",");
        setFirst(1);
      }

      var src = search;
      var querySearch = new URLSearchParams(location.search).get("search");
      if (first == 0 && querySearch != null) {
        src = querySearch;
        setDefaultSearch(src);
        setFirst(1);
        // alert(querySearch)
      }
      // alert(`v2/semua-kelas?search=${src}&categories=${cc}&price=&limit=${limit}&offset=${offset}`)
      // console.log("URL : ", `v2/semua-kelas?search=${search}&categories=${categories}&price=&limit=${limit}&offset=${offset}`)
      // console.log({ src, cc });
      let response = await context.getRequest(
        `v2/semua-kelas?search=${src ? src : ""}&categories=${
          cc == "0" ? "" : cc
        }&price=&limit=${limit}&offset=${offset}`
      );
      if (response) {
        setData(response.data.data);
        setTotal(response.data.total_kelas);
        setFounded(response.data.founded == 1 ? true : false);
        if (response.data.founded == 0) {
          context.setNotification(
            "danger",
            "Data tidak ditemukan, tp kamu masih bisa lihat kelas yang lain loh!"
          );
        }
        var totalPage = Math.ceil(response.data.total_kelas / limit);
        var currentPage = offset / limit + 1;
        var bef = currentPage - 2 + 1;
        if (bef < 0) {
          bef = 0;
        }
        // if(bef<0)
        var aft = currentPage + 2;
        if (aft >= totalPage) {
          aft = totalPage;
        }
        setPagination({
          currentPage,
          bef,
          aft,
        });
        document.getElementById("card-home").scrollIntoView({
          behavior: "smooth",
        });
        setRefresh(false);
        setError(false);
      }
    } catch (error) {
      setRefresh(false);
      setError(true);
    }
    setRefresh(false);
  }

  function changePage(page) {
    var p = (page - 1) * limit;
    setOffset(p);
    // console.log("CHANGE PAGE ", p);
  }

  function getParamsId() {
    if (match.params.categoryId != "") {
      var spl = match.params.categoryId.split("-");
      setCategories(spl[0] == "0" ? "" : spl[0]);
    }
  }

  function getQuerySearch(ss) {
    var querySearch = new URLSearchParams(ss).get("search");
    setSearchString(querySearch);
    setDefaultSearch(querySearch);
  }

  async function ende() {
    let u = await localStorage.getItem("token");
    // let ec=await encryptString(u)
    console.log("ENCRYPT ", u);
    let de = await decryptString(u);
    console.log("DECRYPT ", de);
  }

  useEffect(() => {
    // ende()
    getParamsId();
  }, [match.params.categoryId]);

  useEffect(() => {
    getQuerySearch(location.search);
  }, [location.search]);

  useEffect(() => {
    // getParamsId()
    getData();
  }, [categories, search, offset, categories, status]);
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  function GenerateMetatags(){
    // console.log(first,match.params.categoryId)
    if (
      match.params.categoryId != "" &&
      match.params.categoryId != 0
    ) {
      var spl = match.params.categoryId.split("-");
        var a = [];
        a.push(spl[0]);
        var strcat='';
        for(var i=1;i<spl.length;i++){
          strcat=strcat+" "+capitalize(spl[i])
        }
        // cc = a.join(",");
        console.log("CC :",a)
      return (
        <MetaTags>
          <title>{strcat} | Wakool Academy</title>
          <link
            rel="canonical"
            href={"https://wakool.academy/classes/" + match.params.categoryId}
          />
          <meta name="title" content={strcat} />
          <meta itemprop="name" content={strcat}/>
          <meta name="description" content={desc}/>
          <meta property="og:type" data-react-helmet="true" content="website" />
          <meta
            property="og:url"
            data-react-helmet="true"
            content={"https://wakool.academy/classes/" + match.params.categoryId}
          />
          <meta itemprop="name" content={{strcat}}/>
          <meta
            property="og:title"
            data-react-helmet="true"
            content={strcat}
          />
          <meta
            property="og:description"
            data-react-helmet="true"
            content={desc}
          />
          <meta
            property="twitter:url"
            data-react-helmet="true"
            content={"https://wakool.academy/classes/" + match.params.categoryId}
          />
          <meta
            property="twitter:description"
            data-react-helmet="true"
            content={desc}
          />
          <meta
            property="twitter:title"
            data-react-helmet="true"
            content={strcat}
          />
        </MetaTags>
      )
    }else{
      return(
        <MetaTags>
          <title>Semua Kategori | Wakool Academy</title>
          <link
            rel="canonical"
            href={"https://wakool.academy/classes/0"}
          />
          <meta name="title" content={'Semua Katagori'} />
          <meta itemprop="name" content={'Semua Katagori'}/>
        </MetaTags>
      )
    }
    return null
  }
  return (
    <div className="main-wrapper course-page" style={{ position: "relative" }}>
      <GenerateMetatags/>
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Kelas"
        image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}
      />

      <Styles>
        {/* Course Grid */}
        <section
          className={gridToList ? "course-list-area" : "course-grid-area"}
          style={{ paddingBottom: 150 }}
        >
          <Container>
            <Row>
              <Col lg="3" md="4" sm="5">
                <CourseSidebar
                  setDefaultSearch={defaultSearch}
                  setSearchString={setSearchString}
                  setGridToList={setGridToList}
                  listCategories={listCategories}
                  statusFree={statusFree.bind(this)}
                  statusPaid={statusPaid.bind(this)}
                />
              </Col>
              <Col lg="9" md="8" sm="7">
                <div
                  id="card-home"
                  className={gridToList ? "course-items2" : "course-items"}
                >
                  <Row>
                    {gridToList ? (
                      <CourseItemList
                        reload={getData.bind(this)}
                        error={error}
                        refresh={refresh}
                        founded={founded}
                        data={data}
                        pagination={pagination}
                        changePage={changePage.bind(this)}
                      />
                    ) : (
                      <CourseItemGrid
                        reload={getData.bind(this)}
                        error={error}
                        refresh={refresh}
                        founded={founded}
                        data={data}
                        pagination={pagination}
                        changePage={changePage.bind(this)}
                      />
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <Footer />
    </div>
  );
}
