import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Spinner,
  Modal,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import HeaderTwo from "../../components/HeaderTwo";
import Footer from "../../components/Footer";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/course.js";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { ContextProvider } from "../../context/BaseContext";
import RenderLevelToString from "../../components/RenderLevelToString";
import Deskripsi from "./components/Deskripsi";
import Materi from "./components/Materi";
import Instruktur from "./components/Instruktur";
import Review from "./components/Review";
import LiveKelas from "./components/LiveKelas";
import { LoadingBall } from "../../components/LoadingBall";
import {
  getRating,
  currencyFormat,
  signatureKey,
  NewPrices,
  convertToSlug,
  paramsMentor
} from "../../util/Functions";
import { Document, Page, pdfjs } from "react-pdf";
import MetaTags from "react-meta-tags";
import { encode, decode, Base64 } from "js-base64";
import ReactGA from "../../config/GoogleAnalytic.config";
import Swal from "sweetalert2";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CourseDetails() {
  const history = useHistory();
  const { id } = useParams();
  const context = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState([]);
  const [rating, setRating] = useState([]);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const [showVoucher, setShowVoucher] = useState(false);
  const [reloadVoucher, setReloadVoucher] = useState(false);
  const [voc, setVoc] = useState("");
  const [voucher, setVoucher] = useState("");
  const [voucherSuccess, setVoucherSuccess] = useState(false);
  const [voucherFailed, setVoucherFailed] = useState(false);
  const [responFailed, setResponFailed] = useState("");
  const [price, setPrice] = useState(0);
  const [priceDiskon, setPriceDiskon] = useState(0);
  const [persen, setPersen] = useState(0);
  const [kodeTransaksi, setKodeTransaksi] = useState("");

  const [loadingToBuy, setLoadingToBuy] = useState(false);
  const [listPromo, setListPromo] = useState([]);
  const [usePromo, setUsePromo] = useState("");
  const [objRealPrice, setObjRealPrice] = useState("");

  const [detailKelas, setDetailKelas] = useState({});
  const [categoryClass, setCategoryClass] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [mentorData, setMentorData] = useState([]);
  const [instruktur, setInstruktur] = useState([]);
  const [errorAvatar, setErrorAvatar] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);
  const [isPrakerja, setIsPrakerja] = useState(false)

  const [liveKelas, setLiveKelas] = useState([]);

  const [favorit, setfavorit] = useState(false);

  const bumper = context.bumper;
  // Preview Content
  const [typeContent, setTypeContent] = useState(0);
  const [urlContent, setUrlContent] = useState("");
  const [urlSource, setUrlSource] = useState(bumper);
  const [snapToken, setSnapToken] = useState("");
  const [signatureValue, setSignatureValue] = useState("");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [copied, setcopied] = useState(false);

  const message = `Hai, saya ingin membagikan kursus ${detailKelas?.title}`;

  function returnLink(id, slug, type) {
    const apilink = "https://share.wakool.academy/";
    let links = apilink + `${id}/${slug}?media=${type}`;
    return links;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(pageNumber);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  async function getListDiscount() {
    let form = new FormData();
    form.append("class_id", id);
    let response = await context.postRequest(`price-list-class`, form, "form");
    console.log("NEW PROMO", response);
    if (response) {
      // console.log("REAL PRICE : ",response.data.real_price);
      // console.log("USE PROMO : ",response.data.real_price);
      // console.log("REAL PRICE : ",response.data.real_price);
      setObjRealPrice(response.data.real_price);
      if (response.data.price_list_discount.length == 1) {
        setUsePromo(response.data.price_list_discount[0]);
      } else {
        setListPromo(response.data.price_list_discount);
      }
    }
  }

  async function getUrl(harga, vouchers, price_desc) {
    // console.log({ harga, vouchers, price_desc });
    if (harga.toString() == "0") {
      // console.log("HARGA NOL", kodeTransaksi);
      hargaNol(kodeTransaksi, vouchers);
    } else {
      setLoadingToBuy(true);
      let form = new FormData();
      form.append("class_id", id);
      form.append("vouchers", vouchers);
      if (price_desc) {
        form.append("price_desc", JSON.stringify(price_desc));
      }

      if (harga.toString() == objRealPrice.real_price.toString()) {
        form.append("price_desc", JSON.stringify(objRealPrice));
      }

      form.append("tipe", "paced");
      let response = await context.postRequest(
        "transaction-snap-token",
        form,
        "form"
      );
      // console.log("RESPONSE ", response);

      if (response != null) {
        setLoadingToBuy(false);
        if (response.data.hasOwnProperty("redirect_url")) {
          window.snap.pay(response.data.token, {
            onSuccess: function (result) {
              console.log("success");
              // console.log(result);
              getDataDetailKelas();
            },
            onPending: function (result) {
              console.log("pending");
              // console.log(result);
              getDataDetailKelas();
            },
            onError: function (result) {
              console.log("error");
              // console.log(result);
            },
            onClose: function () {
              console.log("close");
            },
          });
        }
      } else {
        setLoadingToBuy(false);
      }
    }
  }

  async function hargaNol(id, voc) {
    setLoadingToBuy(true);
    let form = new FormData();
    if (voc) {
      form.append("order_id", id + "-" + voc);
    } else {
      form.append("order_id", id);
    }
    form.append("price", "0");
    let response = await context.postRequest("transaction-free", form);
    // console.log(response);
    if (response) {
      context.setNotification(
        "success",
        "Kelas berhasil dibeli, segera cek kelasku untuk melihat kelasmu"
      );
      setLoadingToBuy(false);
      getDataDetailKelas();
    } else {
      context.setNotification(
        "danger",
        "Opss, terjadi kesalahan, coba beberapa saat lagi"
      );
      setLoadingToBuy(false);
    }
    getDataDetailKelas();
  }

  async function getDataDetailKelas() {
    setLoading(true);
    context
      .getRequest(`/getKelasByID/${id}`)
      .then((response) => {
        console.log("RESPONSE DETAIL KELAS : ", response.data);
        setLoading(false);
        setDetailKelas(response.data.data);
        setPrice(response.data.data.prices);
        // getUrl(response.data.data.prices,'')
        setPayment(response.data.data.payment);
        setCategoryClass(response.data.category_classes);
        setSectionData(response.data.data.sections);
        setMentorData(response.data.data.mentors);
        console.log("MENTOR : ", response.data.data.mentors)
        setInstruktur(response.data.data.instruktur);
        setfavorit(response.data.data.favorite.length == 0 ? false : true);
        setLiveKelas(response.data.data.live_kelas);
        setKodeTransaksi(response.data.kode_transaksi);
        setIsPrakerja(response.data.data.is_prakerja)
      })
      .catch((error) => {
        setLoading(false);
        console.log("ERORR", error);
      });
  }

  function totalVideo(a, b, c) {
    let total = parseInt(a) - parseInt(b) - parseInt(c);
    return total.toString();
  }

  const RenderShowPreview = ({ type, url }) => {
    if (type == 1) {
      return (
        <video
          disablePictureInPicture
          // src={url}
          src={urlSource}
          type="video/mp4"
          controls
          autoPlay={true}
          controlsList="nodownload noremoteplayback"
          onEnded={async () => {
            let response = await context.getRequestWithoutHost(url);
            if (response) {
              setUrlSource(response.data.url);
            }
            // var u = url + signatureValue;
            // // alert(url+"&signatureKey=&keyPair=")
            // setUrlSource(u);
          }}
          onContextMenu={(e) => e.preventDefault()}
        ></video>
      );
    } else if (type === 3) {
      // var u = url + signatureValue;
      // console.log({ url });
      return (
        <iframe
          title="content-pdf"
          src={`${url}#toolbar=0`}
          style={{
            border: "none",
            height: "100vh",
            width: "100%",
          }}
        />
        // <>
        //   <div className="pt-3">
        //     <button
        //       style={{ width: "30px", height: "30px" }}
        //       className="btn btn-icon btn-light mr-3"
        //       type="button "
        //       disabled={pageNumber <= 1}
        //       onClick={() => changePage(-1)}
        //     >
        //       <i className="fas fa-angle-double-left"></i>
        //     </button>
        //     <span className="pt-1">
        //       Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        //     </span>
        //     <button
        //       style={{ width: "30px", height: "30px" }}
        //       className="btn btn-icon btn-light ml-3"
        //       type="button"
        //       disabled={pageNumber >= numPages}
        //       onClick={() => changePage(1)}
        //     >
        //       <i className="fas fa-angle-double-right"></i>
        //     </button>
        //   </div>
        //   <br />
        //   <div
        //     className="RenderPdf"
        //     style={{
        //       height: "500px",
        //       overflowX: "scroll",
        //       textAlign: "-webkit-center",
        //     }}
        //     onContextMenu={(e) => e.preventDefault()}
        //   >
        //     <Document
        //       loading={<LoadingBall />}
        //       file={`${url}`}
        //       onLoadSuccess={onDocumentLoadSuccess}
        //     >
        //       <Page pageNumber={pageNumber} width={1000} />
        //     </Document>
        //   </div>
        // </>
      );
    } else {
      return (
        <img
          src={
            errorBanner
              ? process.env.PUBLIC_URL + `/assets/images/details-banner.jpg`
              : detailKelas?.img
          }
          alt="gambar-kelas"
          className="img-fluid"
          onError={() => setErrorBanner(true)}
        />
      );
    }
  };

  async function likeUnLike(id) {
    if (id != undefined) {
      let par = favorit ? "0" : "1";
      await context.getRequest("favorite/" + id + "/" + par);
    }
  }

  async function getRatings() {
    setReload(true);
    let response = await context.getRequest(
      "kelas-rating/" + id + "?page=" + page
    );
    setRating(rating.concat(response.data.data.data));
    setReload(false);
  }

  useEffect(() => {
    getRatings();
  }, [page]);

  console.log('isprakerja=============',isPrakerja)

  function reloadPage() {
    setPage(page + 1);
  }

  useEffect(() => {
    // console.log("GOOGLE GA")
    // ReactGA.pageview(window.location.pathname + window.location.search);

    getDataDetailKelas();
    getListDiscount();

    //change this to the script source you want to load, for example this is snap.js sandbox env
    // const midtransScriptUrl = "https://app.midtrans.com/snap/snap.js";
    const midtransScriptUrl = "https://app.midtrans.com/snap/snap.js";
    //change this according to your client-key
    // const myMidtransClientKey = "Mid-client-BEo9mX5sZY9dZSJd";
    const myMidtransClientKey = "Mid-client-BEo9mX5sZY9dZSJd";

    let scriptTag = document.createElement("script");
    scriptTag.src = midtransScriptUrl;
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey);

    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);
  async function videoToBumper(contentTypeBumper) {
    if (contentTypeBumper == 1 || contentTypeBumper == 3) {
      setUrlSource(bumper);
      let sig = await signatureKey();
      setSignatureValue(sig.url);
      // alert(sig.url)
    }
  }
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    var dec = decodeURIComponent(results[2].replace(/\+/g, " "));
    return Base64.decode(dec);
  }
  function Harga() {
    if(detailKelas.is_self_paced==true){
      return (
        <div className="price">
          <h6>Harga</h6>
          {voucherSuccess ? (
            <p>Rp.{currencyFormat(priceDiskon)}</p>
          ) : (
            <p>Rp.{currencyFormat(price)}</p>
          )}
        </div>
      )
    }else if(detailKelas.is_live_class==true){
      return(
        <div className="price">
          <h6>Harga Live Kelas</h6>
          <p>Rp {currencyFormat(detailKelas.live_kelas[0]?.price)}</p>
        </div>
      )
    }
    else{
      return null
    }
    
  }
  return (
    <div className="main-wrapper course-details-page">
      <MetaTags>
        <title>{detailKelas.title} | Wakool Academy</title>
        <link
          rel="canonical"
          href={"https://wakool.academy/detail-class/" + id}
        />
        <meta name="title" content={getParameterByName("title")} />
        <meta
          name="description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        <meta property="og:type" data-react-helmet="true" content="website" />
        <meta
          property="og:url"
          data-react-helmet="true"
          content={"https://wakool.academy/detail-class/" + id}
        />
        <meta
          property="og:title"
          data-react-helmet="true"
          content={getParameterByName("title")}
        />
        <meta
          property="og:description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        <meta
          property="og:image"
          data-react-helmet="true"
          content={getParameterByName("img")}
        />
        <meta
          property="twitter:card"
          data-react-helmet="true"
          content="summary_large_image"
        />
        <meta
          property="twitter:url"
          data-react-helmet="true"
          content={"https://wakool.academy/detail-class/" + id}
        />
        <meta
          property="twitter:title"
          data-react-helmet="true"
          content={getParameterByName("title")}
        />
        <meta
          property="twitter:description"
          data-react-helmet="true"
          content={getParameterByName("description")}
        />
        <meta
          property="twitter:image"
          data-react-helmet="true"
          content={getParameterByName("img")}
        />
      </MetaTags>
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox
        title="Detail Kelas"
        image={`${process.env.PUBLIC_URL}/assets/illustration13.png`}
      />

      {loading ? (
        <section
          className="course-details-area"
          style={{ marginTop: "50px", paddingBottom: 170 }}
        >
          <LoadingBall />
        </section>
      ) : (
        <Styles>
          {/* Course Details */}
          <section
            className="course-details-area"
            style={{ paddingBottom: 150 }}
          >
            <Container>
              <Row>
                <Col lg="8" md="8" sm="12">
                  <div className="course-details-top">
                    <div className="heading">
                      <h4>{detailKelas?.title}</h4>
                    </div>
                    <div className="course-top-overview">
                      <div className="d-flex overviews">
                        <div className="author">
                          <Link
                            to={`${process.env.PUBLIC_URL}/instructor-details/${mentorData?.id}/personal/${convertToSlug(mentorData?.name)}${paramsMentor(mentorData)}`}
                          >
                            <img
                              src={
                                errorAvatar
                                  ? process.env.PUBLIC_URL +
                                  `/assets/images/author.jpg`
                                  : detailKelas?.mentors?.avatar
                              }
                              alt="avatar-mentor"
                              onError={() => setErrorAvatar(true)}
                            />
                            <div className="author-name">
                              <h6>Instruktur</h6>
                              <p>{detailKelas?.mentors?.name}</p>
                            </div>
                          </Link>
                        </div>
                        <div className="category">
                          <h6>Kategori</h6>
                          <p>{categoryClass[0]?.category?.title}</p>
                        </div>
                        <div className="rating">
                          <h6>Rating</h6>
                          <ul className="list-unstyled list-inline">
                            {getRating(
                              parseFloat(
                                detailKelas.total_vote
                                  ? detailKelas.total_vote
                                  : 0
                              )
                            )}
                            <li className="list-inline-item">
                              (
                              {parseFloat(
                                detailKelas.total_vote
                                  ? detailKelas.total_vote
                                  : 0
                              )}
                              )
                            </li>
                          </ul>
                        </div>
                        {payment.length > 0 ? null : (
                          <Harga/>
                        )}
                      </div>
                    </div>
                    <div className="course-details-banner text-center">
                      <RenderShowPreview type={typeContent} url={urlContent} />
                    </div>
                    <div className="course-tab-list">
                      <Tab.Container defaultActiveKey="overview">
                        <Nav className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="overview">Deskripsi</Nav.Link>
                          </Nav.Item>
                          {detailKelas.is_self_paced ? (
                            <Nav.Item>
                              <Nav.Link eventKey="curriculum">Materi</Nav.Link>
                            </Nav.Item>
                          ) : null}

                          <Nav.Item>
                            <Nav.Link eventKey="instructor">
                              Instruktur
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="review">Ulasan</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        {/* CONTENT */}
                        <Tab.Content>
                          {/* Deskripsi */}
                          <Tab.Pane
                            eventKey="overview"
                            className="overview-tab"
                          >
                            <Deskripsi data={detailKelas} context={context} />
                          </Tab.Pane>
                          {/* Materi */}
                          <Tab.Pane
                            eventKey="curriculum"
                            className="curriculum-tab"
                          >
                            <Materi
                              data={sectionData}
                              setTypeContent={setTypeContent}
                              setUrlContent={setUrlContent}
                              videoToBumper={videoToBumper}
                              context={context}
                            />
                          </Tab.Pane>
                          {/* Instruktur */}
                          <Tab.Pane
                            eventKey="instructor"
                            className="instructor-tab"
                          >
                            <Instruktur
                              data={mentorData}
                              instruktur={instruktur}
                            />
                          </Tab.Pane>
                          {/* Review */}
                          <Tab.Pane eventKey="review" className="review-tab">
                            <Review
                              data={rating}
                              reloadPage={reloadPage.bind(this)}
                              reload={reload}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Col>
                <Col lg="4" md="4" sm="12">
                  <div className="single-details-sidbar">
                    <Row>
                      <Col className="mb-4" md="12">
                        <div
                          id="share-social"
                          className="course-details-feature"
                        >
                          <h5 className="title">Bagikan kelas ini</h5>
                          <ul className="share social list-unstyled list-inline text-center">
                            <li className="list-inline-item">
                              <FacebookShareButton
                                url={returnLink(
                                  detailKelas?.id,
                                  detailKelas?.slug,
                                  "facebook"
                                )}
                                quote={message}
                                hashtags={["#hastag", "tag"]}
                              >
                                <a>
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                              </FacebookShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TwitterShareButton
                                url={returnLink(
                                  detailKelas?.id,
                                  detailKelas?.slug,
                                  "twitter"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-twitter"></i>
                                </a>
                              </TwitterShareButton>
                            </li>
                            <li className="list-inline-item">
                              <LinkedinShareButton
                                url={returnLink(
                                  detailKelas?.id,
                                  detailKelas?.slug,
                                  "linkedin"
                                )}
                                summary={message}
                              >
                                <a>
                                  <i className="fab fa-linkedin-in"></i>
                                </a>
                              </LinkedinShareButton>
                            </li>
                            <li className="list-inline-item">
                              <WhatsappShareButton
                                url={returnLink(
                                  detailKelas?.id,
                                  detailKelas?.slug,
                                  "whatsapp"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-whatsapp"></i>
                                </a>
                              </WhatsappShareButton>
                            </li>
                            <li className="list-inline-item">
                              <TelegramShareButton
                                url={returnLink(
                                  detailKelas?.id,
                                  detailKelas?.slug,
                                  "telegram"
                                )}
                                title={message}
                              >
                                <a>
                                  <i className="fab fa-telegram-plane"></i>
                                </a>
                              </TelegramShareButton>
                            </li>

                            {/* <li className="list-inline-item">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip id="tooltip-copy">
                                    {copied ? "Copied" : "Copy to clipboard"}
                                  </Tooltip>
                                }
                              >
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(
                                      returnLink(
                                        detailKelas?.id,
                                        detailKelas?.slug,
                                        "url"
                                      )
                                    );
                                    setcopied(true);
                                  }}
                                  onMouseLeave={() =>
                                    setTimeout(function () {
                                      setcopied(false);
                                    }, 1000)
                                  }
                                >
                                  <i className="fas fa-copy"></i>
                                </a>
                              </OverlayTrigger>
                            </li> */}
                          </ul>
                        </div>
                      </Col>
                      {detailKelas.is_self_paced && (
                        <Col className="mb-4" md="12">
                          <div className="course-details-feature">
                            <h5 className="title">Kelas Mandiri</h5>
                            <ul className="list-unstyled feature-list">
                              <li>
                                <i className="las la-clock"></i> Durasi:{" "}
                                <span>{detailKelas.total_durasi} menit</span>
                              </li>
                              <li>
                                <i className="las la-sort-amount-up"></i> Level:{" "}
                                <RenderLevelToString
                                  status={detailKelas.level}
                                />
                              </li>
                              <li>
                                <i className="las la-book"></i> Total Materi:{" "}
                                <span>{detailKelas.total_content}</span>
                              </li>
                              <li>
                                <i className="las la-infinity"></i> Dapat
                                diakses kapan pun
                                <span>
                                  <i className="las la-check-circle text-success"></i>
                                </span>
                              </li>
                              <li>
                                <i className="las la-certificate"></i>{" "}
                                Mendapatkan sertifikat
                                <span>
                                  <i className="las la-check-circle text-success"></i>
                                </span>
                              </li>
                            </ul>
                            {payment.length > 0 ? null : (
                              <h5 className="title">Voucher</h5>
                            )}
                            {payment.length > 0 ? null : (
                              <div className="voucher-disc">
                                <form id="form-voucher" className="form">
                                  <p className="form-control">
                                    <Row>
                                      <Col
                                        lg="7"
                                        md="7"
                                        sm="7"
                                        className="pr-0"
                                      >
                                        <input
                                          value={voucher}
                                          onChange={(v) =>
                                            setVoucher(v.currentTarget.value)
                                          }
                                          placeholder="Masukan Voucher"
                                        />
                                      </Col>
                                      <Col lg="5" md="5" sm="5">
                                        <button
                                          onClick={() => {
                                            if (context.login) {
                                              cekVoucher();
                                            } else {
                                              context.setNotification(
                                                "danger",
                                                "Anda belum login, silahkan login terlebih dahulu"
                                              );
                                            }
                                          }}
                                          type="button"
                                          className="btn-voucher"
                                        >
                                          {voucherSuccess ? "Batal" : "Cek"}{" "}
                                          {reloadVoucher ? (
                                            <Spinner
                                              size="sm"
                                              animation="border"
                                              variant="light"
                                            />
                                          ) : null}
                                        </button>
                                      </Col>
                                    </Row>
                                    {voucherSuccess ? (
                                      <Alert variant={"success"}>
                                        <strong>Selamat!</strong> Anda mendapat
                                        diskon dari kode voucher {voc}
                                      </Alert>
                                    ) : null}
                                    {voucherFailed ? (
                                      <Alert variant={"danger"}>
                                        <strong>Maaf!</strong> {responFailed}
                                      </Alert>
                                    ) : null}
                                    {/* {voucherSuccess ? <p className='discount'>Rp. {currencyFormat(detailKelas?.prices)}</p> : null} */}
                                    {/* <h4>Rp. {currencyFormat(price)}</h4> */}
                                    {voucherSuccess ? (
                                      <div>
                                        <div
                                          className="row"
                                          style={{ paddingLeft: 17 }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#f8d7da",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginRight: 10,
                                              borderRadius: 5,
                                              padding: 5,
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#721c24",
                                                fontSize: 10,
                                              }}
                                            >
                                              {persen}%
                                            </p>
                                          </div>
                                          <h5 className="price">
                                            Rp. {currencyFormat(priceDiskon)}
                                          </h5>
                                          <h6
                                            className="price"
                                            style={{
                                              textDecorationLine:
                                                "line-through",
                                              fontSize: 12,
                                              textVerticalAlign: "bottom",
                                              color: "grey",
                                            }}
                                          >
                                            Rp.{" "}
                                            {currencyFormat(
                                              detailKelas?.prices
                                            )}
                                          </h6>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {listPromo.length > 0 && (
                                          <div className="form-group">
                                            <select
                                              className="form-control"
                                              id="choose-promo"
                                              onChange={(e) => {
                                                if (e.target.value) {
                                                  listPromo.forEach((item) => {
                                                    if (
                                                      item.promo_code ==
                                                      e.target.value
                                                    ) {
                                                      setUsePromo(item);
                                                    }
                                                  });
                                                } else {
                                                  setUsePromo("");
                                                }
                                              }}
                                            >
                                              <option value="">
                                                - Pilih Promo -
                                              </option>
                                              {listPromo.map((item, idx) => {
                                                return (
                                                  <option
                                                    key={idx}
                                                    value={item.promo_code}
                                                  >
                                                    {`
                                                ${item.description
                                                      } - potongan harga Rp
                                                ${currencyFormat(
                                                        item.real_price -
                                                        item.promo_price
                                                      )}`}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </div>
                                        )}

                                        <NewPrices
                                          detail={detailKelas}
                                          realPrice={realPrice}
                                          usePromo={usePromo}
                                          priceHome={true}
                                        />

                                        {/* <Prices
                                        detail={detailKelas}
                                        realPrice={realPrice}
                                      /> */}
                                      </>
                                    )}
                                  </p>
                                </form>
                              </div>
                            )}
                            <button
                              disabled={loadingToBuy? true : false }
                              onClick={() => {
                                if (payment.length > 0) {
                                  window.location.href =
                                    process.env.PUBLIC_URL +
                                    "/learn-class/" +
                                    id;
                                } else {
                                  if (context.login) {
                                    if (voucherSuccess) {
                                      getUrl(priceDiskon, voc);
                                    } else {
                                      getUrl(price, "", usePromo);
                                    }
                                  } else {
                                    context.setNotification(
                                      "danger",
                                      "Anda belum login, silahkan login terlebih dahulu"
                                    );
                                  }
                                }
                              }}
                              type="button"
                              className="enroll-btn"
                            >
                              {loadingToBuy && <Spinner
                                              size="sm"
                                              animation="border"
                                              variant="light"
                                            /> }
                              {payment.length > 0
                                ? <span className="ml-2">Lihat Kelas</span>
                                : <span className="ml-2">Beli Kelas</span>}
                            </button>
                            <button
                              onClick={() => {
                                if (context.login) {
                                  setfavorit(favorit ? false : true);
                                  likeUnLike(detailKelas.id);
                                } else {
                                  context.setNotification(
                                    "danger",
                                    "Anda belum login"
                                  );
                                }
                              }}
                              className="btn favorit mt-2"
                            >
                              {favorit ? (
                                <>
                                  <i
                                    className="fas fa-heart"
                                    style={{ color: "#DE1D22" }}
                                  ></i>{" "}
                                  <span style={{ color: "#DE1D22" }}>
                                    Difavoritkan
                                  </span>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="far fa-heart"
                                    style={{ color: "white" }}
                                  ></i>{" "}
                                  Favorit
                                </>
                              )}
                            </button>
                          </div>
                        </Col>
                      )}
                      {detailKelas.is_live_class && (
                        <Col className="mb-4" md="12">
                          <LiveKelas
                            liveKelas={liveKelas}
                            context={context}
                            getDataDetailKelas={getDataDetailKelas}
                          />
                        </Col>
                      )}
                      {detailKelas.is_private && (
                        <Col className="mb-4" md="12">
                          <div className="course-details-feature">
                            <h5 className="title">Kelas Privat</h5>
                            <p>Kelas privat untuk tim mu online atau offline</p>
                            <p className="text-center">
                              <Link
                                className="add_cart"
                                to={
                                  process.env.PUBLIC_URL +
                                  `/class/${id}/new-private`
                                }
                              >
                                <button
                                  style={{ height: "35px", fontSize: "15px" }}
                                  className="enroll-btn p-2"
                                >
                                  Kirim permintaan
                                </button>
                              </Link>
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      )}

      {/* Footer 2 */}
      <Footer />

      {/* {loading ? null : (
        <div
          style={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: 20,
            zIndex: 999,
          }}
        >
          <Footer />
        </div>
      )} */}
    </div>
  );
  function realPrice(vale) {
    // console.log("VALE : ", vale);
    setPrice(vale);
    // getUrl(vale,'')
  }
  async function cekVoucher() {
    setUsePromo("");
    setReloadVoucher(true);
    if (voucher != "") {
      let form = new FormData();
      form.append("vouchers", voucher);
      form.append("class_id", id);
      form.append("type_class", "1");
      let response = await context.postRequest("check-vouchers", form, "form");
      if (response.errors == 200) {
        var potongan = detailKelas?.prices - response.data[0].harga_diskon;
        var pers = 0;
        if (response.data[0].type_diskon == "%") {
          pers = response.data[0].harga_diskon;
        } else {
          pers = (potongan / detailKelas?.prices) * 100;
        }
        // alert(response.data[0].harga_diskon)
        // await getUrl(response.data[0].harga_diskon,voucher)
        setPersen(pers);
        await setPrice(detailKelas?.prices);
        await setPriceDiskon(response.data[0].harga_setelah_diskon);
        setVoc(voucher);
        setVoucher("");
        setVoucherSuccess(true);
        setVoucherFailed(false);
        setReloadVoucher(false);
      } else {
        // getUrl(detailKelas?.prices,'')
        setVoucher("");
        setResponFailed(response.message);
        setVoucherSuccess(false);
        setVoucherFailed(true);
        setPrice(detailKelas?.prices);
        setReloadVoucher(false);
      }
    } else {
      // getUrl(detailKelas?.prices,'')
      setVoucher("");
      setVoucherSuccess(false);
      setVoucherFailed(false);
      setPrice(detailKelas?.prices);
      setReloadVoucher(false);
    }
  }
}

export default CourseDetails;
