import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Styles } from './styles/account.js';
import firebase from '../../config/firebase.config'
import { ContextProvider } from '../../context/BaseContext'
import { setStorage } from '../../util/Functions'
import { useForm } from "react-hook-form";


function ForgotPassword({ history }) {

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const context = useContext(ContextProvider)
    const [refresh, setRefresh] = useState(false)
    const [username, setUsername] = useState('')

    return (
        <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper login-page" style={{ position: 'relative' }}>

                {/* Header 2 */}
                <HeaderTwo />

                {/* Breadcroumb */}
                <BreadcrumbBox title="Lupa Password" image={`${process.env.PUBLIC_URL}/assets/illustration14.jpg`} />

                {/* Login Area */}
                <section className="login-area" style={{ paddingBottom: 150 }}>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="login-box">
                                    <div className="login-title text-center">
                                        <h3>Lupa Password</h3>
                                    </div>
                                    <form onSubmit={handleSubmit(send)} id="form_login" className="form">
                                        <p className="form-control">
                                            <label htmlFor="login_user">Email</label>
                                            <input {...register('email', {
                                                required: 'Kamu harus mengisi email', pattern: {
                                                    value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                                    message: "Harap isi dengan format email"
                                                }
                                            })} type="text" placeholder="Masukankan email anda" id="login_user" />
                                            <div style={{ color: 'red' }}>{errors.email ? errors.email.message : null}</div>
                                        </p>
                                        <button disabled={refresh} className="btn-login">Kirim &nbsp;{refresh ? <Spinner animation="border" variant="light" /> : null}</button>
                                        <div className="save-forget-password d-flex justify-content-center">
                                            {/* <div className="save-passowrd">
                                                <label htmlFor="save_password"><input type="checkbox" id="save_password" className="check-box" />Save Password</label>
                                            </div> */}
                                            <div className="forget-password">
                                                <Link to={process.env.PUBLIC_URL + "/login"}>Masuk</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Footer 2 */}
                <div style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}>
                    <Footer />
                </div>

            </div>

        </Styles>
    )

    async function send(data) {
        setRefresh(true)
        try {
            let form = new FormData()
            form.append('email', username)
            let response = await context.postRequest('forgotPassword', JSON.stringify(data))
            context.setNotification('success', response.message)
            setRefresh(false)
        } catch (error) {
            context.setNotification('danger', error.response.data.message)
            setRefresh(false)
        }
    }
}

export default ForgotPassword