import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {convertToSlug,paramsMentor} from '../../../util/Functions'
import { shareHost } from "../../../util/host";

export default function Instruktur({ data,instruktur }) {
  // console.log(data);
  const [avatarError, setAvatarError] = useState(false);

  return (
    <div className="container-instructor" style={{ paddingBottom: 150 }}>
      <h5>Instruktur Kelas</h5>
      <div className="review-tab">
        <div className="review-comments">
          
          <div className="comment-box d-flex">
              <div className="comment-image">
                  <img src={
                      avatarError
                        ? process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`
                        : data.avatar
                    }
                    alt="avatar-mentor"
                    // style={{width:150,height:150}}
                    className="img-fluid"
                    onError={() => setAvatarError(true)} />
              </div>
              <div className="comment-content">
                  <div className="content-title d-flex justify-content-between">
                      <div className="comment-writer">
                          <h6 className="heading">
                            <a
                              // href={
                              //   process.env.PUBLIC_URL +
                              //   "/instructor-details/" +
                              //   data.id+'/personal/'+convertToSlug(data.name)+paramsMentor(data)
                              // }
                              href={`${shareHost}${data.id}/mentor/${convertToSlug(data.name)}`}
                              style={{color:'#D71C20'}}
                            >
                              {data.name}
                            </a>
                          </h6>
                          <p style={{fontWeight:'bold'}}>{data.title}</p>
                      </div>
                  </div>
                  <div className="comment-desc">
                      <p dangerouslySetInnerHTML={{__html:data.description}}/>
                  </div>
              </div>
          </div>
          <RenderInstruktur instruktur={instruktur}/>
        </div>
      </div>
      {/* <div className="instructor-item">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="instructor-img text-center">
              <img
                src={
                  avatarError
                    ? process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`
                    : data.avatar
                }
                alt="avatar-mentor"
                style={{width:150,height:150}}
                className="img-fluid"
                onError={() => setAvatarError(true)}
              />
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="instructor-content text-center">
              <div className="instructor-box">
                <div className="top-content d-flex justify-content-between">
                  <div className="instructor-name">
                    <h6 className="heading">
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/instructor-details/" +
                          data.id
                        }
                      >
                        {data.name}
                      </Link>
                    </h6>
                    <p>{data.title}</p>
                  </div>
                  <div className="instructor-social">
                    <ul className="social list-unstyled list-inline">
                      {data.facebook && (
                        <li className="list-inline-item">
                          <a className="facebook" href={data.facebook}>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      )}
                      {data.twitter && (
                        <li className="list-inline-item">
                          <a className="twitter" href={data.twitter}>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                      )}
                      {data.linkedin && (
                        <li className="list-inline-item">
                          <a className="linkedin" href={data.linkedin}>
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      )}
                      {data.youtube && (
                        <li className="list-inline-item">
                          <a className="youtube" href={data.youtube}>
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="instructor-desk">
                  <p style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html:data.description}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      
    </div>
  );
  function RenderInstruktur(){
    return instruktur.map((val,i)=>{
      // console.log("DETAIL INSTRUKTUR : ",val)
      return (
        <div key={'instruktur'+i} className="comment-box d-flex">
          <div className="comment-image">
              <img src={
                  avatarError
                    ? process.env.PUBLIC_URL + `/assets/images/instructor-1.jpg`
                    : val.instruktur.avatar
                }
                alt="avatar-mentor"
                // style={{width:150,height:150}}
                className="img-fluid"
                onError={() => setAvatarError(true)} />
          </div>
          <div className="comment-content">
              <div className="content-title d-flex justify-content-between">
                  <div className="comment-writer">
                      <h6 className="heading">
                        <a
                          // href={
                          //   process.env.PUBLIC_URL +
                          //   "/instructor-details/" +
                          //   val.instruktur_id+'/instruktur'
                          // }
                          href={`${shareHost}${val.instruktur_id}/mentor/${val.instruktur.nama}`}
                          style={{color:'#D71C20'}}
                        >
                          {val.instruktur.nama}
                        </a>
                      </h6>
                      <p style={{fontWeight:'bold'}}>{val.instruktur.title}</p>
                  </div>
              </div>
              <div className="comment-desc">
                  <p dangerouslySetInnerHTML={{__html:val.instruktur.description}}/>
              </div>
          </div>
      </div>
      )
    })
  }
}
