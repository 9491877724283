import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function Catatan({ idContentActive, context }) {
  const { id } = useParams();
  const [dataCatatan, setdataCatatan] = useState([]);
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  async function getCatatan(id) {
    let response = await context.getRequest(`note?content_id=${id}`);
    if (response) {
      // console.log(response);
      setdataCatatan(response.data.data);
    }
  }

  const onSubmit = async (data) => {
    console.log(data, id);
    var form = new FormData();
    form.append("content_id", idContentActive);
    form.append("class_id", id);
    form.append("note", data.notes);

    try {
      let response = await context.postRequest("note", form, "form");
      if(response){
        reset()
        getCatatan(idContentActive);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (idContentActive) {
      getCatatan(idContentActive);
    }
  }, [idContentActive]);
  return (
    <Row>
      <Col md="12">
        {idContentActive ? (
          <div className="catatan-content">
            <h5>Catatan Belajar</h5>
            <p>Catatan pembelajaran mu di sini agar mudah di ingat.</p>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <div className="form-control mb-2">
                <textarea
                  {...register("notes", {
                    required: "Maaf, tidak bisa menyimpan catatan kosong",
                  })}
                  placeholder="Tulis catatan mu disini"
                />
                <div style={{ color: "red" }}>
                  {errors.notes && errors.notes.message}
                </div>
              </div>
              <div className="text-right">
                <button type="submit">Simpan Catatan</button>
              </div>
            </form>
            <div className="list-catatan mt-4">
              <h5>List Catatan Belajar</h5>
              <div className="event-area">
                <Row>
                  <Col md="12">
                    {dataCatatan.length != 0 ? (
                      dataCatatan.map((item, idx) => {
                        return (
                          <div className="event-box d-flex" key={idx}>
                            <div className="event-date text-center">
                              <p style={{ color: "whitesmoke" }}>{moment(item.created_at).format("DD MMM YYYY")}</p>
                            </div>
                            <div className="event-details pt-2 pb-2">
                              <p>{item.notes}</p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="catatan-content text-center p-4">
                        <i
                          style={{ fontSize: "50px", color: "red" }}
                          className="fas fa-exclamation-circle mb-2"
                        ></i>
                        <p>Anda belum memiliki catatan di materi ini</p>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <div className="catatan-content text-center p-4">
            <br />
            <br />
            <i
              style={{ fontSize: "50px", color: "red" }}
              className="fas fa-exclamation-circle mb-2"
            ></i>
            <p>
              Klik konten materi terlebih dahulu untuk menulis atau melihat
              catatan anda.
            </p>
            <br />
            <br />
          </div>
        )}
      </Col>
    </Row>
  );
}
