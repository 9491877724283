import React from "react";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Footer from '../../components/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "../../components/styles/aboutUs";

export default function App(){
    return (
        <div className="main-wrapper course-page" style={{ position: 'relative' }}>
            <HeaderTwo />
            <BreadcrumbBox title="Kebijakan Privasi" image={`${process.env.PUBLIC_URL}/assets/illustration11.png`} />
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="about-content">
                                    <h4 className="about-title">Kebijakan Privasi</h4>
                                    <p className="about-para">Harap membaca kebijakan privasi ini dengan seksama karena ketika menggunakan Wakool Academy maka Anda menyatakan telah membaca dan setuju terhadap kebijakan privasi ini. Kebijakan privasi ini berlaku untuk seluruh pengguna di berbagai perangkat dan aplikasi kecuali apabila diatur dalam kebijakan yang terpisah.</p>
                                    <ul>
                                        <li><p className="about-para">Berbagai data dan informasi milik pengguna yang diberikan pada saat mendaftar, mengakses dan menggunakan layanan baik melalui penelusur web di komputer dan perangkat seluler ataupun berupa aplikasi yang di install di perangkat seluler pengguna dilindungi dan dijaga kerahasiaannya.</p></li>
                                        <li><p className="about-para">Informasi yang dikumpulkan dapat berupa nama, foto, alamat email, akun media social, nomor telephone, perangkat yang digunakan, versi penelusur web, informasi aktifitas pada halaman, nilai, percakapan dan data lainnya.</p></li>
                                        <li><p className="about-para">Wakool Academy berhak menggunakan data pengguna untuk aktifitas transaksi, meningkatkan layanan dan mutu dari situs ini dan aplikasinya.</p></li>
                                        <li><p className="about-para">Wakool Academy bisa memperoleh data dari pihak ketiga dan juga bisa memberikan data kepada pihak ketiga untuk kepentingan transaksi atau kegiatan bersama dengan pihak ketiga yang diikuti oleh pengguna. Kami akan mengupayakan agar pihak ketiga yang menerima data pengguna memastikan bahwa data pengguna memperoleh perlindungan yang sebanding dengan kebijakan privasi ini.</p></li>
                                        <li><p className="about-para">Seluruh informasi pribadi milik pengguna akan disimpan selama pengguna masih menggunakan Wakool Academy dan setidaknya lima tahun setelah aktifitas terakhir pengguna di Wakool Academy.</p></li>
                                        <li><p className="about-para">Pengguna bertanggung jawab terhadap pertukaran informasi data pribadi diluar situs Wakool Academy yang dilakukan oleh pengguna.</p></li>
                                        <li><p className="about-para">Pengguna bertanggung jawab terhadap kebenaran dan kemutakhiran data pribadi yang diberikan kepada Wakool Academy.</p></li>
                                        <li><p className="about-para">Pengguna dapat melakukan permohonan untuk penghapusan informasi Pribadi Pengguna pada platform Wakool Academy atau membatalkan persetujuan pengguna terhadap seluruh penarikan, pengungkapan dan penggunaan informasi pengguna dengan cara memberitahukan kepada Wakool Academy secara tertulis melalui email <a style={{fontSize:"15px", color:"red"}} href="mailto:info@wakool.academy">info@wakool.academy</a> . Berdasarkan bentuk dan sifat penarikan data tersebut, pengguna paham dan menyetujui bahwa setelah permohonan penghapusan atau pembatalan persetujuan tersebut, pengguna mungkin tidak lagi dapat menggunakan platform Wakool Academy ataupun mengakibatkan penghentian akun atau hubungan kontraktual antara pengguna dan Wakool Academy dengan semua hak dan kewajiban yang telah ada sebelumnya harus dipenuhi terlebih dahulu. Setelah menerima informasi penghapusan atau pembatalan persetujuan dari pengguna, maka Wakool Academy akan memberikan informasi konsekuensi yang akan muncul dan pengguna bisa memutuskan apakah akan melanjutkan permohonannya atau tidak.</p></li>
                                        <li><p className="about-para">Ketika pengguna menggunakan Wakool Academy, maka kami akan menempatkan cookies pada penelusur web pengguna. Cookies digunakan diantaranya untuk mengenali pengguna dan aktifitasnya di Wakool Academy serta menggunakan data tersebut untuk kemudahan transaksi, penelusuran di Wakool Academy dan iklan yang sesuai.</p></li>
                                        <li><p className="about-para">Untuk kepentingan pengembangan bisnis, kami mungkin membeli atau menjual perusahaan atau aset. Kami dapat mengungkapkan data kepada calon pembeli atau penjual. Kami juga dapat mengirimkan atau menyerahkan informasi tersebut untuk kepentingan penggabungan, peleburan atau pembubaran perusahaan.</p></li>
                                        <li><p className="about-para">Wakool Academy dapat memberitahukan data dan informasi yang dimiliki oleh pengguna jika diwajibkan dan atau diminta oleh institusi berwenang berdasarkan ketentuan hukum yang berlaku, perintah resmi dari pengadilan, dan atau perintah resmi dari instansi/aparat bersangkutan.</p></li>
                                        <li><p className="about-para">Wakool Academy akan memberi tahukan kepada pengguna melalui situs web atau aplikasi wakool Academy apabila ada perubahan mengenai kebijakan privasi ini.</p></li>
                                        <li><p className="about-para">Semua konten yang ada di Wakool Academy diperuntukan untuk orang yang sudah dewasa dan apabila ada konten yang bisa digunakan untuk anak-anak maka semua proses yang dilakukan harus didampingi oleh orang dewasa. Pengguna setuju bahwa semua transaksi, review, komentar dan aktifitas lainnya adalah tanggung jawab Pengguna yang telah dewasa ketika mendampingi anak-anak dalam menggunakannya.</p></li>
                                    </ul>
                                    <h4 className="about-title">Hak Kekayaan Intelektual</h4>
                                    <p className="about-para">Setiap konten yang ditampilkan oleh Wakool Academy adalah milik Wakool Academy, pemateri atau pemilik hak cipta aslinya yang memberikan kuasa kepada Wakool Academy dan rekannya untuk menggunakan kekayaan intelektual tersebut.<br/><br/>
Pengguna dilarang menyalin, mengunduh, merubah, menerjemahkan, merekam tangkapan layar, mendistribusikan, mengadaptasi, menampilkannya kepada publik, menjual, sebagian atau keseluruhan konten yang ada di Wakool Academy baik untuk kepentingan komersial ataupun penggunaan pribadi.<br/><br/>
Pengguna memiliki hak untuk mengakses semua konten yang telah dimilikinya didalam platform atau aplikasi Wakool Academy. Pengguna tidak diperbolehkan mengalihkan, menggunakan bersama-sama hak dan atau akunnya kepada pengguna lain.<br/><br/>
Pemateri dibolehkan menawarkan materinya di tempat lain, baik offline ataupun online selama harga jual materi tersebut tidak lebih rendah daripada harga normal di Wakool Academy. Hal ini tidak berlaku apabila dinyatakan lain oleh kebijakan atau kontrak yang disepakati bersama. <br/><br/>
</p>
                                    <h4 className="about-title">Keadaan Kahar (Force Majeure)</h4>
                                    <p className="about-para">Wakool Academy dan seluruh layanannya dapat diinterupsi oleh kejadian luar biasa yang diluar kewenangan atau control kami, termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan komunikasi, gangguan hardware, kebijakan pemerintah dan lainnya. Pengguna setuju untuk melepaskan Wakool Academy dari segala tuntutan dan tanggung jawab terhadap gangguan pelayanan baik sebagian atau keseluruhan karena keadaan Kahar ini.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <div style={{ position: 'fixed', width: '100%', bottom: 0, left: 0, right: 0, marginTop: 20 }}>
                <Footer />
            </div>
            
        </div>
    )
}