import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/mobileMenu.js";
import Search from "./Search.js";

function MobileMenu({ username, logOut, categorylist }) {
  const [showSideBar, setshowSideBar] = useState(false);
  const [toggleCategory, setToggleCategory] = useState(false);
  const [toggleRiwayat, setToggleRiwayat] = useState(false);

  useEffect(() => {});

  return (
    <Styles>
      {/* Mobile Menu */}
      <section className="mobile-menu-area">
        <Container>
          <Row>
            <Col md="0" sm="12">
              <div className="mb-topbar d-flex justify-content-between">
                <div className="topbar-item">
                  <p>
                    <i className="las la-phone"></i>(022) 7537609
                  </p>
                </div>
                <div className="topbar-item">
                  <ul className="list-unstyled list-inline">
                    {!username ? (
                      <>
                        <li className="list-inline-item">
                          <Link to={process.env.PUBLIC_URL + "/login"}>
                            <i className="las la-user"></i>Masuk
                          </Link>
                        </li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item">
                          <Link to={process.env.PUBLIC_URL + "/registration"}>
                            <i className="las la-user-edit"></i>Daftar
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="list-inline-item">
                          <Link to={process.env.PUBLIC_URL + "/profile"}>
                            <i className="las la-user-edit"></i>Edit Profile
                          </Link>
                        </li>
                        <li className="list-inline-item">/</li>
                        <li className="list-inline-item">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              logOut();
                            }}
                          >
                            <i className="las la-user"></i>Keluar
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className="mb-logo-area d-flex justify-content-between">
                <div className="mb-logo-box d-flex">
                  <div className="hm-button">
                    <a
                      href={process.env.PUBLIC_URL + "/"}
                      id="mb-sidebar-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        setshowSideBar(true);
                      }}
                    >
                      <i className="las la-bars"></i>
                    </a>
                  </div>
                  <div className="mb-logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/wakoollogo1.png"
                        }
                        alt="logo-wakool"
                      />
                    </Link>
                  </div>
                </div>
                <div className="mb-search-box">
                  <Search />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mobile Menu Sidebar */}
      <section
        className={showSideBar ? "mb-sidebar opened" : "mb-sidebar"}
        id="mb-sidebar-body"
      >
        <div className="mb-sidebar-heading d-flex justify-content-between">
          <div>
            <h5>Menu</h5>
          </div>
          <div>
            <a
              href={process.env.PUBLIC_URL + "/"}
              id="close-mb-sidebar"
              onClick={(e) => {
                e.preventDefault();
                setshowSideBar(false);
              }}
            >
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <div className="mb-sidebar-menu">
          <div className="mb-menu-item">
            <button className="mb-menu-button active">
              <Link to={process.env.PUBLIC_URL + "/"}>
                <p>Home</p>
              </Link>
            </button>
          </div>
          <div className="mb-menu-item">
            <button
              onClick={() => setToggleCategory(toggleCategory ? false : true)}
              className="mb-menu-button"
            >
              <p>
                Kategori{" "}
                {toggleCategory ? (
                  <i className="las la-minus"></i>
                ) : (
                  <i className="las la-plus"></i>
                )}
              </p>
            </button>
            <div className={`mb-menu-content ${toggleCategory && "show"}`}>
              <ul className="list-unstyled">
                {categorylist.map((idx) => {
                  return (
                    <li key={"menus" + idx.id}>
                      <Link
                        className="nav-link"
                        to={
                          process.env.PUBLIC_URL +
                          "/classes/" +
                          idx.id +
                          "-" +
                          idx.slug
                        }
                      >{`${idx.title}`}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="mb-menu-item">
            <div className="mb-menu-item">
              <button className="mb-menu-button">
                <Link to={process.env.PUBLIC_URL + "/prakerja"}>
                  <p>Prakerja</p>
                </Link>
              </button>
            </div>
          </div>
          <div className="mb-menu-item">
            <div className="mb-menu-item">
              <button className="mb-menu-button">
                <Link to={process.env.PUBLIC_URL + "/tiktalk"}>
                  <p>TIKTALK</p>
                </Link>
              </button>
            </div>
          </div>
          <div className="mb-menu-item">
            <div className="mb-menu-item">
              <button className="mb-menu-button">
                <Link to={process.env.PUBLIC_URL + "/webinars"}>
                  <p>Webinar</p>
                </Link>
              </button>
            </div>
          </div>
          <div className="mb-menu-item">
            <div className="mb-menu-item">
              <button className="mb-menu-button">
                <Link to={process.env.PUBLIC_URL + "/faq"}>
                  <p>FAQ</p>
                </Link>
              </button>
            </div>
          </div>
          {username && (
            <>
              <div className="mb-menu-item">
                <div className="mb-menu-item">
                  <button className="mb-menu-button">
                    <Link to={process.env.PUBLIC_URL + "/certificate"}>
                      <p>Sertifikat</p>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="mb-menu-item">
                <div className="mb-menu-item">
                  <button className="mb-menu-button">
                    <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                      <p>Favorit</p>
                    </Link>
                  </button>
                </div>
              </div>
              <div className="mb-menu-item">
                <button className="mb-menu-button">
                  <Link to={process.env.PUBLIC_URL + "/my-class"}>
                    <p>Kelasku</p>
                  </Link>
                </button>
              </div>
              {/* <div className="mb-menu-item">
                <button className="mb-menu-button">
                  <Link to={process.env.PUBLIC_URL + "/transaction"}>
                    <p>Riwayat Transaksi</p>
                  </Link>
                </button>
              </div> */}
              <div className="mb-menu-item">
                <button
                  onClick={() => setToggleRiwayat(toggleRiwayat ? false : true)}
                  className="mb-menu-button"
                >
                  <p>
                    Riwayat{" "}
                    {toggleRiwayat ? (
                      <i className="las la-minus"></i>
                    ) : (
                      <i className="las la-plus"></i>
                    )}
                  </p>
                </button>
                <div className={`mb-menu-content ${toggleRiwayat && "show"}`}>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/transaction"}
                      >
                        Riwayat Transaksi
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link"
                        to={process.env.PUBLIC_URL + "/list-private-user"}
                      >
                        Riwayat Private Kelas
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <div
        className={
          showSideBar ? "mb-sidebar-overlay visible" : "mb-sidebar-overlay"
        }
        id="mb-sidebar-overlay"
      ></div>
    </Styles>
  );
}

export default MobileMenu;
