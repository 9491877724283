import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerOne.js";

class Footer extends Component {
  render() {
    return (
      <Styles>
        {/* Copyright Area */}
        <section className="copyright-area">
          <Container>
            <Row>
              <Col md="6">
                <div className="copy-text">
                  <p>
                    Copyright &copy; 2021{" "}
                    <a
                      href={process.env.PUBLIC_URL + "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wakool Academy
                    </a>
                  </p>
                </div>
              </Col>
              <Col md="6" className="text-right">
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a href={"https://www.facebook.com/wakool.academy"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={"https://twitter.com/wakool_academy"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  {/* <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li> */}
                  <li className="list-inline-item">
                    <a
                      href={
                        "https://www.youtube.com/channel/UCB_5WWvk0x8qA6zeo2gJ6JQ"
                      }
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={"https://www.instagram.com/wakool.academy/"}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>

          {/* Back To Top */}
          <BackToTop />
        </section>
      </Styles>
    );
  }
}

export default Footer;
