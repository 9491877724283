import React, { useState } from "react";
import { Badge, Col, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import RenderLevelToString from "../../../components/RenderLevelToString";
import {
  getRating,
  minuteToHour,
  generateParamsQueryClass,
} from "../../../util/Functions";

export default function RenderClassItem({ data }) {
  const [showErrorCover, setShowErrorCover] = useState(false);

  return (
    <Col className="pl-2 pr-2" lg="3" md="4" sm="6">
      <div className="product-box">
        <div className="product-img text-center">
          <Link
            to={
             process.env.PUBLIC_URL +
                  `/learn-class/${data.kelas.id}${generateParamsQueryClass(
                    data.kelas
                  )}`
            }
          >
            <img
              src={
                showErrorCover
                  ? process.env.PUBLIC_URL + `/assets/images/product-02.jpg`
                  : data.kelas.img
              }
              alt="myclass-cover"
              className="img-fluid"
              onError={() => setShowErrorCover(true)}
            />
          </Link>
          {/* <span>{"Baru"}</span> */}
          {/* <div className="layer-box" />
          <Link
            className="add_cart"
            to={process.env.PUBLIC_URL + `/learn-class/${data.kelas.id}${generateParamsQueryClass(data.kelas)}`}
          >
            Mulai Belajar
          </Link>
          <Link
            className="item_view"
            to={process.env.PUBLIC_URL + `/detail-class/${data.kelas.id}${generateParamsQueryClass(data.kelas)}`}
          >
            Detail Kelas
          </Link> */}
        </div>
        <div className="product-content text-center">
          <div className="pro-title">
            <h5 className="max-character-2">
              <Link
                to={
                  process.env.PUBLIC_URL +
                       `/learn-class/${data.kelas.id}${generateParamsQueryClass(
                         data.kelas
                       )}`
                 }
              >
               <a>{data.kelas.title}</a>
              </Link>
            </h5>
            {/* <h6>Kategori</h6> */}
          </div>
          <div className="pro-rating">
            <ul className="list-unstyled list-inline">
              {getRating(
                parseFloat(data.kelas.total_vote ? data.kelas.total_vote : 0)
              )}
              <li className="list-inline-item">
                ({parseFloat(data.kelas.total_vote ? data.kelas.total_vote : 0)}
                ){" "}
                {data.kelas.is_prakerja && (
                  <Badge variant="info">Prakerja</Badge>
                )}
              </li>
            </ul>
          </div>
          <div className="pro-info">
            <span className="time">
              <i className="las la-clock" />{" "}
              {minuteToHour(data.kelas.total_durasi)}
            </span>
            <span className="content">
              <i className="las la-book-open" /> {data.kelas.total_content}{" "}
              materi
            </span>
            <span>
              <i className="las la-signal" />{" "}
              <RenderLevelToString status={data.kelas.level} />
            </span>
          </div>

          {data.kelas.kelas_activity != null ? (
            <div className="pro-activity">
              <span>Terakhir dilihat</span>
              {data.kelas.kelas_activity ? (
                <span className="max-character-1">
                  {data.kelas.kelas_activity?.content_user?.section_user?.title}{" "}
                  - {data.kelas.kelas_activity?.content_user?.title}
                </span>
              ) : (
                <span>"-"</span>
              )}
            </div>
          ) : null}
          <ProgressBar
            className="mt-2"
            variant="info"
            now={data.total_selesai_persentase}
            label={`${data.total_selesai_persentase}%`}
          />
        </div>
      </div>
    </Col>
  );
}
