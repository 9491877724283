import React, { Component } from "react";
import { Styles } from "../styles/courseSearch.js";

function CourseSearch({ setSearchString, setDefaultSearch }) {
  return (
    <Styles>
      {/* Course Search */}
      <div className="course-search">
        <h5>Cari Kelas</h5>
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            defaultValue={setDefaultSearch}
            name="search"
            onChange={(e) => {
              e.preventDefault();
              setSearchString(e.target.value);
            }}
            placeholder="Search Here"
          />
          <button type="submit">
            <i className="las la-search"></i>
          </button>
        </form>
      </div>
    </Styles>
  );
}

export default CourseSearch;
