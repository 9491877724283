import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
var fileDownload = require("js-file-download");

export default function FileContents({ context, filesContent }) {
  function RenderListFile({ file }) {
    const [loading, setLoading] = useState(false);

    async function handleDownload(url, fileName) {
      setLoading(true);
      // console.log(url.split("="));
      let name = fileName.replace('.','')
      let type = url.split("=")
      context
        .downloadRequestWithoutHost(url)
        .then((response) => {
          //   console.log(response);
          fileDownload(response, `${name}.${type[1]}`);
          setLoading(false);
        })
        .catch((error) => console.log("ERROR", error));
    }
    return (
      <li>
        <p className="mb-1">{file.nama_file}</p>
        <p className="text-right">
          <button
            onClick={() => handleDownload(file.file_url, file.nama_file)}
            className="btn btn-danger btn-sm"
            disabled={loading}
          >
            {loading ? (
              <div className="spinner-border text-light" role="status" />
            ) : (
              <>Donwload</>
            )}
          </button>
        </p>
      </li>
    );
  }

  return (
    <Col className="mt-4" md="12">
      <div className="course-details-feature">
        <h5 className="title">File Pendukung</h5>
        <ul className="list-unstyled feature-list">
          {filesContent.length != 0 ? (
            <>
              {filesContent.map((file, index) => (
                <RenderListFile key={index} file={file} />
              ))}
            </>
          ) : (
            <li className="text-center">
              <p>
                <i
                  style={{ fontSize: "30px", color: "red" }}
                  className="fas fa-exclamation-circle mb-1"
                ></i>
              </p>
              <p>Materi ini tidak memiliki file pendukung</p>
            </li>
          )}
        </ul>
      </div>
    </Col>
  );
}
