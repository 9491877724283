import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import './costumStyle.css'
// import * as serviceWorker from "./serviceWorker";
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}
// ReactDOM.hydrate(<App />, document.getElementById("root"));

// serviceWorker.unregister();