import React, { Fragment, useContext, useState } from "react";
import Datas from "../../../data/course/item.json";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import Pagination from "./../../../components/Pagination";
import {
  currencyFormat,
  getRating,
  minuteToHour,
  Prices,
  generateParamsQueryClass,
} from "../../../util/Functions";
import { ContextProvider } from "../../../context/BaseContext";
import {shareHost} from '../../../util/host'

function CourseItemGrid({ data, getData }) {
  const context = useContext(ContextProvider);
  const GridItem = ({ data }) => {
    const [wishlist, setWishlist] = useState(true);
    const kelas = data.kelas;
    return (
      <Col lg="4" md="6" sm="12">
        <div className="course-item">
          <a
            href={linkHref(kelas)}
            // to={
            //   process.env.PUBLIC_URL +
            //   "/detail-class/" +
            //   kelas.id +
            //   generateParamsQueryClass(kelas)
            // }
          >
            <div
              className="course-image"
              style={{ backgroundImage: `url('${kelas.img}')` }}
            >
              <div className="author-img d-flex">
                <div className="img">
                  <img src={kelas.mentors.avatar} alt="" />
                </div>
                <div className="title">
                  <p>{kelas.mentors.name}</p>
                  <span>{kelas.total_content} Materi</span>
                </div>
              </div>
              <div className="course-price">
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    setWishlist(wishlist ? false : true);
                    likeUnLike(kelas.id);
                  }}
                >
                  {wishlist ? (
                    <i
                      className="fas fa-heart"
                      style={{ color: "#DE1D22" }}
                    ></i>
                  ) : (
                    <i className="far fa-heart" style={{ color: "white" }}></i>
                  )}
                </p>
              </div>
            </div>
          </a>
          <div className="course-content">
            <h6 className="heading">
              <a
                href={linkHref(kelas)}
                // to={
                //   process.env.PUBLIC_URL +
                //   "/detail-class/" +
                //   kelas.id +
                //   generateParamsQueryClass(kelas)
                // }
              >
                {kelas.title}
              </a>
            </h6>
            <h6 className="category">
              {kelas.category_classes[0].category.title}
            </h6>
            {/* <h6 className="price">Rp. {currencyFormat(kelas.prices)}</h6> */}
            <Prices detail={kelas} />
            <p
              className="desc max-character"
              dangerouslySetInnerHTML={{ __html: kelas.description }}
            ></p>
            <div className="course-face d-flex justify-content-between">
              <div className="duration">
                <p>
                  <i className="las la-clock"></i>
                  {minuteToHour(kelas.total_durasi)}
                </p>
              </div>
              <div className="rating">
                <ul className="list-unstyled list-inline">
                  {getRating(
                    parseFloat(kelas.total_vote ? kelas.total_vote : 0)
                  )}
                  <li className="list-inline-item">
                    ({parseFloat(kelas.total_vote ? kelas.total_vote : 0)})
                  </li>
                </ul>
              </div>
              <div className="student">
                {/* <p>
                  <i className="las la-user"></i>
                  {kelas.total_siswa}
                </p> */}
                <p>
                  <i class="las la-fire-alt"></i>
                  {kelas.view_count}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
    function linkHref(val){
      var link=''
      console.log("VAL : ",val)
      link=shareHost+val.id+"/"+val.slug
      // if(val.type=='mandiri' && val.source=='wakool'){
      //   val.desc=val.description
      //   // link="detail-class/"+val.class_id+"?"+generateParamsQueryClass(val)
      //   link=shareHost+val.class_id+"/"+val.slug
      // }else if(val.type=='webinar' && val.source!='wakool'){
      //   val.id=val.class_id
      //   // link=paramsWebinars(val)
      //   link=shareHost+val.id+"/webinar/"+convertToSlug(val.title)+"?source="+val.source
      // }
      return link
      // console.log(link)
    }
    async function likeUnLike(id) {
      if (id != undefined) {
        let par = wishlist ? "0" : "1";
        await context.getRequest("favorite/" + id + "/" + par);
        getData();
        // console.log("favorite/" + id + "/" + par);
      }
    }
  };

  return (
    <Fragment>
      {/* Course Item */}
      {data.data?.map((item, index) => (
        <GridItem data={item} key={index} />
      ))}

      {/* <Col md="12" className="text-center">
                <Pagination />
            </Col> */}
    </Fragment>
  );
}

export default CourseItemGrid;
