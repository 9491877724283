export const colors = {

    bg1: "#212F3D",
    bgIllus:"#212F3D10",
    bg2: "#F6F9FF",
    gr_bg: "linear-gradient(90deg, #EE2025 0%, #d61c20 100%)",
    gr_bg2: "linear-gradient(90deg, #d61c20 0%, #EE2025 100%)",
    copy_bg: "#212F3D",
    blue: "#2c97ea",
    green: "#EE2025",
    green2: "#EE2025",
    red: "#d61c20",
    purple: "#84479c",
    yellow: "#fbab19",
    black1: "#182B49",
    black2: "#444444",
    text1: "#555555",
    text2: "#666666",
    text3: "#969696",
    text4: "#aaaaaa",
    text5: "#cccccc",
    border1: "#eeeeee",
    border2: "#3e3e3e",
    border3: "#dddddd",
    footer1: "#1a1b25",
    footer2: "#16171f",
    ftext: "#8D8E92",

    // wakool: "#ef4c40"
}

export const fonts = {

    roboto: "'Roboto', sans-serif",
    poppins: "'Poppins', sans-serif",

}