import React, { useEffect, useContext } from "react";
import { ContextProvider } from "./context/BaseContext";
import GA from './config/GoogleAnalytic.config'
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BrowserRouter as Router, Switch, Route, useLocation,useHistory } from "react-router-dom";
import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import About from "./pages/about/About";
import CourseGrid from "./pages/courses/CourseGrid";
import CourseList from "./pages/courses/CourseList";
import CourseDetails from "./pages/courses/CourseDetails";
import Instructor from "./pages/instructor/Instructors";
import InstructorDetails from "./pages/instructor/InstructorDetails";
import Gallery from "./pages/gallery/Gallery";
import Events from "./pages/events/Events";
import EventDetails from "./pages/events/EventsDetails";
import Login from "./pages/account/Login";
import Register from "./pages/account/Register";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import PageNotFound from "./pages/404/PageNotFound";
import ComingSoon from "./pages/comingsoon/ComingSoon";
import BlogClassic from "./pages/blog/BlogClassic";
import BlogGrid from "./pages/blog/BlogGrid";
import BlogDetails from "./pages/blog/BlogDetails";
import Product from "./pages/shop/Products";
import ProductDetails from "./pages/shop/ProductDetails";
import Cart from "./pages/shop/Cart";
import Classes from "./pages/classes/classes";
import Wishlist from "./pages/wishlist/wishlist";
import Transaction from "./pages/transaction/Transaction";
import ListMyClass from "./pages/list-my-class/ListMyClass";
import DetailClass from "./pages/detail-class/Detail-Class";
import LearnMyCLass from "./pages/learn-my-class/LearnMyClass";
import LearnLiveCLass from "./pages/learn-my-class/LearnLiveClass";
import ZoomFullscreen from "./pages/learn-my-class/components/ZoomFullscreen";
import Profile from "./pages/profile/Profile";
import ForgotPassword from "./pages/account/ForgotPassword";
import PrivacyPolicy from "./pages/privacy_policy";
import { useSnackbar } from "react-simple-snackbar";
import firebase, { messaging, getToken } from "./config/firebase.config";
import ChangePassword from "./pages/account/ChangePassword";
import Certificate from "./pages/certificate/Certificate";
import CertificateMitra from "./pages/certificate/CertificateMitra";
import ListCerticate from './pages/certificate/index'
import "./config/firebase.config";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import FbToken from "./pages/FbToken"
import NewPrivate from './pages/private-class/NewPrivate'
import ListPrivate from './pages/private-class/ListPrivate'
import PrivateDiscuss from './pages/private-class/PrivateDiscuss'
import Webinars from './pages/webinars/Webinars'
import WebinarDetail from './pages/webinars/WebinarDetail'

import Sitemap from './pages/SiteMap'

import TikTalk from './pages/tiktalk'
import TikTalkDetail from './pages/tiktalk/detail'

import CheckCertificate from './pages/check_certificate/index'
import Prakerja from "./pages/prakerja/Prakerja";


function App() {
  // const messaging=firebase.messaging()
  const location = useLocation();
  const context = useContext(ContextProvider);
  async function getTokenFirebase() {
    let username = localStorage.getItem("username");
    if (username) {
      // console.log(username)
      let token = await getToken();
      let form = new FormData();
      // form.append('firebase_token',token)
      form.append("firebase_token_web", token);
      let response = await context.postRequest("updateUser", form, "form");
      if (response) {
        // console.log("FIREBASE TOKEN RESPONSE : ", token, username, response);
        localStorage.setItem("firebase_token", token);
      }
    }
  }
  async function getPromoDiskon() {
    let response = await context.getRequest(`promo-new-user`);
    if (response) {
      localStorage.setItem(
        "diskon_new_user",
        JSON.stringify(response.data.data)
      );
      //setDiskon(response.data.data)
      // console.log("RESPONSE DISKON NEW USER : ",response.data.data)
    }
  }

  useEffect(() => {
    clearInterval(window.xx)
  }, [location])
  

  useEffect(() => {
    getTokenFirebase();
    getPromoDiskon();
    if(!firebase.messaging.isSupported()){
      // alert('not support')  
        context.setValue('supportFirebase',false)  
    }
  }, [context.user]);

  useEffect(() => {
    GA.pageview(window.location.pathname + window.location.search);
    if (window && window.parent) {
      // console.log("location : ",window.location.pathname)
      window.parent.postMessage({type: 'redirect', url: window.location.pathname + window.location.search}, '*');
    }
    // window.postMessage('target', 'hello')
    
  },[location])
  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        {/* WAKOOL ACADEMY PAGES */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={HomeOne}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/prakerja"}`}
          component={Prakerja}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/certificate"}`}
          component={ListCerticate}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/check-certificate"}`}
          component={CheckCertificate}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/wakool-certificate/:key"}`}
          component={CertificateMitra}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/tiktalk"}`}
          component={TikTalk}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/tiktalk/:id/detail"}`}
          component={TikTalkDetail}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/sitemap"}`}
          component={()=>{
            window.location.href="https://api.wakool.academy/sitemap"
            return null
          }}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/7ic7czl8m5hb2697g31uqr8r8igoal"}`}
          component={FbToken}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/classes/:categoryId"}`}
          component={Classes}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/wishlist"}`}
          component={Wishlist}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/transaction"}`}
          component={Transaction}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/my-class"}`}
          component={ListMyClass}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/detail-class/:id"}`}
          component={DetailClass}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/class/:id/new-private"}`}
          component={NewPrivate}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/list-private-user"}`}
          component={ListPrivate}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/private-discuss/:id"}`}
          component={PrivateDiscuss}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/login"}`}
          component={Login}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/registration"}`}
          component={Register}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/forgotpassword"}`}
          component={ForgotPassword}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/changepassword/:token"}`}
          component={ChangePassword}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/instructor-details/:id/:type/:slug"}`}
          component={InstructorDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/learn-class/:id"}`}
          component={LearnMyCLass}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/learn-live-class/:id/:class_id"}`}
          component={LearnLiveCLass}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/zoom-fullscreen/:token"}`}
          component={ZoomFullscreen}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/profile"}`}
          component={Profile}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/certificate/:token"}`}
          component={Certificate}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/webinars"}`}
          component={Webinars}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/webinars-detail/:id/:slug"}`}
          component={WebinarDetail}
        />
        <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
        <Route path={`${process.env.PUBLIC_URL + "/faq-detail/:id/:slug"}`} component={Faq} />
        {/* TEMPLATE PAGES */}
        {/* <Route
          path={`${process.env.PUBLIC_URL + "/home-two"}`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/about"}`}
          component={About}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/course-grid"}`}
          component={CourseGrid}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/course-list"}`}
          component={CourseList}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/course-details"}`}
          component={CourseDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/instructor"}`}
          component={Instructor}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/gallery"}`}
          component={Gallery}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/events"}`}
          component={Events}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/event-details"}`}
          component={EventDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/contact"}`}
          component={Contact}
        />
        <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
        <Route
          path={`${process.env.PUBLIC_URL + "/404"}`}
          component={PageNotFound}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/coming-soon"}`}
          component={ComingSoon}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-classic"}`}
          component={BlogClassic}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
          component={BlogGrid}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/blog-details"}`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/products"}`}
          component={Product}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/product-details"}`}
          component={ProductDetails}
        />
        <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} /> */}
        <Route
          path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
          component={PrivacyPolicy}
        />
        <Route component={PageNotFound} />
      </Switch>
      <ShowSnackBar />
      <NotificationContainer />
      <div id="whatsapp-cs">
        <WhatsAppWidget
          message="Halo kak 👋🏼 
          Apakah ada yang ingin di tanyakan mengenai kelas yang telah kakak beli? 
          Atau ada hal lain yang bisa kami bantu?"
          textReplyTime=""
          companyName="CS Wakool Academy"
          sendButton="Kirim"
          phoneNumber="+6285161201937"
        />
      </div>
    </>
  );
  function ShowSnackBar(type = "success", message = "no message") {
    var success = "#0FB172";
    var danger = "#DE1D22";
    var info = "#2C97EA";
    var color = success;
    if (context.notification.type == "danger") {
      color = danger;
    } else if (context.notification.type == "info") {
      color = info;
    } else if (context.notification.type == "success") {
    } else if (context.notification.type == "warning") {
    }
    // const [openSnackbar, closeSnackbar] = useSnackbar({
    //     position: 'top-right',
    //     style: {
    //       backgroundColor: color,
    //       border: '1px',
    //       color: 'white',
    //       fontFamily: 'Roboto,sans-serif',
    //       fontSize: '20px',
    //       textAlign: 'center',
    //       borderRadius:10
    //     },
    //     closeStyle: {
    //       color: 'white',
    //       fontSize: '16px',
    //     },
    // })
    useEffect(() => {
      if (context.notification.show) {
        // openSnackbar(context.notification.message)
        if (context.notification.type == "danger") {
          NotificationManager.error(context.notification.message);
        } else if (context.notification.type == "info") {
          NotificationManager.info(context.notification.message);
        } else if (context.notification.type == "success") {
          NotificationManager.success(context.notification.message);
        } else if (context.notification.type == "warning") {
          NotificationManager.warning(context.notification.message);
        }
        context.setValue("notification", {
          show: false,
          type: "",
          message: "",
        });
      }
    }, [context.notification]);
    return null;
  }
}

export default App;
