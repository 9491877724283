import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ContextProvider } from "../../context/BaseContext.js";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import HeaderTwo from "../../components/HeaderTwo";
import Loading from "../../components/Loading";
import { host } from "../../util/host";

export default function Certificate() {
  const context = useContext(ContextProvider);
  const { token } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // getDataSertifikat();
  }, []);
  return (
    <div className="main-wrapper course-details-page">
      {/* {loading && <Loading />} */}

      <HeaderTwo />

      <BreadcrumbBox
        title="Sertifikat"
        image={`${process.env.PUBLIC_URL}/assets/illustration11.png`}
      />

      <div
        id="renderSertifikat"
        className="container d-flex justify-content-center align-items-center"
      >
        <div
          style={{ width: "100%", height: "100%" }}
          // className="wrapSet"
        >
          <embed
            className="certificate"
            src={
              host +
              "certificates/" +
              token +
              "#page=1&view=fitH,100&statusbar=0&toolbar=0"
            }
            title="Certificate Wakool Academy"
            style={{ width: "100%", height: "100%" }}
            onLoad={() => setLoading(false)}
          ></embed>
          {/* <div className="middle">
            {!loading && <button
              onClick={() => {
                window.open(host + "certificates/" + token);
              }}
              type="button"
              className="btn btn-danger btn-lg"
            >
              Download Certificate
            </button>}
          </div> */}
        </div>
      </div>
      <div style={{marginBottom:"45px"}} className="col-12 text-center">
        <button
          onClick={() => {
            window.open(host + "certificates/" + token);
          }}
          type="button"
          className="btn btn-danger btn-lg"
        >
          Download Certificate
        </button>
      </div>
      <Footer />
    </div>
  );
}
