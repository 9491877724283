import firebase from "firebase/app";
import "firebase/auth"
import "firebase/analytics"
import "firebase/messaging"

var firebaseConfig = {
  apiKey: "AIzaSyC0ZFQr_9weS9X0yjGU68mMD25F0ASRvGw",
  authDomain: "wakool-academy.firebaseapp.com",
  projectId: "wakool-academy",
  storageBucket: "wakool-academy.appspot.com",
  messagingSenderId: "1077018823377",
  appId: "1:1077018823377:web:418cea0adf864f638fd3f0"
};
firebase.initializeApp(firebaseConfig);
export var messaging
export var getToken=()=>{
  return null
}
export const vavidKey='BAsCUBEOS7xDForABrvOL9rXSAgIC1cUxLSGDuyWPRfDMhEEWUgSR4fHW_vv79g4qUp-K4UfDqFPSxcCkYbzl10'
if(firebase.messaging.isSupported()){
  messaging=firebase.messaging()
  getToken = async () => {
    return messaging.getToken({vapidKey: vavidKey})
      .then((currentToken) => {
        if (currentToken) {
          // console.log('current token for client: ', currentToken);
          return currentToken;
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          return null;
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return null;
        // catch error while creating client token
      });
  }
}
export default firebase;