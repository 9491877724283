import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Styles } from '../styles/reviewForm.js';
import { useForm } from "react-hook-form";
import { ContextProvider } from '../../../context/BaseContext.js';
import { useLocation } from 'react-router-dom';

function ReviewForm({ getRatings, getDataKelas, ratings, liveClass }) {
    const { id } = useParams();
    const context = useContext(ContextProvider)
    const location = useLocation()
    const [rate, setRate] = useState('')
    const [comment, setComment] = useState('')
    const [rate1, setRate1] = useState(false)
    const [rate2, setRate2] = useState(false)
    const [rate3, setRate3] = useState(false)
    const [rate4, setRate4] = useState(false)
    const [rate5, setRate5] = useState(false)
    const [commentError, setCommentError] = useState(false)
    const [rateError, setRateError] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (ratings) {
            setRate(ratings.rate)
            setComment(ratings.comment)
        }
    }, [])
    useEffect(() => {
        if (rate == 1) {
            setRate1(true)
            setRate2(false)
            setRate3(false)
            setRate4(false)
            setRate5(false)
        } else if (rate == 2) {
            setRate1(false)
            setRate2(true)
            setRate3(false)
            setRate4(false)
            setRate5(false)
        } else if (rate == 3) {
            setRate1(false)
            setRate2(false)
            setRate3(true)
            setRate4(false)
            setRate5(false)
        } else if (rate == 4) {
            setRate1(false)
            setRate2(false)
            setRate3(false)
            setRate4(true)
            setRate5(false)
        } else if (rate == 5) {
            setRate1(false)
            setRate2(false)
            setRate3(false)
            setRate4(false)
            setRate5(true)
        }
    }, [rate])

    async function kirim() {
        if (rate != '') {
            if (comment != '') {
                setRefresh(true)
                setCommentError(false)
                setRateError(false)
                var form = new FormData();
                form.append('class_id', id);
                form.append('rate', rate);
                form.append('comment', comment);

                if(liveClass == true){
                    setRefresh(false)
                    console.log("ulasan live kelas");
                }else{
                    var response = await context.postRequest('ratings', form, 'form');
                    // console.log('form', JSON.stringify(response));
                    if (response.hasOwnProperty('data')) {
                        getRatings()
                        getDataKelas()
                        setRefresh(false)
                    }
                }
                
            } else {
                setCommentError(true)
            }
        } else {
            setRateError(true)
        }
    }


    return (
        <Styles>
            <form id="form6" className="form review-comment-form">
                <Row>
                    <Col md="12">
                        <div className="star-rating">
                            <input type="radio" name="rate" id="rate-5" checked={rate5} defaultValue="5" onChange={(v) => setRate(v.currentTarget.defaultValue)} />
                            <label htmlFor="rate-5" className="las la-star"></label>
                            <input type="radio" name="rate" id="rate-4" checked={rate4} defaultValue="4" onChange={(v) => setRate(v.currentTarget.defaultValue)} />
                            <label htmlFor="rate-4" className="las la-star"></label>
                            <input type="radio" name="rate" id="rate-3" checked={rate3} defaultValue="3" onChange={(v) => setRate(v.currentTarget.defaultValue)} />
                            <label htmlFor="rate-3" className="las la-star"></label>
                            <input type="radio" name="rate" id="rate-2" checked={rate2} defaultValue="2" onChange={(v) => setRate(v.currentTarget.defaultValue)} />
                            <label htmlFor="rate-2" className="las la-star"></label>
                            <input type="radio" name="rate" id="rate-1" checked={rate1} defaultValue="1" onChange={(v) => setRate(v.currentTarget.defaultValue)} />
                            <label htmlFor="rate-1" className="las la-star"></label>
                        </div>
                        {rateError ? <div style={{ color: 'red' }}>Beri bintang pada kelas ini</div> : null}
                    </Col>
                    <Col md="12">
                        <p className="form-control">
                            <textarea value={comment} onChange={(v) => setComment(v.currentTarget.value)} name="comment" id="desc6" placeholder="Tuliskan ulasan Anda"></textarea>
                            {commentError ? <div style={{ color: 'red' }}>Tulis ulasanmu untuk kelas ini</div> : null}
                        </p>
                    </Col>
                </Row>
            </form>
            <Col md="12">
                <button onClick={()=>kirim()}>Kirim Ulasan {refresh?<Spinner variant='light' animation='border' size='sm' />:null}</button>
            </Col>
        </Styles>
    )
}

export default ReviewForm