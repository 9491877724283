import React, { useEffect, useState, useContext } from "react";
import { ContextProvider } from "../../../context/BaseContext";
import moment from "moment";
import "moment/locale/id";
import { LoadingBall } from "../../../components/LoadingBall";
import Swal from "sweetalert2";
import { useLocation, useHistory } from "react-router-dom";

export default function App({
  setTestDone,
  sertifkatData,
  idClass,
  isBankSoal,
  idContentActive,
  setActivityDone,
  setCannotChangeContent,
  preTest,
  postTest,
  getDataKelas,
  idMentor,
}) {
  const history = useHistory();
  const location = useLocation();
  console.log("Lokasi", location);
  const context = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);

  const [showInfoQuiz, setshowInfoQuiz] = useState(false);
  const [infoKuis, setinfoKuis] = useState(null);
  const [preTestDone, setPreTestDone] = useState(false);
  const [postTestDone, setPostTestDone] = useState(false);
  const [jawaban, setJawaban] = useState([]);
  const [time, setTime] = useState(0);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [inTime, setInTime] = useState(null);
  const [postJawaban, setPostJawaban] = useState([]);
  const [kuis_id, setKuisId] = useState("");
  const [score, setScore] = useState("");
  const [isPreTest, setisPreTest] = useState(false);
  const [isPostTest, setIsPostTest] = useState(false);
  const [isPass, setIsPass] = useState(false);

  async function getData(contentId) {
    setLoading(true);
    resetData();
    if (idContentActive) {
      let response = await context.getRequest(
        `kelas-quiz?content_id=${contentId}`
      );
      console.log("ini quiz", response);
      if (response) {
        console.log("respon apa di api", response.data.data);
        if (response.data.data.QuizTemp != undefined) {
          // console.log(response.data.data.QuizTemp);
          setJawaban(response.data.data.QuizTemp);
          let dd = [];
          response.data.data.QuizTemp.map((item, index) => {
            dd.push(item.answer?.correct);
          });
          let jml_benar = dd.filter(function (value) {
            return value === 1;
          }).length;
          setScore(
            parseFloat(
              (jml_benar / response.data.data.QuizTemp.length) * 100
            ).toFixed(2)
          );
          setTitle(response.data.data.kuis?.title);
          setLoading(false);
        } else {
          setshowInfoQuiz(true);
          setData(response.data.data.data);
          console.log("data quiz biasa", response.data.data.data);
          setinfoKuis(response.data.data.kuis);

          let j = [];
          response.data.data.data.forEach((val) => {
            let lt = {
              id_pertanyaan: val.id_pertanyaan,
              pertanyaan_text: val.pertanyaan,
              id_jawaban: "",
              jawaban_text: "",
            };
            j.push(lt);
          });
          setPostJawaban(j);
          setLoading(false);
        }
      }
    } else if (!preTest.length > 0 && isBankSoal) {
      let response = await context.getRequest(
        `get_pretest?class_id=${contentId}`
      );
      if (response) {
        console.log("Respon get pretest", response.data.data.questions);
        let arr = [];
        setshowInfoQuiz(true);
        setinfoKuis(response.data.data);
        let j = [];
        response.data.data.questions.forEach((val) => {
          let lt = {
            id_pertanyaan: val.id,
            pertanyaan_text: val.text,
            id_jawaban: "",
            jawaban_text: "",
          };
          let la = {
            id_pertanyaan: val.id,
            jawaban: val.answers,
            pertanyaan: val.text,
          };
          j.push(lt);
          arr.push(la);
        });
        setData(arr);
        setPostJawaban(j);
        setLoading(false);
        setIsPostTest(true);
      }
    } else if (postTest && isBankSoal) {
      let response = await context.getRequest(
        `get_postest?class_id=${contentId}`
      );
      if (response) {
        console.log("Respon get pretest", response.data.data.questions);
        let arr = [];
        setshowInfoQuiz(true);
        setinfoKuis(response.data.data);
        let j = [];
        response.data.data.questions.forEach((val) => {
          let lt = {
            id_pertanyaan: val.id,
            pertanyaan_text: val.text,
            id_jawaban: "",
            jawaban_text: "",
          };
          let la = {
            id_pertanyaan: val.id,
            jawaban: val.answers,
            pertanyaan: val.text,
          };
          j.push(lt);
          arr.push(la);
        });
        setData(arr);
        setPostJawaban(j);
        setLoading(false);
        setisPreTest(true);
      }
    }
  }

  function resetData() {
    setinfoKuis(null);
    setData([]);
  }

  function startQuiz() {
    setshowInfoQuiz(false);
    setCannotChangeContent(true);
    setKuisId(infoKuis.id);
    setTime(infoKuis.time);
  }

  function getTime(time) {
    var eventTime = moment().add(time, "minutes").unix(); // Timestamp - Sun, 21 Apr 2013 13:00:00 GMT
    var currentTime = moment().unix(); // Timestamp - Sun, 21 Apr 2013 12:30:00 GMT
    var diffTime = eventTime - currentTime;
    var duration = moment.duration(diffTime * 1000, "milliseconds");
    var interval = 1000;
    //console.log(moment().unix())
    window.xx = setInterval(function () {
      duration = moment.duration(duration - interval, "milliseconds");
      let h = duration.hours() < 10 ? "0" + duration.hours() : duration.hours();
      let m =
        duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes();
      let s =
        duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds();

      let timeout = h + ":" + m + ":" + s;

      if (timeout == "00:00:00") {
        clearInterval(window.xx);
        setInTime(timeout);
      } else {
        setInTime(timeout);
      }
    }, interval);
  }

  function handleBtnDone(kuis_id, postJawaban) {
    Swal.fire({
      title: "Konfirmasi Selesaikan Kuis",
      text: `Apakah sudah yakin untuk menyelesaikan kuis`,
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonColor: "#EE2025",
      confirmButtonText: "Ya, Selesaikan Kuis",
      cancelButtonText: "Batalkan",
    }).then((result) => {
      if (result.isConfirmed) {
        submitQuiz(kuis_id, postJawaban);
      }
    });
  }

  async function submitQuiz(kuis_id, postJawaban) {
    let check = postJawaban.findIndex((val) => val.id_jawaban == "");
    if (check == -1) {
      let form = {};
      if (idContentActive) {
        form = {
          type_quiz: "quiz",
          kuis_id: kuis_id,
          jawaban: postJawaban,
        };
      } else if (!preTest.length > 0 && isBankSoal) {
        form = {
          type_quiz: "pretest",
          kuis_id: kuis_id,
          jawaban: postJawaban,
        };
      } else if (postTest && isBankSoal) {
        form = {
          type_quiz: "postest",
          kuis_id: kuis_id,
          jawaban: postJawaban,
        };
      }
      let response = await context.postRequest("kelas-quiz", form);
      if (response) {
        if (response.data.type_quiz == "pretest") {
          setTestDone(true);
          setScore(response.data.score);
          setPreTestDone(true);
          if (response.data.is_pass == true) {
            context.setNotification("success", response.message);
          } else {
            context.setNotification("danger", response.message);
          }
        } else if (response.data.type_quiz == "postest") {
          if (response.data.is_pass == true) {
            setIsPass(true);
            context.setNotification("success", response.message);
          } else {
            setIsPass(false);
            context.setNotification("danger", response.message);
          }
          setIsPass(response.data.is_pass);
          setPostTestDone(true);
        } else {
          clearInterval(window.xx);
          setCannotChangeContent(false);
          context.setNotification("success", "Jawaban anda telah tersimpan");
          await setActivityDone(idContentActive);
          await getData(idContentActive);
        }
        setTime(0);
        setData([]);
        setJawaban([]);
      }
    } else {
      context.setNotification(
        "danger",
        "Mohon periksa kembali jawabanmu, isi pertanyaan dengan baik dan benar"
      );
    }
  }

  async function submitQuizIfTimeout(kuis_id, postJawaban) {
    Swal.fire({
      title: "Waktu Habis",
      text: "Jawaban kuis akan kami simpan untuk menghitung hasil score",
      icon: "warning",
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ya",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let form = {};
        if (idContentActive) {
          form = {
            type_quiz: "quiz",
            kuis_id: kuis_id,
            jawaban: postJawaban,
          };
        } else if (!preTest.length > 0 && isBankSoal) {
          form = {
            type_quiz: "pretest",
            kuis_id: kuis_id,
            jawaban: postJawaban,
          };
        } else if (postTest && isBankSoal) {
          form = {
            type_quiz: "postest",
            kuis_id: kuis_id,
            jawaban: postJawaban,
          };
        }
        let response = await context.postRequest("kelas-quiz", form);
        if (response) {
          setCannotChangeContent(false);
          setTime(0);
          setData([]);
          setJawaban([]);
          context.setNotification("success", "Jawaban anda telah tersimpan");
          await setActivityDone(idContentActive);
          await getData(idContentActive);
        }
      }
    });
  }

  console.log("data soal", data);
  console.log("Post jawaban", postJawaban);

  useEffect(() => {
    if (idContentActive) {
      getData(idContentActive);
    } else if (preTest || postTest) {
      getData(idClass);
    }
  }, [idContentActive, idClass]);

  useEffect(() => {
    if (time && time != 0) {
      getTime(time);
    }
  }, [time]);

  useEffect(() => {
    if (inTime && inTime == "00:00:00") {
      submitQuizIfTimeout(kuis_id, postJawaban);
    }
  }, [inTime]);

  return (
    <>
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "465px" }}
        >
          <LoadingBall />
        </div>
      ) : (
        <>
          {data.length > 0 ? (
            <>
              {showInfoQuiz ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "465px" }}
                >
                  <table className="text-left">
                    <tbody>
                      <tr>
                        <td colSpan={2} className="p-4 text-center">
                          <h5>
                            Informasi{" "} Test
                            {/* {!preTest.length > 0
                              ? "PRE-TEST"
                              : !postTest.length > 0
                              ? "POST-TEST"
                              : "Kuis"} */}
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2">
                          <h6>Kuis</h6>
                        </td>
                        <td className="p-2">
                          <h6>: {infoKuis.title}</h6>
                        </td>
                      </tr>
                      {isPreTest == false && isPostTest == false ? (
                        <>
                          <tr>
                            <td className="p-2">
                              <h6>Deskripsi</h6>
                            </td>
                            <td className="p-2">
                              <h6>: {infoKuis.description}</h6>
                            </td>
                          </tr>
                          {infoKuis?.time ? (
                          <tr>
                            <td className="p-2">
                              <h6>Durasi</h6>
                            </td>
                            <td className="p-2">
                              <h6>: {infoKuis.time} mnt</h6>
                            </td>
                          </tr>
                          ) : null}
                        </>
                      ) : null}
                      <tr>
                        <td colSpan={2} className="p-4 text-center">
                          <button
                            onClick={() => startQuiz()}
                            className="btn btn-danger"
                          >
                            Mulai Kuis
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <br />
                  {inTime ? (
                    <>
                      <h5 style={{ color: "#EE2025" }} className="mb-1">
                        Waktu Tersisa
                      </h5>
                      <h5 className="mb-1">{inTime}</h5>
                    </>
                  ) : null}

                  <div
                    className="mt-5"
                    style={{ height: "465px", overflowX: "scroll" }}
                  >
                    <div className="text-left" style={{ marginLeft: 18 }}>
                      {data.map((question, index) => {
                        const stylePertanyaan = {
                          fontSize: 14,
                          color: "#EE2025",
                          fontWeight: 600,
                          marginBottom: 10,
                        };

                        const styleQuestion = {
                          fontSize: 16,
                          color: "#444444",
                          fontWeight: 600,
                          padding: "10px",
                          marginBottom: 15,
                          lineHeight: "1.6",
                        };

                        return (
                          <div
                            style={{ marginBottom: 20, padding: "0 10px" }}
                            key={index}
                            className="d-flex flex-column"
                          >
                            <h6 style={stylePertanyaan}>
                              Pertanyaan {index + 1}
                            </h6>

                            {/* Pertanyaan */}
                            <div
                               style={{ padding: "20px 10px 0px" }}
                              className="rounded rounded-lg border border-grey"
                            >
                              <h6
                                style={styleQuestion}
                                dangerouslySetInnerHTML={{
                                  __html: question.pertanyaan,
                                }}
                              ></h6>

                              <ul className="list-group list-group-flush mt-3">
                                <ChoiceItem
                                  item={question.jawaban}
                                  idp={question.id_pertanyaan}
                                />
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <hr />
                    <button
                      type="button"
                      className="btn btn-success mb-4"
                      onClick={() => {
                        handleBtnDone(kuis_id, postJawaban);
                      }}
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {jawaban.length > 0 ? (
                /* Class code Mentor for KAI Class */
                idMentor == 492 ? (
                  <RenderKuisDone />
                ) : (
                  <RenderHasil />
                )
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "465px", width: "100%" }}
                >
                  {preTestDone || postTestDone ? (
                    <RenderTestDone />
                  ) : (
                    <RenderKuisDone />
                  )}

                  {/* Legacy Code */}
                  {/*
                    {preTestDone || postTestDone ? (
                      <RenderTestDone />
                    ) : (
                      <div>
                        <img
                          style={{
                            height: "150px",
                            width: "150px",
                            objectFit: "cover",
                          }}
                          src={`${process.env.PUBLIC_URL}/assets/no-question.png`}
                          alt="no-question"
                        />
                        <p>Pertanyaan kuis tidak ditemukan</p>
                      </div>
                    )}
                   */}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function RenderKuisDone() {
    return (
      <div style={{ height: "465px", overflowX: "scroll" }}>
        <div className="text-center d-flex flex-column justify-content-center align-items-center h-100">
          <i
            style={{ fontSize: "40px", color: "orange" }}
            className={"fas fa-star"}
          ></i>
          <h5 className="card-title mt-5" style={{ color: "red" }}>
            Kuis Selesai
          </h5>

          <p className="card-text text-alternate mb-4">
            <p>Terima Kasih</p>
          </p>
        </div>
      </div>
    );
  }

  function RenderHasil() {
    const styleHeading = {
      color: "#444444",
      fontWeight: 600,
      lineHeight: "1.6",
      marginBottom: 10,
    };
    return (
      <div style={{ height: "465px", overflowX: "scroll" }}>
        <div className="row">
          <div className="col-md-12 ">
            <br />
            <div className="text-center">
              <h5 style={{ ...styleHeading, color: "#EE2025" }}>
                Hasil {title}
              </h5>
              <br />
              <h6 style={styleHeading}>Nilai {score}</h6>
              <br />
            </div>

            <div className="text-left" style={{ backgroundColor: "#ebede" }}>
              {jawaban.map((item, index) => {
                let isTrue = "green";
                if (item.answer?.correct == 0) {
                  isTrue = "red";
                }

                const stylePertanyaan = {
                  fontSize: 14,
                  color: "#EE2025",
                  fontWeight: 600,
                  marginBottom: 10,
                };

                const styleQuestion = {
                  fontSize: 16,
                  color: "#444444",
                  fontWeight: 600,
                  padding: "10px",
                  marginBottom: 15,
                  lineHeight: "1.6",
                };

                return (
                  <div
                    style={{ marginBottom: 20, padding: "0 10px" }}
                    key={index}
                    className="d-flex flex-column"
                  >
                    <h6 style={stylePertanyaan}>Pertanyaan {index + 1}</h6>

                    {/* Pertanyaan */}
                    <div
                      style={{ padding: "20px 10px 0px" }}
                      className="rounded rounded-lg border border-grey"
                    >
                      <h6
                        style={styleQuestion}
                        dangerouslySetInnerHTML={{
                          __html: item.questions.text,
                        }}
                      ></h6>

                      <ul className="list-group list-group-flush mt-3">
                        {item.answer ? (
                          <li
                            className="list-group-item bg-white border border-gray rounded-lg"
                            style={{ marginBottom: 15, color: isTrue }}
                          >
                            <div className={`d-flex align-items-center`}>
                              <p>Jawaban : </p>
                              <div
                                style={{ marginLeft: 5 }}
                                dangerouslySetInnerHTML={{
                                  __html: item.answer.text,
                                }}
                              ></div>

                              <i
                                style={{
                                  marginLeft: 5,
                                  fontSize: "15px",
                                  color: isTrue,
                                }}
                                className={
                                  isTrue == "green"
                                    ? "fas fa-check-circle"
                                    : "fas fa-times-cirle"
                                }
                              ></i>
                            </div>
                          </li>
                        ) : (
                          <li
                            className="list-group-item bg-white border border-gray rounded-lg"
                            style={{ marginBottom: 15, color: "red" }}
                          >
                            <p>Jawaban : </p>
                            <div
                              style={{ marginLeft: 5 }}
                              dangerouslySetInnerHTML={{
                                __html: "<p>-<p>",
                              }}
                            ></div>

                            <i
                              style={{
                                marginLeft: 5,
                                fontSize: "15px",
                                color: isTrue,
                              }}
                              className={"fas fa-times-cirle"}
                            ></i>
                          </li>
                        )}

                        {item.answer == null || item.answer.correct == 0 ? (
                          <li
                            className="list-group-item bg-white border border-gray rounded-lg"
                            style={{ marginBottom: 15, color: "green" }}
                          >
                            <div className={`d-flex align-items-center`}>
                              <p>Jawaban Benar : </p>
                              <div
                                style={{ marginLeft: 5 }}
                                dangerouslySetInnerHTML={{
                                  __html: item.answer_correct.text,
                                }}
                              ></div>
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function RenderTestDone() {
    return (
      <div>
        <table className="text-left" style={{ marginLeft: 18 }}>
          <tbody>
            <tr>
              <td colSpan={2} className="p-4 text-center">
                <h5>
                  {preTestDone
                    ? "Selamat anda telah menyelesaikan PRE-TEST"
                    : isPass
                    ? "Selamat anda lulus menyelesaikan POST-TEST"
                    : "Oopps anda belum lulus menyelesaikan POST-TEST"}
                </h5>
              </td>
            </tr>
            <tr>
              <td className="p-2">
                <h6>Kuis</h6>
              </td>
              <td className="p-2">
                <h6>: {infoKuis.title}</h6>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="p-4 text-center">
                <button
                  onClick={() =>
                    preTestDone || isPass == false
                      ? window.location.reload()
                      : history.push(
                          process.env.PUBLIC_URL +
                            `/certificate/${sertifkatData}`
                        )
                  }
                  className="btn btn-danger"
                >
                  {preTestDone
                    ? "Lanjut Belajar"
                    : isPass
                    ? "Dapatkan Sertifikat"
                    : "Ulangi POST-TEST"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function RenderNoQuestion() {
    return (
      <div>
        <img
          style={{
            height: "150px",
            width: "150px",
            objectFit: "cover",
          }}
          src={`${process.env.PUBLIC_URL}/assets/no-question.png`}
          alt="no-question"
        />
        <p>Pertanyaan kuis tidak ditemukan</p>
      </div>
    );
  }

  /* Legacy Code Choice
  function Choice({ item, idp }) {
    return item.map((val, index) => {
      let ind = postJawaban.findIndex((value) => value.id_pertanyaan == idp);
      let dd = postJawaban;

      const isChecked = dd[ind].id_jawaban == val.id ? true : false;

      console.log("idnisi", ind);
      console.log(dd[ind].id_jawaban == val.id);
      return (
        <tr style={{ fontSize: 14, padding: 20 }} key={index}>
          <td className="p-2" />
          <td className="p-2">
            <div
              className={`input-group bg-white rounded align-items-center ${
                isChecked ? "border border-danger" : ""
              }`}
            >
              <div className="input-group-text">
                <input
                  id={`idp_${idp}_${index}`}
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  className="mt-0 "
                  type="radio"
                  name={`idp_${idp}`}
                  checked={isChecked}
                  value={val.id}
                  onChange={(e) => {
                    dd[ind].id_jawaban = val.id;
                    dd[ind].jawaban_text = val.text;
                    setPostJawaban((v) => [...dd]);
                  }}
                />
              </div>
              <label
                htmlFor={`idp_${idp}_${index}`}
                class={`text-left ${isChecked ? "font-weight-bolder" : ""}`}
                dangerouslySetInnerHTML={{ __html: val.text }}
              ></label>
            </div>
          </td>
        </tr>
      );
    });
  }
  */

  function ChoiceItem({ item, idp }) {
    return item.map((val, index) => {
      let ind = postJawaban.findIndex((value) => value.id_pertanyaan == idp);
      let dd = postJawaban;

      const isChecked = dd[ind].id_jawaban == val.id ? true : false;

      return (
        <li
          className="list-group-item bg-white border border-gray rounded-lg"
          style={{ marginBottom: 15 }}
          key={index}
        >
          <div className={`d-flex align-items-center`}>
            <input
              id={`idp_${idp}_${index}`}
              style={{
                width: 18,
                height: 18,
              }}
              className="mt-0"
              type="radio"
              name={`idp_${idp}`}
              checked={isChecked}
              value={val.id}
              onChange={(e) => {
                dd[ind].id_jawaban = val.id;
                dd[ind].jawaban_text = val.text;
                setPostJawaban((v) => [...dd]);
              }}
            />
            <label
              htmlFor={`idp_${idp}_${index}`}
              style={{ marginLeft: 5, marginBottom: 2 }}
              class={`${isChecked ? "font-weight-bolder" : ""}`}
              dangerouslySetInnerHTML={{ __html: val.text }}
            ></label>
          </div>
        </li>
      );
    });
  }
}
